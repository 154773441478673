import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Grid,
  Modal,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form"; // Step 1
import { REQUIRED_RED } from "../../App";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const AddShopSupplyModal = ({
  open,
  handleClose,
  setShopSupplyData,
  shopSupplyData,
  editShopSupply,
  setEditShopSupply,
}) => {
  console.log(
    "🚀 ~ file: AddShopSupplyModal.jsx:45 ~ shopSupplyData:",
    shopSupplyData
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm(); // Step 2

  useEffect(() => {
    if (editShopSupply?.edit) {
      const data = shopSupplyData[editShopSupply?.index];
      console.log(
        "🚀 ~ file: AddShopSupplyModal.jsx:63 ~ useEffect ~ data:",
        data
      );
      setValue("itemName", data?.itemName);
      setValue("qty", +data?.qty);
      setValue("cost", +data?.cost);
      setValue("totalAmount", data?.totalAmount);
    }
  }, [editShopSupply]);

  const deleteshopSupplies = () => {
    const filteredShopSupplyData = shopSupplyData.filter(
      (item, index) => index !== editShopSupply?.index
    );
    setShopSupplyData(filteredShopSupplyData);
    reset();
    handleClose(); // Close the modal after form submission
  };

  const onSubmitShopSupplies = (data) => {
    // Handle form submission here

    // console.log("suppludata", data);
    if (editShopSupply?.edit) {
      const allData = [...shopSupplyData];

      allData[editShopSupply?.index] = data;
      setShopSupplyData(allData);
    } else {
      setShopSupplyData([...shopSupplyData, data]);
    }
    reset();
    handleClose(); // Close the modal after form submission
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmitShopSupplies)}>
          {" "}
          {/* Step 3 */}
          <Box
            sx={{
              ...style,
              height: "fit-content",
              width: "500px",
              border: "none",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <AppBar
              variant="elevation"
              sx={{
                height: "64px",
                borderRadius: "8px 8px 0px 0px !important",
                paddingRight: "0px !important",
                boxShadow: "none",
                bgcolor: "secondary.main",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" fontWeight={500}  width={"98%"}>
                  {editShopSupply?.edit ? "Edit" : "Add"} Shop Supplies
                </Typography>
                <Stack direction={"row"} spacing={0}>
                  <div
                    onClick={() => {
                      handleClose();
                      reset();
                    }}
                    style={{ cursor: "pointer", color: "black" ,height:'32px'}}
                  >
                    <CloseIcon style={{fontSize:32}}/>
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              gap={2}
              disableGutters
              sx={{
                scrollbarColor: "blue",
                scrollbarWidth: "thin",
                overflowY: "auto",
                marginTop: "64px",
                maxHeight: "80vh",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} xl={12}>
                  {/*<Typography variant="body1" padding={"8px 0px"}>
                    Item Name <span style={{ color: "#D32F2F" }}>*</span>
                  </Typography>*/}
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Item Name <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="itemName"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Item Name is required" }} // Step 3
                      render={({ field }) => <TextField {...field} fullWidth />}
                    />
                    {errors.itemName && <p>{errors.itemName.message}</p>}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Quantity <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="qty"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Quantity is required" }} // Step 3
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="number"
                          onChange={(event) => {
                            setValue(
                              "totalAmount",
                              (getValues().cost * event.target.value).toString()
                            );
                            setValue("qty", event.target.value);
                          }}
                        />
                      )}
                    />
                    {errors.qty && <p>{errors.qty.message}</p>}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Cost <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="cost"
                      control={control}
                      defaultValue=""
                      // rules={{ required: "Cost is required" }} // Step 3
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          fullWidth
                          onChange={(e) => {
                            setValue(
                              "totalAmount",
                              (e.target.value * getValues().qty).toString()
                            );
                            setValue("cost", e.target.value);
                          }}
                        />
                      )}
                    />
                    {errors.cost && <p>{errors.cost.message}</p>}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Total Amount{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="totalAmount"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Total is required" }} // Step 3
                      render={({ field }) => (
                        <TextField
                          name="total"
                          {...field}
                          fullWidth
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                backgroundColor: "background.disabled_fields",
                                border: 0,
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item container justifyContent={"end"} spacing={2}>
                  <Grid
                    item
                    className="space-between-style"
                    width={editShopSupply?.edit ? "100%" : "auto"}
                  >
                    {" "}
                    {editShopSupply?.edit && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => deleteshopSupplies()}
                      >
                        REMOVE item
                      </Button>
                    )}
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleClose();
                          reset();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        type="submit" // Step 3
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default AddShopSupplyModal;
