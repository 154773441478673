import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { REQUIRED_RED } from "../../App";
import {
  addVehicle,
  decodeVIN,
  importedVehicleCreated,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import SuccessMessageModal from "../Common/SuccessMessage";
import { checkValidVIN } from "../../utility/utilityFunctions";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",

  boxShadow: 24,
  pt: 2,
  pl: 4,
  pr: 0,
  pb: 3,
};

const MissingVehicleInfoModal = ({
  open,
  handleClose,
  id,
  vehicalDetailFunction,
  setAddVehicleInfoButton,
  setVehicleCreatedId,
  // vehicles,
  // setVehicles,
  // editVehicle,
  // setEditVehicle,
  vehicleSource,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const dispatch = useDispatch();
  const [vinError, setVINError] = useState("");

  const [vinLoading, setVINLoading] = useState(false);
  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [yearPage, setYearPage] = useState(1);

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [makeLoader, setMakeLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 500,
    year: yearValue,
  };

  useEffect(() => {
    fetchVehicleYear();
    // fetchVehicleMake(vehicleMakeParams);
  }, []);
  useEffect(() => {
    if (vehicleSource) {
      setValue("vin", vehicleSource?.vin);
      setValue("licenseNumber", vehicleSource?.license);

      if (vehicleSource?.vehicleYear) {
        setValue("year", vehicleSource?.vehicleYear);
        setYearValue(vehicleSource?.vehicleYear);
      }
      fetchVehicleMake({
        page: makeValuePage,
        limit: 500,
        year: vehicleSource?.vehicleYear,
      });

      if (vehicleSource?.vehicleMake?.name) {
        setValue(
          "vehicleMakeId",
          JSON.stringify({
            id: vehicleSource?.vehicleMake?._id,
            name: vehicleSource?.vehicleMake?.name,
          })
        );
        setMakeValue(
          JSON.stringify({
            id: vehicleSource?.vehicleMake?._id,
            name: vehicleSource?.vehicleMake?.name,
          })
        );

        fetchVehicleModel({
          page: modelValuePage,
          limit: 1000,
          vehicleMakeId: vehicleSource?.vehicleMake?._id,
          year: vehicleSource?.vehicleYear,
        });
      }
      if (vehicleSource?.vehicleModel?.name) {
        setValue(
          "vehicleModelId",
          JSON.stringify({
            id: vehicleSource?.vehicleModel?._id,
            name: vehicleSource?.vehicleModel?.name,
          })
        );
        setModelValue(
          JSON.stringify({
            id: vehicleSource?.vehicleModel?._id,
            name: vehicleSource?.vehicleModel?.name,
          })
        );

        fetchVehicleTrim({
          page: trimValuePage,
          limit: 1000,
          vehicleMakeId: vehicleSource?.vehicleMake?._id,
          vehicleModelId: vehicleSource?.vehicleModel?._id,
          year: vehicleSource?.vehicleYear,
        });
      }

      if (vehicleSource?.vehicleModelTrim?.name) {
        setValue(
          "vehicleModelTrimId",
          JSON.stringify({
            id: vehicleSource?.vehicleModelTrim?._id,
            name: vehicleSource?.vehicleModelTrim?.name,
            description: vehicleSource?.vehicleModelTrim?.description,
          })
        );
        setTrimValue(
          JSON.stringify({
            id: vehicleSource?.vehicleModelTrim?._id,
            name: vehicleSource?.vehicleModelTrim?.name,
            description: vehicleSource?.vehicleModelTrim?.description,
          })
        );
      }
    }
  }, [vehicleSource]);

  const fetchVehicleYear = async () => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: 500 }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);

  useEffect(() => {
    setMake(makes);
  }, [makes]);

  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);

    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);

  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");

      setVINLoading(true);
      const resp = await dispatch(decodeVIN(getValues()?.vin));
      // setModelSelectLoader(true);
      setMakeValue(resp?.data?.make?._id);
      setModelValue("");
      setTrimValue("");
      fetchVehicleMake({
        page: 1,
        limit: 500,
        year: resp?.data?.year,
      });
      let para = {
        page: 1,
        limit: 1000,
        vehicleMakeId: resp?.data?.make?._id,
        year: resp?.data?.year,
      };

      let maekResult = await dispatch(vehicleModelMaster(para));
      if (maekResult) {
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          vehicleModelId: resp?.data?.model?._id,
          year: resp?.data?.year,
        };
        await dispatch(vehicleTrimMaster(para));
      }

      if (resp) {
        setValue("year", resp?.data?.year);
        setValue(
          "vehicleMakeId",
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setValue(
          "vehicleModelId",
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );
        setValue(
          "vehicleModelTrimId",
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );
        setYearValue(resp?.data?.year);
        setMakeValue(
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setModelValue(
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );
        setTrimValue(
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );

        setVINLoading(false);
      }
    } else if (getValues()?.vin && getValues()?.vin.length != 17) {
      setVINLoading(false);
      // setVINError("Please Enter Valid VIN");
      setError("vin", {
        message: "Please enter valid VIN",
      });
    } else {
      setVINLoading(false);
      // setVINError("Please Enter Valid VIN");
      setError("vin", {
        message: "Please enter valid VIN",
      });
    }
  };

  const onSubmit = async (values) => {
    setShowLoader(true);

    if (vehicleSource?.source == "shop_imported") {
      let payload = {
        year: values?.year + "",
        vehicleMake: JSON.parse(values.vehicleMakeId).id,
        vehicleModel: JSON.parse(values.vehicleModelId).id,
      };
      if (values?.vin) {
        payload.vin = values?.vin;
      }
      if (values?.licenseNumber) {
        payload.licenseNumber = values?.licenseNumber;
      }
      if (values.vehicleModelTrimId) {
        payload.vehicleModelTrim = JSON.parse(values.vehicleModelTrimId).id;
      }

      const resp = await dispatch(
        importedVehicleCreated(payload, vehicleSource?._id)
      );

      if (resp.status) {
        setShowLoader(false);
        setAddVehicleInfoButton(true);
        vehicalDetailFunction(id);
        handleClose();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
        setVehicleCreatedId(resp?.data?._id);
      }
    }
    if (!vehicleSource) {
      let payload = {
        year: values?.year + "",
        vehicleMakeId: JSON.parse(values.vehicleMakeId).id,
        vehicleModelId: JSON.parse(values.vehicleModelId).id,
        vin: values?.vin,
        licenseNumber: values.licenseNumber,
        customerId: id,
      };
      if (values.vehicleModelTrimId) {
        payload.vehicleModelTrimId = JSON.parse(values.vehicleModelTrimId).id;
      }

      const resp = await dispatch(addVehicle(payload));
      if (resp?.status) {
        setShowLoader(false);
        setAddVehicleInfoButton(true);
        vehicalDetailFunction(resp?._id);
        handleClose();
        reset();
        setMakeValue("");
        setYearValue("");
        setModelValue("");
        setTrimValue("");
        setOpenSuccessModal(true);
        setSuccessMessage("New Vehicle Added");
        setVehicleCreatedId(resp?._id);
      }
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          reset();
          reset();
          setMakeValue("");
          setYearValue("");
          setModelValue("");
          setTrimValue("");
          handleClose();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            height: "scroll",
            border: "none",
            borderRadius: "4px",
          }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="primary"
            variant="elevation"
            sx={{
              height: "64px",
              paddingRight: "0px !important",
              backgroundColor: "#002394",
            }}
          >
            <Toolbar variant="regular">
              <Typography
                variant="h6"
                width={"98%"}
                color={"white"}
                fontWeight={500}
              >
                Confirm Vehicle Information
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    reset();
                    setMakeValue("");
                    setYearValue("");
                    setModelValue("");
                    setTrimValue("");
                    handleClose();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            gap={2}
            disableGutters
            style={{ marginTop: "66px", paddingRight: "20px" }}
            sx={{ maxHeight: "650px", overflowY: "scroll" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography style={{ color: REQUIRED_RED, marginBottom: "24px" }}>
                Please fill out the missing fields for this vehicle.
              </Typography>
              {/* <Typography
                fontSize={"20px"}
                fontWeight={500}
                style={{ marginBottom: "20px" }}
              >
                Vehicle Information
              </Typography> */}
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    VIN {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                  </Typography>
                  <Controller
                    name="vin"
                    control={control}
                    // rules={{
                    //   required: "VIN is required",
                    // }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        // required
                        id="outlined-basic"
                        variant="outlined"
                        // error={!!errors.vin}
                        // helperText={errors.vin ? errors.vin.message : ""}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          if (e.target.value !== "") {
                            const regex = checkValidVIN(e.target.value);
                            if (regex) {
                              clearErrors("vin");
                            } else {
                              setError("vin", {
                                message: "Please enter valid VIN",
                              });
                            }
                          } else {
                            clearErrors("vin");
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {vinLoading ? (
                                <CircularProgress />
                              ) : (
                                <Button onClick={handleDecodeVIN}>
                                  Decode
                                </Button>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* <Typography style={{ color: "red" }}>{vinError}</Typography> */}
                  {/* <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                            /> */}
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  Or
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth>
                    <Typography variant="body1" className="label-500">
                      Year <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      defaultValue=""
                      name="year"
                      control={control}
                      rules={{
                        required: "Year is required",
                      }}
                      render={({ field }) => (
                        <Select
                          value={yearValue}
                          onChange={(event) => {
                            field.onChange(event);
                            setYearValue(event.target.value);
                            setMakeValue("");
                            setModelValue("");
                            setTrimValue("");
                            setMake([]);
                            setModel([]);
                            setTrim([]);
                            fetchVehicleMake({
                              page: makeValuePage,
                              limit: 500,
                              year: event.target.value,
                            });
                          }}
                          error={!!errors.year}
                          helperText={errors.year ? errors.year.message : ""}
                          // {...field}
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                          // disabled={field?.value ? true : false}
                          // style={{
                          //   backgroundColor: field?.value ? "#F1F1F1" : "",
                          // }}
                        >
                          {Array.isArray(year) &&
                            year?.map((y) => {
                              return (
                                <MenuItem value={y} key={y}>
                                  {y}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth>
                    <Typography variant="body1" className="label-500">
                      Make <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="vehicleMakeId"
                      defaultValue={""}
                      control={control}
                      rules={{
                        required: "Make is required",
                      }}
                      render={({ field }) => (
                        <Select
                          fullWidth
                          value={makeValue ? makeValue : ""}
                          disabled={yearValue !== "" ? false : true}
                          onChange={(event) => {
                            field.onChange(event);

                            setMakeValue(event.target.value);
                            setModelValue(null);
                            setTrimValue(null);
                            setModel([]);
                            setTrim([]);
                            fetchVehicleModel({
                              page: modelValuePage,
                              limit: 1000,
                              vehicleMakeId: JSON.parse(event.target.value).id,
                              year: yearValue,
                            });
                          }}
                          error={!!errors.vehicleMakeId}
                          helperText={
                            errors.vehicleMakeId
                              ? errors.vehicleMakeId.message
                              : ""
                          }
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                          // disabled={field?.value ? true : false}
                          // style={{
                          //   backgroundColor: field?.value ? "#F1F1F1" : "",
                          // }}
                        >
                          {!makeLoader &&
                            Array.isArray(make) &&
                            make?.map((m) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: m?._id,
                                    name: m?.name,
                                  })}
                                  key={m?._id}
                                >
                                  {m?.name}
                                </MenuItem>
                              );
                            })}
                          {makeLoader && (
                            <MenuItem disabled>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth>
                    <Typography variant="body1" className="label-500">
                      Model <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>
                    <Controller
                      name="vehicleModelId"
                      control={control}
                      rules={{
                        required: "Model is required",
                      }}
                      render={({ field, fieldState }) => (
                        <Select
                          fullWidth
                          value={modelValue}
                          disabled={
                            yearValue !== "" && makeValue !== "" ? false : true
                          }
                          onChange={(event) => {
                            field.onChange(event);
                            setModelValue(event.target.value);
                            setTrimValue(null);
                            setTrim([]);
                            fetchVehicleTrim({
                              page: trimValuePage,
                              limit: 1000,
                              vehicleMakeId: JSON.parse(makeValue)?.id,
                              vehicleModelId: JSON.parse(event.target.value).id,
                              year: yearValue,
                            });
                          }}
                          error={!!errors.vehicleModelId}
                          helperText={
                            errors.vehicleModelId
                              ? errors.vehicleModelId.message
                              : ""
                          }
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                          // disabled={field?.value ? true : false}
                          // style={{
                          //   backgroundColor: field?.value ? "#F1F1F1" : "",
                          // }}
                          // {...field}
                        >
                          {Array.isArray(model) &&
                            model?.map((m) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: m?._id,
                                    name: m?.name,
                                  })}
                                  key={m?._id}
                                >
                                  {m?.name}
                                </MenuItem>
                              );
                            })}
                          {modelLoader && (
                            <MenuItem disabled>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth>
                    <Typography variant="body1" className="label-500">
                      Trim
                    </Typography>
                    <Controller
                      name="vehicleModelTrimId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Select
                          fullWidth
                          value={trimValue}
                          disabled={
                            yearValue && makeValue && modelValue ? false : true
                          }
                          onChange={(event) => {
                            field.onChange(event);

                            setTrimValue(event.target.value);
                          }}
                          error={!!errors.vehicleModelTrimId}
                          helperText={
                            errors.vehicleModelTrimId
                              ? errors.vehicleModelTrimId.message
                              : ""
                          }
                          MenuProps={{
                            style: { maxHeight: "400px" },
                          }}
                          // disabled={field?.value ? true : false}
                          // style={{
                          //   backgroundColor: field?.value ? "#F1F1F1" : "",
                          // }}
                          // {...field}
                        >
                          {Array.isArray(trim) &&
                            trim?.map((m) => {
                              return (
                                <MenuItem
                                  value={JSON.stringify({
                                    id: m?._id,
                                    name: m?.name,
                                    description: m?.description,
                                  })}
                                  key={m?._id}
                                >
                                  <div>
                                    <div style={{ fontWeight: 500 }}>
                                      {m?.name}
                                    </div>
                                    <div>{m?.description}</div>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          {trimLoader && (
                            <MenuItem disabled>
                              <CircularProgress />
                            </MenuItem>
                          )}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    margin: "24px 0 0px 0",
                  }}
                >
                  And
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <Typography variant="body1" className="label-500">
                    Licence Plate Number{" "}
                    <span style={{ color: REQUIRED_RED }}>*</span>
                  </Typography>

                  <Controller
                    name="licenseNumber"
                    control={control}
                    rules={{
                      required: "Licence Plate Number is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        required
                        id="outlined-basic"
                        variant="outlined"
                        error={!!errors.licenseNumber}
                        helperText={
                          errors.licenseNumber
                            ? errors.licenseNumber.message
                            : ""
                        }
                        {...field}
                      />
                    )}
                  />
                  {/* <TextField
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                            /> */}
                </Grid>
                <Grid item container justifyContent={"space-between"}>
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      {/* {editVehicle?.edit && (
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteVehicle}
                            size="large"
                          >
                            DELETE
                          </Button>
                        )} */}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          reset();
                          reset();
                          setMakeValue("");
                          setYearValue("");
                          setModelValue("");
                          setTrimValue("");
                          handleClose();
                        }}
                        size="large"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleClose}
                        size="large"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MissingVehicleInfoModal;
