import { Add, Delete, Edit, KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";

import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../../App";
import FormatNumber, {
  FormatNumberForKm,
  FormatNumberNegativeValue,
  FormatTotalNumbers,
} from "../../../components/FormatNumber/FormatNumber";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import {
  customerDetail as customerDetailApi,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import { termsAndWarrantyData } from "../../../redux/Estimate/action";
import {
  CustomersListBasedOnType,
  createNewInvoice,
  createNewInvoiceDraft,
  invoiceDetails,
} from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import { appointmentDetails } from "../../../redux/appointment/action";
import { erMsgValidation } from "../../../utility/ValidationMessages";
import DropdownLoader from "../../Common/DropdownLoader";
import Loader from "../../Common/Loader";
import SuccessMessageModal from "../../Common/SuccessMessage";
import AddShopSupplyModal from "../../modal/AddShopSupplyModal";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";
import ServiceModal from "../../modal/ServiceModal";
import "../Invoice.css";

const styleChip = {
  color: "#002394",
  // marginLeft: "200px",
  width: "125px",
  height: "21px",
  padding: "4px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewInvoice = () => {
  const { id, appoinmentId, customerId, type } = useParams();
  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const serviceRef = useRef([]);

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [allService, setAllServices] = useState([]);
  // const [showLoader, setShowLoader] = useState(false);
  const [termsData, setTermsData] = React.useState("");

  const [getTermsData, setGetTermsData] = React.useState("");
  const [demoDescription, setDemoDescription] = React.useState("");

  const [customersListData, setCustomersListData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [shopSupplyData, setShopSupplyData] = useState([]);
  const [editShopSupply, setEditShopSupply] = useState({
    edit: false,
    index: "",
  });
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);
  const handleCloseCustomerInfo = () => {
    setOpenCustomerInfo(false);
  };
  const [commingFromModalData, setCommingFromModalData] = React.useState([]);
  const [commingPartsFromModalData, setCommingPartsFromModalData] =
    React.useState([]);
  const [editServiceData, setEditServiceData] = useState({
    edit: false,
    index: "",
  });
  const [IsSaveDraft, setIsSaveDraft] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    reset,
    setFocus,
  } = useForm();

  const handleOpenServiceModal = () => setOpenServiceModal(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);
  const [reqDate, setreqDate] = useState(new Date());
  const [customerData, setCustomerData] = React.useState("");
  const [vehicleData, setVehicleData] = React.useState("");
  const [selectDate, setSelectDate] = React.useState(null);
  const [completionDate, setCompletionDate] = React.useState(null);
  const [customValidationErr, setCustomValidationErr] = React.useState(null);
  const [odoValidationErr, setOdoValidationErr] = React.useState(null);
  const [customerDetail, setCustomerDetail] = React.useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });

  const [data, setData] = React.useState("");
  const [dropOffDate, setDropOffDate] = useState(null);
  const limit = 50;
  const [totals, setTotals] = useState({
    subTotal: 0,
    discount: 0,
    taxPercentage: 13,
    tax: 0,
    estimatedTotal: 0,
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [customerDropdownLoading, setCustomerDropdownLoading] = useState(false);
  const [vehicleDropdownLoading, setVehicleDropdownLoading] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState({});
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const [messageType, setMessageType] = useState("");
  const [discountError, setDiscountError] = useState(false);

  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };

  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: true,
  });
  useEffect(() => {
    reset({
      invoicePreparedBy: "",
      odoDroppedOff: "",
      odoReturned: "",
    });

    setCompletionDate(null);
    setSelectDate(null);
    setTotals({
      subTotal: 0,
      discount: 0,
      taxPercentage: 13,
      tax: 0,
      estimatedTotal: 0,
    });
    setAllServices([]);
    setCustomerData(null);
    setVehicleData(null);
    setData([]);

    setTermsData("");
    setDemoDescription("");
    setShopSupplyData([]);
  }, [window.location.pathname]);

  const onSubmitInvoice = async (values) => {
    // await trigger();
    if (discountError) {
      toast.error("Discount can not be more than subtotal amount.");
      return;
    }
    setMessageType("success");

    let dropOdo = values?.odoDroppedOff.toString()?.replace(/,/g, "");
    let pickUpOdo = values?.odoReturned.toString()?.replace(/,/g, "");

    if (+dropOdo > +pickUpOdo) {
      setOdoValidationErr(
        "Odometer Returned should be greater than Odometer Dropped Off"
      );
      return;
    } else {
      setOdoValidationErr(null);
    }
    // if (odoValidationErr != null) {
    //   return;
    // }

    if (new Date(selectDate) > new Date(completionDate)) {
      setCustomValidationErr(
        "Work Completion date will be always higher than Dropped off."
      );
      return;
    }
    if (customValidationErr != null) {
      return;
    }

    const completionDateinputDate = new Date(completionDate);
    const completionDateadjustedDate = new Date(
      Date.UTC(
        completionDateinputDate.getFullYear(),
        completionDateinputDate.getMonth(),
        completionDateinputDate.getDate(),
        0,
        0,
        0,
        0
      )
    );

    const completionDateisoString = completionDateadjustedDate.toISOString();

    const selectDateDateinputDate = new Date(selectDate);
    const selectDateDateadjustedDate = new Date(
      Date.UTC(
        selectDateDateinputDate.getFullYear(),
        selectDateDateinputDate.getMonth(),
        selectDateDateinputDate.getDate(),
        0,
        0,
        0,
        0
      )
    );

    const selectDateDateisoString = selectDateDateadjustedDate.toISOString();

    let payload = {
      customerId: customerData?.refIdUsers,
      invoicePreparedBy: values?.invoicePreparedBy,
      odoDroppedOff: dropOdo,
      odoReturned: pickUpOdo,
      vehicleId: vehicleData,
      appointmentCompletionDate: completionDateisoString,

      appointmentDroppedOffDate: selectDateDateisoString,
      shopSupplies: [...shopSupplyData],
      // ...termsData&&termsData.title!==""&&
      // {
      //   termsAndWarranty: {
      //     title: termsData?.title,
      //     description: demoDescription,
      //   },
      // },

      services: [],
      discount: totals?.discount,
      tax: totals?.tax,
      subTotal: totals?.subTotal,
      Total: totals?.estimatedTotal,
    };

    if (termsData && demoDescription) {
      payload.termsAndWarranty = {
        title: termsData.title,
        description: demoDescription,
      };
    }

    if (id) {
      payload.draftId = id;
    }
    let allServices = [];
    let priceNotAvailable = false;

    allService?.map((d, index) => {
      let data = {};
      data.serviceId = d?.service?._id;
      data.serviceName = d?.service?.name;
      if (d?.pricingType == "fixed") {
        data.pricingType = "fixed";

        data.fixedPrice = d?.fixedPrice ? d?.fixedPrice : "";
        if (!+d?.fixedPrice) {
          priceNotAvailable = true;
          return toast.error("Please add a price for all services added.");
        }
      } else {
        data.pricingType = "labor_&_parts";
        data.labor = { qty: d?.laborQuantity, ratePerHour: d?.laborRate };
        data.parts = [];
        d?.parts?.map((part) => {
          data.parts.push({
            partName: part?.partName,
            partNumber: part?.partNumber,
            description: part?.description,
            qty: part?.qty,
            cost: part?.cost,
            totalAmount: part?.totalAmount,
            partsProvider: part?.partsProvider,
          });
        });
      }
      allServices.push(data);
    });
    payload.services.push(...allServices);
    // if (priceNotAvailable) {
    //   return (
    //     setMessageType("error"),
    //     setOpenSuccessModal(true),
    //     setSuccessMessage("Please add a price for the services in the invoice.")
    //   );
    // }
    if (IsSaveDraft) {
      if (!values.invoicePreparedBy) {
        delete payload.invoicePreparedBy;
      }
      if (!values.odoDroppedOff) {
        delete payload.odoDroppedOff;
      }
      if (!values.odoReturned) {
        delete payload.odoReturned;
      }
      if (!completionDate) {
        delete payload.appointmentCompletionDate;
      }
      if (!selectDate) {
        delete payload.appointmentDroppedOffDate;
      }
      if (payload?.shopSupplies?.length == 0) {
        delete payload.shopSupplies;
      }
      if (payload?.services?.length == 0) {
        delete payload.services;
      }
      if (!payload?.termsAndWarranty?.title) {
        delete payload.termsAndWarranty;
      }
    }

    setShowLoader(true);
    if (!priceNotAvailable) {
      if (IsSaveDraft) {
        let result = await dispatch(createNewInvoiceDraft(payload));
        if (result) {
          setOpenSuccessModal(true);
          setSuccessMessage("Invoice draft was successfully created.");
          setCustomValidationErr(null);
          setTimeout(() => {
            navigate("/invoice/drafts");
          }, 1000);
        }
      } else {
        let result = await dispatch(createNewInvoice(payload));
        // console.log("🚀 ~ onSubmitInvoice ~ result:", result);
        if (result) {
          setOpenSuccessModal(true);
          setSuccessMessage("Invoice was sucessfully sent.");
          setCustomValidationErr(null);
          setTimeout(() => {
            navigate(`/invoice/sent/${result?._id}`);
          }, 1000);
        }
      }
    }
    setShowLoader(false);
  };

  useEffect(() => {
    let subTotal = 0;
    console.log("allService", allService);

    shopSupplyData?.map((suppu) => {
      subTotal += +suppu.totalAmount;
    });
    allService?.map((service) => {
      if (service?.pricingType == "fixed") {
        subTotal += +service?.fixedPrice;
      } else {
        subTotal += +(service?.laborQuantity * service?.laborRate);
        service?.parts?.map((part) => {
          subTotal += +part?.totalAmount;
        });
      }
    });
    if (subTotal == 0) {
      totals.discount = 0;
    }
    setTotals({
      subTotal: subTotal,
      taxPercentage: +totals?.taxPercentage,
      discount: +totals?.discount,
      tax:
        +totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0,
      estimatedTotal:
        subTotal -
        +totals?.discount +
        (+totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0),
    });
    if (subTotal > 0 && totals?.discount <= subTotal) {
      setDiscountError(false);
    } else if (subTotal == 0) {
      setDiscountError(false);
    } else {
      setDiscountError(true);
    }
  }, [allService, shopSupplyData]);
  const termsAndWarranty = async () => {
    const data = await dispatch(termsAndWarrantyData());
    const filteredData = data.map(({ _id, ...rest }) => rest);
    setGetTermsData(filteredData);
  };
  const removeServicesClicked = (idx) => {
    const newdata = [...allService];
    let newArray = [...newdata.slice(0, idx), ...newdata.slice(idx + 1)];
    setAllServices(newArray);
  };
  const EditServiceHandler = (idx) => {
    handleOpenServiceModal();
    setEditServiceData({ edit: true, index: idx });
  };

  const getDescriptionForTitle = (titleToFind, data) => {
    for (const item of data) {
      if (item.title === titleToFind) {
        return item.description;
      }
    }
    return "";
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmitInvoice)();
    }
  };

  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      fetchVehicles();
    }
  }, [customerData]);

  const fetchVehicles = async () => {
    // setShowLoader(true);
    setVehicleDropdownLoading(true);
    let data;
    if (id || appoinmentId) {
      data = await dispatch(vehicleDetails(10, 1, customerData?._id));
    } else {
      data = await dispatch(vehicleDetails(10, 1, customerData?.refIdUsers));
    }
    if (data) {
      setData(data);
      setVehicleDropdownLoading(false);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else {
        handleCloseCustomerInfo();
      }
    }
    setVehicleDropdownLoading(false);

    // setShowLoader(false);
  };
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && newVehicleCreatedId) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [data]);
  const customersLists = async (page, limit) => {
    // setCustomerDetail([]);
    if (customerId) {
      setShowLoader(true);
    }
    try {
      setCustomerListLoader(true);
      let data;
      data = await dispatch(
        CustomersListBasedOnType(customerSearch, customerTypeData, page, limit)
      );
      if (data.data) {
        // setCustomerDetail(data.data);
        setCustomerDetail([...customerDetail, ...data.data]);
        setServicePageData({
          totalpages: data?.totalPages,
          currentPage: data?.currentPage,
        });
        if (id) {
          setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (appoinmentId) {
          setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (customerId) {
          setCustomerData(data.data.find((d) => d._id == customerId));
        } else {
          setCustomerData("");
        }
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };
  useEffect(() => {
    if (customerTypeData.length != 0) {
      if (!id && !appoinmentId && !customerId && !type) {
        customersLists(page, limit);
      }
    } else {
      setCheckChangeLoader(false);
    }
  }, [customerTypeData]);
  useEffect(() => {
    if (!id && !appoinmentId && !customerId && !type) {
      setCustomerTypeData(["shop_created", "apex_auto_app"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        // shop_imported: true,
      });
    }
    termsAndWarranty();
    if (appoinmentId) {
      fetchAppointmentDetails(appoinmentId);
    }
    if (id) {
      fetchInvoiceDetails(id);
    }
    if (customerId) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
  }, [window.location.pathname]);

  const customerDataDetail = async () => {
    setShowLoader(true);

    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetailApi(customerId));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(customerId));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = customerId;
    resp.refIdImport = customerId;

    setCustomerData(resp);
    setCustomerDetail([resp]);
    setShowLoader(false);
  };

  const fetchAppointmentDetails = async (appoinmentId) => {
    setShowLoader(true);
    const resp = await dispatch(appointmentDetails(appoinmentId));
    if (resp) {
      setAppoinmentData(resp);
      const { _id, firstName, lastName } = resp?.customer;
      let fullname = firstName;
      if (lastName) fullname = fullname + " " + lastName;
      resp.customer = {
        ...resp?.customer,
        refIdUsers: _id,
        fullName: fullname,
      };

      setCustomerData(resp?.customer);
      setVehicleData(resp?.vehicle?._id);
      // setValue("odoDroppedOff", resp?.dropOffODO);
      // setValue("odoReturned", resp?.completionODO);

      setValue("odoDroppedOff", FormatNumberForKm(resp?.dropOffODO));
      setValue("odoReturned", FormatNumberForKm(resp?.completionODO ?? "0"));

      setCustomerTypeData(["shop_created", "apex_auto_app"]);
      if (
        resp?.customer?.createdFrom == "shop_created" ||
        resp?.customer?.createdFrom == "apex_auto_app"
      ) {
        setState({ shop_created: true, apex_auto_app: true });
      } else if (resp?.customer?.createdFrom == "shop_imported") {
        setState({ shop_imported: true });
      }

      const providedDate = dayjs(resp?.dropOffDateTime);

      setSelectDate(dayjs(dayjs(providedDate).format("YYYY-MM-DD")));

      const providedDateComplete = dayjs(resp?.shopClosingTime);

      setCompletionDate(
        dayjs(dayjs(providedDateComplete).format("YYYY-MM-DD"))
      );

      // setTermsData(
      //   resp?.termsAndWarranty.description ? resp?.termsAndWarranty : ""
      // );
      // setDemoDescription(resp?.termsAndWarranty?.description);

      setTotals({
        discount: +resp?.discount ? +resp?.discount : 0,
        tax: +resp?.tax ? +resp?.tax : 0,

        estimatedTotal: +resp?.Total ? +resp?.Total : 0,

        taxPercentage: +(
          parseInt(parseInt(resp?.tax == null ? 0 : resp?.tax) * 100) /
          +(
            parseInt(resp?.subTotal == null ? 0 : resp?.subTotal) -
            parseInt(resp?.discount == null ? 0 : resp?.discount)
          )
        )
          ? +(
              parseInt(parseInt(resp?.tax == null ? 0 : resp?.tax) * 100) /
              +(
                parseInt(resp?.subTotal == null ? 0 : resp?.subTotal) -
                parseInt(resp?.discount == null ? 0 : resp?.discount)
              )
            )
          : 13,
        subTotal: +resp?.subTotal ? +resp?.subTotal : 0,
      });

      let services = [];
      let notNewAddedData;
      let newAddedData;

      resp?.services?.map((data) => {
        notNewAddedData = resp?.services?.filter(
          (item) => item.newAdded == false
        );
        newAddedData = resp?.services?.filter((item) => item.newAdded);

        // console.log("foo newAddedData", newAddedData);

        let approvedItem = [];
        if (newAddedData.length > 0) {
          approvedItem = newAddedData.filter(
            (item) => item.approvalStatus === "approved"
          );

          // console.log("foo approvedItem", approvedItem);

          if (approvedItem) {
            approvedItem?.map((item) => {
              notNewAddedData.push(item);
            });
          }
        }
      });

      // console.log("foo notNewAddedData", notNewAddedData);

      notNewAddedData?.map((item) => {
        // console.log("foo item", item);
        // let service = {};

        // if (item?.pricingType === "fixed") {
        //   service._id = item.serviceId;
        //   service.name = item.serviceName;
        //   service.pricingType = "fixed";
        //   service.fixedPrice = item.fixedPrice ? item.fixedPrice : "";
        // }

        // if (item?.pricingType == "labor_&_parts") {
        //   service._id = item.serviceId;
        //   service.name = item.serviceName;
        //   service.pricingType = "labor_&_parts";
        //   service.labor = {
        //     qty: item?.labor?.qty,
        //     ratePerHour: item?.labor?.ratePerHour,
        //     totalAmount: item?.labor?.totalAmount,
        //   };
        //   service.parts = [];
        //   item?.parts?.map((part) => {
        //     service.parts.push({
        //       partName: part?.partName,
        //       partNumber: part?.partNumber,
        //       description: part?.description,
        //       qty: part?.qty,
        //       cost: part?.cost,
        //       totalAmount: part?.totalAmount,
        //       partsProvider: part?.partsProvider,
        //     });
        //   });
        // }

        let service = {
          ...item,
          // laborQuantity: item?.labor?.qty ? item?.labor?.qty : undefined,
          // laborRate: item?.labor?.ratePerHour
          //   ? item?.labor?.ratePerHour
          //   : undefined,
          service: { _id: item.serviceId, name: item.serviceName },
        };
        if (item?.pricingType == "no_price") {
          service.fixedPrice = "0";
          service.pricingType = "fixed";
        }
        if (item?.pricingType == "range") {
          service.fixedPrice = item.priceMin;
          service.pricingType = "fixed";
        }

        if (item?.labor?.qty) {
          service.laborQuantity = item?.labor?.qty;
          service.laborRate = item?.labor?.ratePerHour;
        } else {
          delete service.laborQuantity;
          delete service.laborRate;
        }

        if (item?.parts?.length > 0) {
          service.parts = [];
          item?.parts?.map((part) => {
            service.parts.push({
              partName: part?.partName,
              partNumber: part?.partNumber,
              description: part?.description,
              qty: part?.qty,
              cost: part?.cost,
              totalAmount: part?.totalAmount,
              partsProvider: part?.partsProvider,
            });
          });
        } else {
          delete service.parts;
        }

        delete service.labor;
        delete service.serviceName;
        delete service.serviceId;

        // console.log("foo service", service);
        services.push(service);

        // console.log("foo services", services);

        // return services;
      });

      setAllServices(services);
    }
    setShowLoader(false);
  };

  const fetchInvoiceDetails = async (id) => {
    setShowLoader(true);
    const resp = await dispatch(invoiceDetails(id));

    if (resp) {
      setValue("invoicePreparedBy", resp?.invoicePreparedBy);

      setCustomerTypeData(["shop_created", "apex_auto_app"]);
      if (
        resp?.customer?.createdFrom == "shop_created" ||
        resp?.customer?.createdFrom == "apex_auto_app"
      ) {
        setState({ shop_created: true, apex_auto_app: true });
      } else if (resp?.customer?.createdFrom == "shop_imported") {
        setState({ shop_imported: true });
      }
      setVehicleData(resp?.vehicle?._id);
      // setValue("odoDroppedOff", resp?.odoDroppedOff);
      // setValue("odoReturned", resp?.odoReturned);
      setValue("odoDroppedOff", FormatNumberForKm(resp?.odoDroppedOff));
      setValue("odoReturned", FormatNumberForKm(resp?.odoReturned));

      const providedDate = dayjs(resp?.appointmentDroppedOffDate);

      setSelectDate(dayjs(dayjs(providedDate).format("YYYY-MM-DD")));
      const providedDateComplete = dayjs(resp?.appointmentCompletionDate);

      setCompletionDate(
        dayjs(dayjs(providedDateComplete).format("YYYY-MM-DD"))
      );

      setTermsData(
        resp?.termsAndWarranty?.description ? resp?.termsAndWarranty : ""
      );
      setDemoDescription(resp?.termsAndWarranty?.description);

      setTotals({
        discount: +resp?.discount,
        tax: +resp?.tax,

        estimatedTotal: +resp?.Total,

        taxPercentage: +(
          +(+(resp?.tax == null ? 0 : resp?.tax) * 100) /
          +(
            +(resp?.subTotal == null ? 0 : resp?.subTotal) -
            +(resp?.discount == null ? 0 : resp?.discount)
          )
        )
          ? +(
              +(+(resp?.tax == null ? 0 : resp?.tax) * 100) /
              +(
                +(resp?.subTotal == null ? 0 : resp?.subTotal) -
                +(resp?.discount == null ? 0 : resp?.discount)
              )
            )
          : 0,
        subTotal: +resp?.subTotal,
      });

      let supplyData = [];
      resp?.shopSupplies?.map((data) => {
        let finalData = {
          itemName: data?.itemName,
          cost: data?.cost + " ",
          qty: data?.qty + " ",
          totalAmount: data?.totalAmount + " ",
        };
        supplyData.push(finalData);
      });
      setShopSupplyData(supplyData);

      let services = [];

      resp?.services?.map((data) => {
        let service = {
          ...data,
          laborQuantity: data?.labor?.qty,
          laborRate: data?.labor?.ratePerHour,

          service: { _id: data.serviceId?._id, name: data?.serviceId?.name },
          serviceCategory: {
            _id: data?.serviceId?.serviceCategory?._id,
            name: data?.serviceId?.serviceCategory?.name,
          },
        };

        delete data?.labor?.qty;
        delete data?.labor?.ratePerHour;

        delete service["serviceId"];
        delete service["serviceName"];
        services.push(service);
      });
      if (resp && resp?.customer && resp?.customer?._id) {
        resp.customer = {
          ...resp?.customer,
          refIdUsers: resp?.customer?._id,
        };
        setCustomerData(resp?.customer);
      }
      if (resp && resp?.vehicle && resp?.vehicle?._id) {
        setVehicleData(resp?.vehicle?._id);
      }
      setAllServices(services);
    }
    setShowLoader(false);
  };
  // const invoiceData = useSelector((state) => state.invoice.invoiceDetails);

  const [open3, setOpen3] = React.useState(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
    setEditShopSupply({ edit: false, index: "" });
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };

  const deleteshopSupplies = (idx) => {
    const filteredShopSupplyData = shopSupplyData.filter(
      (item, index) => index !== idx
    );
    setShopSupplyData(filteredShopSupplyData);
  };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 100; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };

  const cancelInvoice = () => {
    if (id) {
      customersLists();
      termsAndWarranty();

      fetchInvoiceDetails(id);
    }
    navigate(-1);
    if (appoinmentId) {
      customersLists();
      termsAndWarranty();
      fetchAppointmentDetails(appoinmentId);
      setValue("invoicePreparedBy", "");
      setTotals({
        subTotal: 0,
        discount: 0,
        taxPercentage: 0,
        tax: 0,
        estimatedTotal: 0,
      });
      setTermsData("");
      setDemoDescription("");
      setShopSupplyData([]);
      setAllServices([]);
    } else {
      setValue("invoicePreparedBy", "");
      setValue("odoDroppedOff", "");
      setValue("odoReturned", "");
      setTotals({
        subTotal: 0,
        discount: 0,
        taxPercentage: 0,
        tax: 0,
        estimatedTotal: 0,
      });
      setCustomerData("");
      setVehicleData("");
      setSelectDate(null);
      setCompletionDate(null);
      setTermsData("");
      setDemoDescription("");
      setShopSupplyData([]);
      setAllServices([]);
    }
  };

  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);
    setCustomerDetail([]);
    try {
      setCustomerListLoader(true);
      let data;
      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limit)
      );
      if (data) {
        setCustomerDetail(data.data);

        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);

    setCustomerDetail([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");
      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };
  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customersLists(page + 1, limit);
    }
  };
  // if (showLoader) {
  //   return <Loader showLoader={showLoader} />;
  // }
  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{
          width: "fit-content",
          minWidth: "100%",
        }}
      >
        {" "}
        <SuccessMessageModal
          message={successMessage}
          open={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
          alertType={messageType}
        />
        <form onSubmit={handleSubmit(onSubmitInvoice, onInvalid)}>
          <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="/invoice/all"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Invoices
                  </Link>
                  <Link
                    // to="/invoice/new"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {id ? "Drafts " : "New Invoice "}
                    {/* Invoice */}
                  </Link>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                {id ? "Edit " : "New "}
                Invoice
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={"24px"} margin={"32px 0"} boxShadow={2}>
            <Grid container item justifyItems={"space-between"}>
              <Grid
                container
                item
                justifyItems={"space-between"}
                rowGap={3}
                padding={"10px 0px"}
              >
                <Grid item container columns={16}>
                  <Grid item md={5.2}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Invoice Prepared By
                    </Typography>
                    <Controller
                      name="invoicePreparedBy"
                      control={control}
                      rules={{
                        required: erMsgValidation.invoicePreparedBy,
                      }}
                      render={({ field }) => (
                        <TextField
                          // label="Invoice Prepared By"
                          variant="outlined"
                          fullWidth
                          error={!!errors.invoicePreparedBy}
                          helperText={
                            errors.invoicePreparedBy
                              ? errors.invoicePreparedBy.message
                              : ""
                          }
                          {...field}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Customer <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerDetail ? customerDetail : []}
                        getOptionLabel={(option) =>
                          // option.firstName + " " + option.lastName
                          option.fullName
                        }
                        value={customerData ? customerData : null}
                        onChange={(event, newValue) => {
                          // Handle the change here if needed
                          setVehicleData("");
                          setCustomerData(newValue);
                          setCustomerCretedFrom(newValue?.createdFrom);
                          if (customerData != null) {
                            setData("");
                          }
                        }}
                        loading={customerListLoader}
                        loadingText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress />
                          </div>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            onChange={(e) =>
                              handleInputDebounced(e, e.target.value)
                            }
                            onBlur={handleBlur}
                            // style={{cursor: "not-allowed"}}
                          />
                        )}
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            handleScroll(event);
                          },
                        }}
                        disabled={
                          id ||
                          customerId ||
                          appoinmentId ||
                          customerTypeData.length == 0
                        }
                        renderOption={(props, option) => (
                          <>
                            <Box component="li" {...props}>
                              <li
                                {...props}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => {
                                  if (option.createdFrom == "shop_imported") {
                                    handleOpenCustomerInfo();
                                  }
                                }}
                              >
                                <div>
                                  {/* {option.firstName + " " + option.lastName} */}
                                  {option.fullName}
                                </div>
                                <div>
                                  <Chip
                                    style={{
                                      ...styleChip,
                                      display: "flex",
                                      background:
                                        option.createdFrom == "shop_created"
                                          ? "#75DBFF80"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#E1D0FD"
                                          : "#EFEFEF",
                                      color:
                                        option.createdFrom == "shop_created"
                                          ? "#000000"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#3E1C76"
                                          : "#002394",
                                    }}
                                    className="chip-text-style"
                                    label={
                                      option.createdFrom == "shop_created"
                                        ? "Shop Created"
                                        : option.createdFrom == "shop_imported"
                                        ? "Shop Imported"
                                        : "Apex Auto"
                                    }
                                  />
                                </div>
                              </li>

                              {/* <li>
                                <CircularProgress />
                              </li> */}
                            </Box>
                            <Box component="li" {...props}>
                              {props["data-option-index"] + 1 ==
                                customerDetail?.length &&
                                customerListLoader && (
                                  <li
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",

                                      display: "flex",
                                    }}
                                  >
                                    <CircularProgress />
                                  </li>
                                )}
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  </Grid>
                  {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={id || customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={id || customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={id || customerId || appoinmentId}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Vehicle
                    </Typography>
                    <Select
                      // value={vehicleData}
                      value={
                        Array.isArray(data) && customerData !== null
                          ? vehicleData
                          : ""
                      }
                      // label="customer"
                      // disabled={appoinmentId && appoinmentData?.vehicle?._id}
                      onChange={(e) => {
                        setVehicleData(e.target.value);
                      }}
                      className="customer-dropdown"
                    >
                      {(data ? data : [])?.map((data, idx) => {
                        if (data)
                          return (
                            <MenuItem
                              value={data._id}
                              onClick={() => {
                                if (
                                  data.source &&
                                  data.source == "shop_imported"
                                ) {
                                  setVehicleSourceData(data);
                                  handleVehicleInfoOpen();
                                }
                              }}
                            >
                              {data?.vehicleYear ? data?.vehicleYear : " "}
                              {data?.year && data?.year}{" "}
                              {data?.vehicleMake?.name &&
                                data?.vehicleMake?.name}{" "}
                              {data?.vehicleModel?.name &&
                                data?.vehicleModel?.name}{" "}
                              {data?.vehicleModelTrim?.name &&
                                data?.vehicleModelTrim?.name}
                            </MenuItem>
                          );
                      })}
                      {vehicleDropdownLoading && <DropdownLoader />}
                    </Select>
                  </Grid>
                  {/* {addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && ( */}
                  {customerData &&
                    customerData?.createdFrom != "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleVehicleInfoOpen();
                            setVehicleSourceData("");
                          }}
                        >
                          {" "}
                          + Add New Vehicle
                        </Button>
                      </Grid>
                    )}

                  {/* )} */}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Odometer Dropped Off
                    </Typography>

                    {/* <FormatNumberForKm inputValue={"10000"} /> */}
                    <Controller
                      name="odoDroppedOff"
                      control={control}
                      rules={{
                        required: !IsSaveDraft
                          ? "Dropped off odo is required"
                          : undefined,
                      }}
                      render={({ field }) => (
                        <TextField
                          // label="Odometer Dropped Off"
                          // disabled={appoinmentId && appoinmentData?.dropOffODO}
                          variant="outlined"
                          fullWidth
                          // type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">km</InputAdornment>
                            ),
                          }}
                          error={!!errors.odoDroppedOff}
                          helperText={
                            errors.odoDroppedOff
                              ? errors.odoDroppedOff.message
                              : ""
                          }
                          {...field}
                          onChange={(event) => {
                            setValue(
                              "odoDroppedOff",
                              FormatNumberForKm(event.target.value)
                            );
                          }}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.5} xs={5.5}>
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Odometer Returned
                    </Typography>
                    <Controller
                      name="odoReturned"
                      control={control}
                      rules={{ required: erMsgValidation.odoReturned }}
                      render={({ field }) => (
                        <TextField
                          // label="Odometer Returned"
                          // type="number"
                          variant="outlined"
                          // disabled={appoinmentId && appoinmentData?.completionODO}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">km</InputAdornment>
                            ),
                          }}
                          error={!!errors.odoReturned}
                          helperText={
                            errors.odoReturned ? errors.odoReturned.message : ""
                          }
                          {...field}
                          onChange={(event) => {
                            setValue(
                              "odoReturned",
                              FormatNumberForKm(event.target.value)
                            );
                          }}
                          onKeyPress={handleKeyPress}
                          InputLabelProps={{ shrink: field.value?.length > 0 }}
                          // required={!IsSaveDraft}
                        />
                      )}
                    />

                    {/* <TextField
                    label="Odometer Returned"
                    id="outlined-end-adornment"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">km</InputAdornment>
                      ),
                    }}
                  /> */}
                  </Grid>
                  <Typography className="err-validation">
                    {odoValidationErr}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={5.9}
                    sm={5.5}
                    xs={5.5}
                    className="width-100-pers"
                  >
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Dropped Off
                    </Typography>
                    {/* <Controller
                      name="appointmentDroppedOffDate"
                      control={control}
                      rules={{
                        required: "appointmentDroppedOffDate is required",
                      }}
                      render={({ field }) => ( */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Select date"
                        // disabled={appoinmentId && appoinmentData?.dropOffDateTime}
                        value={selectDate || null}
                        // disablePast={true}
                        onChange={(date) => {
                          if (
                            completionDate &&
                            dayjs(dayjs(date).format("YYYY-MM-DD")) >
                              new Date(completionDate)
                          ) {
                            setCustomValidationErr(
                              "Work Completion date will be always higher than Dropped off."
                            );
                          } else {
                            setCustomValidationErr(null);
                          }

                          // field.onChange(date);
                          // const formattedDate = new Date(date);
                          // setSelectDate(formattedDate);
                          setSelectDate(
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          );
                        }}
                        style={{ width: "100%" }}
                        components={{
                          OpenPickerIcon: KeyboardArrowDown,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.appointmentDroppedOffDate}
                            helperText={
                              errors.appointmentDroppedOffDate
                                ? errors.appointmentDroppedOffDate.message
                                : ""
                            }
                            required={!IsSaveDraft}
                          />
                        )}
                        // slotProps={{ field: { clearable: true } }}
                      />
                      {/* <DatePicker
                            label="Select date"
                            value={selectDate}
                            // disablePast={true}
                            onChange={(data) => {
                              if (data) {
                                const formattedDate = new Date(data);
                                setSelectDate(formattedDate);
                              } else {
                                setSelectDate(null);
                              }
                            }}
                            style={{ width: "100%" }}
                            slotProps={{ field: { clearable: true } }}
                          /> */}
                    </LocalizationProvider>
                    {/* )} */}
                    {/* /> */}
                  </Grid>
                  <Grid
                    item
                    xl={5.9}
                    lg={5.9}
                    md={5.9}
                    sm={5.5}
                    xs={5.5}
                    className="width-100-pers"
                  >
                    <Typography className="edit-invoice-form-title style-15-500-24-px">
                      Work Completion Date
                    </Typography>
                    {/* <Controller
                      name="appointmentCompletionDate"
                      control={control}
                      rules={{
                        required: "appointmentCompletionDate is required",
                      }}
                      render={({ field }) => ( */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <DatePicker
                            // label="Work Completion Date"
                            value={field.value}
                            {...field}
                            components={{
                              OpenPickerIcon: KeyboardArrowDown,
                            }}
                            onKeyPress={handleKeyPress}
                            shouldDisableDate={disablePrevDates(dropOffDate)}
                          /> */}
                      <DatePicker
                        // label="Select date"
                        value={completionDate || null}
                        // disabled={appoinmentId && appoinmentData?.shopClosingTime}
                        // disablePast={true}
                        onChange={(date) => {
                          if (
                            new Date(selectDate) >
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          ) {
                            setCustomValidationErr(
                              "Work Completion date will be always higher than Dropped off."
                            );
                          } else {
                            setCustomValidationErr(null);
                          }

                          setCompletionDate(
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          );

                          // field.onChange(date);
                          // const formattedDate = new Date(date);
                          // setCompletionDate(formattedDate);
                        }}
                        style={{ width: "100%" }}
                        components={{
                          OpenPickerIcon: KeyboardArrowDown,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.appointmentDroppedOffDate}
                            helperText={
                              errors.appointmentDroppedOffDate
                                ? errors.appointmentDroppedOffDate.message
                                : ""
                            }
                            required={!IsSaveDraft}
                          />
                        )}
                        // slotProps={{ field: { clearable: true } }}
                      />
                    </LocalizationProvider>
                    {/* )}
                    /> */}
                  </Grid>
                </Grid>
                <Typography className="err-validation">
                  {customValidationErr}
                </Typography>
                <Divider
                  style={{
                    width: "100%",
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "4px",
                  }}
                />
              </Grid>
              <Grid item container justifyContent={"end"} padding={"12px 0px"}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpenServiceModal}
                    className="btn-text-size-manage"
                  >
                    ADD SERVICE
                  </Button>
                </Grid>
              </Grid>
              {allService?.map((data, index) => {
                return (
                  <>
                    {(data?.pricingType == "fixed" ||
                      data?.pricingType == "no_price") && (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                      width={"120px"}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.service?.name}
                                  </TableCell>
                                  <TableCell align="left">1</TableCell>{" "}
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {data?.pricingType == "labor_&_parts" && (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          {/* {console.log("foo data", data)} */}
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={0}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    width={"120px"}
                                  >
                                    {1}
                                  </TableCell>
                                  <TableCell align="left">Labor</TableCell>

                                  <TableCell align="left" width={"120px"}>
                                    {data?.laborQuantity}
                                  </TableCell>
                                  <TableCell align="left" width={"120px"}>
                                    {/* {console.log("foo row", data)} */}

                                    {data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={data?.laborRate}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left" width={"120px"}>
                                    {data?.laborQuantity && data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={
                                          data?.laborQuantity * data?.laborRate
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                                {data?.parts?.map((row, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      width={"120px"}
                                    >
                                      {idx + 2}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                      >
                                        {row?.partName}
                                      </Typography>

                                      <Typography
                                        fontWeight={400}
                                        fontSize={12}
                                      >
                                        Part Provider:{" "}
                                        {row?.partsProvider == "oem" && "OEM"}{" "}
                                        {row?.partsProvider == "after_market" &&
                                          "After Market"}{" "}
                                        {row?.partsProvider == "used" && "Used"}
                                      </Typography>
                                      {row?.partNumber && (
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          fontSize={12}
                                        >
                                          Part Number: {row?.partNumber}
                                        </Typography>
                                      )}
                                      {row?.description && (
                                        <Typography
                                          variant="body2"
                                          fontSize={12}
                                          // color={"#848484"}
                                        >
                                          Description: {row?.description}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      {row.qty}
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      <FormatNumber
                                        inputValue={row.cost ? row.cost : "-"}
                                      />
                                    </TableCell>
                                    <TableCell align="left" width={"120px"}>
                                      {row.totalAmount ? (
                                        <FormatNumber
                                          inputValue={row.totalAmount}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            {/* <Grid
                            container
                            item
                            justifyContent={"end"}
                            padding={"24px"}
                          >
                            <Grid item>
                              <Stack direction="row" spacing={2}>
                                <Button
                                  variant="contained"
                                  startIcon={<Edit />}
                                  onClick={handleOpen2}
                                  className="btn-text-size-manage"
                                >
                                  EDIT SERVICE
                                </Button>
                                <EditServiceModal
                                  open2={open2}
                                  handleClose2={handleClose2}
                                  handleOpen1={handleOpen1}
                                  handleOpen2={handleOpen2}
                                />
                                <Button
                                  variant="contained"
                                  color="error"
                                  startIcon={<Delete />}
                                  className="btn-text-size-manage"
                                >
                                  REMOVE SERVICE
                                </Button>
                              </Stack>
                            </Grid>{" "}
                          </Grid> */}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      container
                      item
                      justifyContent={"end"}
                      padding={"24px"}
                      ref={serviceRef.current[index]}
                    >
                      <Grid item>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => {
                              EditServiceHandler(index);
                            }}
                            className="btn-text-size-manage"
                          >
                            EDIT SERVICE
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<Delete />}
                            className="btn-text-size-manage"
                            onClick={() => {
                              removeServicesClicked(index);
                            }}
                          >
                            REMOVE SERVICE
                          </Button>
                        </Stack>
                      </Grid>{" "}
                    </Grid>
                  </>
                );
              })}
              <Divider
                style={{
                  width: "100%",
                  borderBottomStyle: "dotted",
                  borderBottomWidth: "4px",
                }}
              />
              <Grid item container justifyContent={"end"} padding={"20px 0px "}>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpen3}
                    className="btn-text-size-manage"
                  >
                    ADD SHOP SUPPLIES
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{
                  backgroundColor: "#c5d0da",
                  color: "black",
                  marginTop: "5px",
                }}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    padding={"16px"}
                    fontSize={"20px"}
                  >
                    Shop Supplies
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xl={12} md={12} sm={12} xs={12} boxShadow={0}>
                  <Table
                    aria-label="caption table"
                    padding="16px"
                    style={{ boxShadow: 2 }}
                  >
                    <TableHead
                      style={{ backgroundColor: "#f4f6f8", fontWeight: 500 }}
                    >
                      <TableRow>
                        <TableCell align="left" width={"9.6%"}>
                          No.
                        </TableCell>
                        <TableCell> Description</TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Quantity
                        </TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Cost
                        </TableCell>
                        <TableCell align="left" width={"9.6%"}>
                          {" "}
                          Amount
                        </TableCell>
                        <TableCell width={"280px"}> Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shopSupplyData.map((row, index) => (
                        <TableRow key={row.no}>
                          <TableCell component="th" scope="row" width={"9.6%"}>
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">{row.itemName}</TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {row?.qty}
                          </TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {" "}
                            <FormatNumber inputValue={row.cost} />
                          </TableCell>
                          <TableCell align="left" width={"9.6%"}>
                            {" "}
                            <FormatNumber inputValue={row.totalAmount} />
                          </TableCell>
                          <TableCell align="left" className="gap-of-btns">
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Edit />}
                              // onClick={handleOpen3}
                              onClick={() => {
                                setOpen3(true);
                                setEditShopSupply({ edit: true, index: index });
                              }}
                              className="btn-text-size-manage"
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              startIcon={<Delete />}
                              onClick={() => {
                                deleteshopSupplies(index);
                              }}
                            >
                              REMOVE
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid container item justifyContent={"end"} padding={"24px"}>
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <Stack direction="column">
                          <TextField
                            variant="outlined"
                            size="medium"
                            label="Discount($)"
                            color={discountError ? "error" : "primary"}
                            value={totals?.discount}
                            disabled={totals?.subTotal == 0 ? true : false}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                setTotals({
                                  discount: +e.target.value,
                                  tax:
                                    totals?.taxPercentage > 0
                                      ? ((totals?.subTotal - +e.target.value) *
                                          totals?.taxPercentage) /
                                        100
                                      : 0,

                                  estimatedTotal:
                                    totals?.subTotal -
                                    e.target.value +
                                    (totals?.taxPercentage > 0
                                      ? ((totals?.subTotal - +e.target.value) *
                                          totals?.taxPercentage) /
                                        100
                                      : 0),
                                  taxPercentage:
                                    totals?.taxPercentage > 0
                                      ? totals?.taxPercentage
                                      : 0,
                                  subTotal: totals?.subTotal,
                                });
                                if (e.target.value > totals?.subTotal) {
                                  setDiscountError(true);
                                } else {
                                  setDiscountError(false);
                                }
                              } else {
                                toast.error("Please Enter Number value");
                              }
                            }}
                          />
                          {discountError && (
                            <p
                              style={{
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                                letterSpacing: "0.03333em",
                                lineHeight: "1.66",
                                marginTop: "3px",
                              }}
                            >
                              Discount can not be more than subtotal amount
                            </p>
                          )}
                        </Stack>
                        <TextField
                          variant="outlined"
                          size="medium"
                          value={totals?.taxPercentage}
                          label="Taxes (%)"
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setTotals({
                                taxPercentage: +e.target.value,
                                tax:
                                  ((totals?.subTotal - totals?.discount) *
                                    e.target.value) /
                                  100,
                                estimatedTotal:
                                  totals?.subTotal -
                                  totals?.discount +
                                  ((totals?.subTotal - totals?.discount) *
                                    +e.target.value) /
                                    100,
                                discount: totals?.discount,
                                subTotal: totals?.subTotal,
                              });
                            } else {
                              toast.error("Please Enter Number value");
                            }
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                justifyContent={"end"}
                padding={"0px 23px 24px 24px"}
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>Sub Total</Typography>

                      <Typography mb={1}>Discount</Typography>

                      <Typography mb={1}>Tax</Typography>
                      <Typography fontWeight={500}> Total</Typography>
                    </Box>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.subTotal} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.discount} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.tax} />
                      </Typography>
                      <Typography>
                        <FormatTotalNumbers
                          inputValue={totals?.estimatedTotal}
                        />
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={"start"}
                marginTop={"32px"}
                columnGap={4}
              >
                <Grid item lg={2.7} xl={2.7} md={2.7} sm={2.7} xs={2.7}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={getTermsData ? getTermsData : []}
                    onChange={(e, value) => {
                      setTermsData(value);
                      setDemoDescription(
                        getDescriptionForTitle(value?.title, getTermsData)
                      );
                    }}
                    value={termsData == "" ? null : termsData}
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.title}
                      </Box>
                    )}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Terms and Warranty"
                        // required={!IsSaveDraft}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  lg={8.5}
                  xl={8.9}
                  md={8.9}
                  sm={8.9}
                  xs={8.9}
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    width: "72%",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      maxWidth={"100%"}
                      className="desc-style"
                    >
                      {demoDescription}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"end"}>
            <Grid item>
              <Stack spacing={2} direction={"row"}>
                <Button
                  color="error"
                  variant="contained"
                  className="btn-text-size-manage"
                  onClick={cancelInvoice}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="btn-text-size-manage"
                  onClick={() => setIsSaveDraft(true)}
                >
                  SAVE AS DRAFT
                </Button>
                <Button
                  variant="contained"
                  className="btn-text-size-manage"
                  type="submit"
                  onClick={() => setIsSaveDraft(false)}
                >
                  SAVE AND SEND
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <AddShopSupplyModal
        open={open3}
        handleClose={handleClose3}
        setShopSupplyData={setShopSupplyData}
        shopSupplyData={shopSupplyData}
        editShopSupply={editShopSupply}
        setEditShopSupply={setEditShopSupply}
      />
      <ConfirmCustomersInfoModel
        open={openCustomerInfo}
        handleClose={handleCloseCustomerInfo}
        customerData={customerData}
        id={customerData?.refIdImport}
        vehicalDetailFunction={fetchVehicles}
        setAddCustomerInfoButton={setAddCustomerInfoButton}
        setCustomerData={setCustomerData}
        setCustomerTypeData={setCustomerTypeData}
        state={state}
        setState={setState}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        madeFromCustomerProfile={false}
      />
      <MissingVehicleInfoModal
        open={vehicleInfoOpen}
        handleClose={handleVehicleInfoClose}
        id={customerData?.refIdUsers}
        vehicalDetailFunction={fetchVehicles}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        setVehicleCreatedId={setVehicleCreatedId}
        vehicleSource={vehicleSourceData}
      />
      <ServiceModal
        open={openServiceModal}
        setOpenServiceModal={setOpenServiceModal}
        setAllServices={setAllServices}
        allServices={allService}
        editServiceData={editServiceData}
        setEditServiceData={setEditServiceData}
        // open1={open1}
        // handleClose1={handleClose1}
        // handleClose={handleClose}
        // handleOpen1={handleOpen1}

        // serviceModalDataProps={(data) => {
        //   setCommingFromModalData(data);
        // }}
        // getData={commingFromModalData}
        // editServiceData={editServiceData}
        // servicePartsModalDataProps={(data) => {
        //   setCommingPartsFromModalData(data);
        // }}
      />
    </>
  );
};

export default NewInvoice;
