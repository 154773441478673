import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useContext } from "react";
import { Context } from "../../../App";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
// const GOOGLE_MAPS_API_KEY = "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleAutoComplete({
  setCompleteAddress,
  setPlaceId,
  getAddress,
}) {
  const { GOOGLE_MAPS_API_KEY } = useContext(Context);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (getAddress) {
      setValue({
        description: getAddress,
        structured_formatting: {
          main_text: getAddress,
          secondary_text: getAddress,
        },
      });
      setOptions({
        description: getAddress,
        structured_formatting: {
          main_text: getAddress,
          secondary_text: getAddress,
        },
      });
    }
  }, [getAddress]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "ca" } },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      // sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={Array.isArray(options) ? options : []}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue) {
          const request = {
            placeId: newValue.place_id,
            fields: ["address_components", "formatted_address", "geometry"],
          };

          const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );

          service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setCompleteAddress(place);
              setPlaceId(newValue?.place_id);
              // Access complete address components
              const completeAddress = place.formatted_address;

              // Access address components
              const addressComponents = place.address_components;

              // Access latitude and longitude
              const { lat, lng } = place.geometry.location;

              // You can now use completeAddress, addressComponents, lat, and lng as needed

              setOptions([newValue, ...options]);
              const route = addressComponents.filter(
                (data) => data.types[0] == "route"
              );

              const streetNumber = addressComponents.filter(
                (data) => data.types[0] == "street_number"
              );

              let completeStreetAddress = "";
              if (streetNumber.length != 0) {
                completeStreetAddress += streetNumber[0]?.long_name + " ";
              }
              if (route.length != 0) {
                completeStreetAddress += route[0]?.long_name;
              }

              setValue(completeStreetAddress);
            }
          });
        } else {
          setOptions(options);
          setValue(null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        console.log("event, newValue", event, newInputValue);

        setInputValue(newInputValue);
        if (!newInputValue) setCompleteAddress({});
      }}
      renderInput={(params) => (
        <TextField {...params} /*label="Street Address" */ fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
