import { Add, Delete, Edit, KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { REQUIRED_RED } from "../../../App";
import FormatNumber, {
  FormatNumberForKm,
  FormatTotalNumbers,
  preventPhoneInputKeys,
} from "../../../components/FormatNumber/FormatNumber";
import { SHOP_IMPORTED } from "../../../config/dataService/constants";
import {
  customerDetail,
  importedCustomerDetail,
} from "../../../redux/Customer/action";
import {
  createEstimateData,
  createEstimateDraftData,
  estimateData,
  estimateRequestData,
  termsAndWarrantyData,
} from "../../../redux/Estimate/action";
import { CustomersListBasedOnType } from "../../../redux/Invoice/action";
import { vehicleDetails } from "../../../redux/Vehicles/action";
import DropdownLoader from "../../Common/DropdownLoader";
import SuccessMessageModal from "../../Common/SuccessMessage";
import ConfirmCustomersInfoModel from "../../modal/ConfirmCustomersInfo";
import MissingVehicleInfoModal from "../../modal/MissingVehicleInfoModal";
import ServiceModal from "../../modal/ServiceModal";

const styleChip = {
  color: "#002394",
  // marginLeft: "200px",
  width: "125px",
  height: "21px",
  padding: "4px",
  alignItems: "right",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "500",
};

const NewEstimates = () => {
  const { id, customerId, estimateRequestId, type } = useParams();

  const navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [allService, setAllServices] = useState([]);
  console.log("🚀 ~ NewEstimates ~ allService:", allService);
  const [termsData, setTermsData] = React.useState("");
  const [getTermsData, setGetTermsData] = React.useState("");
  const [demoDescription, setDemoDescription] = React.useState("");
  const [customersListData, setCustomersListData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerListLoader, setCustomerListLoader] = React.useState(false);
  const [customerTypeData, setCustomerTypeData] = React.useState([]);
  const [odoValidation, setOdoValidation] = React.useState("");
  const [newVehicleCreatedId, setVehicleCreatedId] = useState("");

  const [
    customValidationErrForCompletion,
    setCustomValidationErrForCompletion,
  ] = React.useState(null);
  const [customValidationErrForExpiry, setCustomValidationErrForExpiry] =
    React.useState(null);
  const [customerDropdownLoading, setCustomerDropdownLoading] =
    React.useState(false);
  const [vehicleDropdownLoading, setVehicleDropdownLoading] =
    React.useState(false);
  const [editShopSupply, setEditShopSupply] = useState({
    edit: false,
    index: "",
  });
  const [editServiceData, setEditServiceData] = useState({
    edit: false,
    index: "",
  });

  const [IsSaveDraft, setIsSaveDraft] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    reset,
    setFocus,
  } = useForm();
  const handleOpenServiceModal = () => setOpenServiceModal(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);
  const [reqDate, setreqDate] = useState(new Date());
  const [customerData, setCustomerData] = React.useState("");
  const [data, setData] = React.useState("");

  const [customerDetails, setCustomerDetails] = React.useState([]);
  const [vehicleData, setVehicleData] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [estimateDate, setEstimateDate] = React.useState(null);
  const [estimateExpirationDate, setEstimateExpirationDate] =
    React.useState(null);
  const [addCustomerInfoButton, setAddCustomerInfoButton] =
    React.useState(false);
  const [estimateCompletionDate, setEstimateCompletionDate] =
    React.useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [estimateStatus, setEstimateStatus] = useState("");
  const [openCustomerInfo, setOpenCustomerInfo] = React.useState(false);
  const [customerCretedFrom, setCustomerCretedFrom] = React.useState("");
  const [addVehicleInfoButton, setAddVehicleInfoButton] = React.useState(false);
  const handleOpenCustomerInfo = () => setOpenCustomerInfo(true);
  const [messageType, setMessageType] = useState("");
  const [checkChangeLoader, setCheckChangeLoader] = useState(false);
  const [discountError, setDiscountError] = useState(false);

  const [servicePageData, setServicePageData] = useState({
    totalpages: "",
    currentPage: "",
  });
  let limits = 50;
  const handleCloseCustomerInfo = () => {
    setOpenCustomerInfo(false);
  };
  const [vehicleInfoOpen, setVehicleInfoOpen] = useState(false);
  const [vehicleSourceData, setVehicleSourceData] = React.useState("");
  const handleVehicleInfoOpen = () => {
    setVehicleInfoOpen(true);
    handleClose();
  };
  const handleVehicleInfoClose = () => {
    setVehicleInfoOpen(false);
    handleClose();
    if (vehicleSourceData) {
      setVehicleSourceData("");
      setVehicleData("");
    }
  };
  const [state, setState] = React.useState({
    shop_created: true,
    shop_imported: true,
    apex_auto_app: false,
  });

  const limit = 5000;
  const [totals, setTotals] = useState({
    subTotal: 0,
    discount: 0,
    taxPercentage: 13,
    tax: 0,
    estimatedTotal: 0,
  });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    reset({
      estimateCreatedBy: "",
      odo: "",
    });
    setEstimateDate(null);
    setEstimateCompletionDate(null);
    setEstimateExpirationDate(null);
    setTotals({
      subTotal: 0,
      discount: 0,
      taxPercentage: 13,
      tax: 0,
      estimatedTotal: 0,
    });
    setAllServices([]);
    setCustomerData(null);
    setVehicleData(null);
    setData([]);
    setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
    setState({
      shop_created: true,
      apex_auto_app: true,
      shop_imported: true,
    });
    setTermsData("");
    setDemoDescription("");
  }, [window.location.pathname]);

  // useEffect(() => {
  //   reset();
  //   if (editServiceData.edit == true) {
  //     editService(editServiceData.index);
  //   }
  // }, [editServiceData]);

  // const editService = (idx) => {
  //   let data = allServices[idx];
  //   setValue(
  //     "serviceCategory",
  //     data && data?.serviceCategory && data?.serviceCategory?._id
  //   );
  //   if (data && data?.serviceCategory && data?.serviceCategory?._id) {
  //     servicesName(1, 5000, data?.serviceCategory?._id);
  //     setServiceCategory(data.serviceCategory._id);
  //   } else {
  //     setServiceCategory(data?.category);
  //   }

  //   setServiceNameData(data?.service?._id);
  //   setValue("fixedPrice", FormatNumberForKm(data?.fixedPrice));
  //   setValue("service", JSON.stringify(data?.service));
  //   setValue("pricingType", data?.pricingType);
  //   setValue("laborRate", data?.laborRate);
  //   setValue("laborQuantity", data?.laborQuantity);

  //   setPricingType(data?.pricingType);
  //   if (data?.pricingType == "labor_&_parts") {
  //     setPartsData(data?.parts);
  //   }
  // };

  const onSubmitEstimate = async (values) => {
    if (discountError) {
      toast.error("Discount can not be more than subtotal amount.");
      return;
    }
    setMessageType("success");
    if (!customerData) {
      // alert("Customer is required");
      setMessageType("error");
      setOpenSuccessModal(true);
      setSuccessMessage("Customer is required.");
      return;
    }
    if (!vehicleData) {
      // alert("Customer is required");
      setMessageType("error");
      setOpenSuccessModal(true);
      setSuccessMessage("Vehicle is required.");
      return;
    }
    if (estimateDate == null) {
      // alert("Estimate Date is required");
      setMessageType("error");
      setOpenSuccessModal(true);
      setSuccessMessage("Estimate Date is required.");
      return;
    }

    let numericValue;
    if (values?.odo) {
      let formattedString = values?.odo.toLocaleString();
      numericValue = parseInt(formattedString.replace(/,/g, ""), 10);
      if (!/^[0-9,]+$/.test(values?.odo)) {
        setOdoValidation("Please enter proper number value");
        return;
      }
    }

    const estimateDateinputDate = new Date(estimateDate);
    const estimateDateadjustedDate = new Date(
      Date.UTC(
        estimateDateinputDate.getFullYear(),
        estimateDateinputDate.getMonth(),
        estimateDateinputDate.getDate(),
        0,
        0,
        0,
        0
      )
    );
    let estimateDateisoString = estimateDateadjustedDate.toISOString();

    const estimateCompletionInputDate = new Date(estimateCompletionDate);
    const estimateCompletionDateadjustedDate = new Date(
      Date.UTC(
        estimateCompletionInputDate.getFullYear(),
        estimateCompletionInputDate.getMonth(),
        estimateCompletionInputDate.getDate(),
        0,
        0,
        0,
        0
      )
    );
    let completionDateisoString =
      estimateCompletionDateadjustedDate.toISOString();

    const estimateExpiryInputDate = new Date(estimateExpirationDate);
    const estimateExpiryDateadjustedDate = new Date(
      Date.UTC(
        estimateExpiryInputDate.getFullYear(),
        estimateExpiryInputDate.getMonth(),
        estimateExpiryInputDate.getDate(),
        0,
        0,
        0,
        0
      )
    );
    let expiryDateisoString = estimateExpiryDateadjustedDate.toISOString();

    if (
      (estimateExpirationDate && estimateCompletionDate == null) ||
      (estimateExpirationDate == null && estimateCompletionDate)
    ) {
      if (estimateExpirationDate && estimateCompletionDate == null) {
        setCustomValidationErrForCompletion("Completion Date is Required");
        return;
      }
      if (estimateExpirationDate == null && estimateCompletionDate) {
        setCustomValidationErrForCompletion("Expiration Date is Required");
        return;
      }
    } else {
      if (
        estimateCompletionDate &&
        !dayjs(completionDateisoString).isAfter(estimateDateisoString) &&
        !dayjs(completionDateisoString).isSame(estimateDateisoString)
      ) {
        setCustomValidationErrForCompletion(
          "Estimate Completion date should be equal or greater than Estimate Date."
        );
        return;
      } else if (
        estimateExpirationDate &&
        !dayjs(expiryDateisoString).isAfter(estimateDateisoString) &&
        !dayjs(expiryDateisoString).isSame(estimateDateisoString)
      ) {
        setCustomValidationErrForExpiry(
          "Estimate Expiration date should be equal or greater than Estimate Date."
        );
        return;
      } else if (
        estimateCompletionDate &&
        estimateExpirationDate &&
        !dayjs(expiryDateisoString).isAfter(completionDateisoString) &&
        !dayjs(expiryDateisoString).isSame(completionDateisoString)
      ) {
        setCustomValidationErrForExpiry(
          "Estimate Expiration Date must be after Completion Date."
        );
        return;
      }
      if (
        customValidationErrForExpiry != null &&
        customValidationErrForCompletion != null
      ) {
        return;
      }
    }
    let payload = {
      estimateCreatedBy: values?.estimateCreatedBy,
      customerId: customerData?.refIdUsers,
      vehicleId: vehicleData,
      // odo: values?.odo,
      odo: numericValue,
      estimateDate: estimateDate ? estimateDateisoString : null,
      estimationExpiryDate: estimateExpirationDate ? expiryDateisoString : null,
      completionDate: estimateCompletionDate ? completionDateisoString : null,
      // ...termsData && termsData.title !== "" && {
      //   termsAndWarranty: {
      //     title: termsData.title,
      //     description: demoDescription,
      //   },
      // },
      services: [],
      discount: totals?.discount,
      tax: totals?.tax,
      subTotal: totals?.subTotal,
      Total: totals?.estimatedTotal,
    };
    if (vehicleData) {
      payload.vehicleId = vehicleData;
    }
    if (termsData && demoDescription) {
      payload.termsAndWarranty = {
        title: termsData.title,
        description: demoDescription,
      };
    }

    if (id) {
      payload.draftId = id;
    }

    let allServices = [];
    allService?.map((d) => {
      let data = {};
      data.serviceId = d?.service?._id;
      data.serviceName = d?.service?.name;
      if (d?.pricingType == "fixed") {
        data.pricingType = "fixed";
        data.fixedPrice = d?.fixedPrice;
      } else {
        data.pricingType = "labor_&_parts";
        data.labor = { qty: d?.laborQuantity, ratePerHour: d?.laborRate };
        data.parts = [];
        d?.parts?.map((part) => {
          data.parts.push({
            partName: part?.partName,
            partNumber: part?.partNumber,
            description: part?.description,
            qty: part?.qty,
            cost: part?.cost,
            totalAmount: part?.totalAmount,
            partsProvider: part?.partsProvider,
          });
        });
      }
      allServices.push(data);
    });
    payload.services.push(...allServices);

    setShowLoader(true);

    if (estimateRequestId) payload.requestId = estimateRequestId;

    if (IsSaveDraft) {
      let result = await dispatch(createEstimateDraftData(payload));
      if (result) {
        setOpenSuccessModal(true);
        setSuccessMessage("Changes saved");
        setTimeout(() => {
          navigate("/estimates/drafts");
        }, 1000);
      }
    } else {
      if (estimateRequestId) payload.requestId = estimateRequestId;

      let result = await dispatch(createEstimateData(payload));
      if (result) {
        setOpenSuccessModal(true);
        setSuccessMessage("Estimate sent successfully");
        setTimeout(() => {
          navigate("/estimates/sents");
        }, 1000);
      }
    }
    setShowLoader(false);
  };

  useEffect(() => {
    let subTotal = 0;
    console.log("allService", allService);

    allService?.map((service) => {
      if (service?.pricingType == "fixed") {
        if (service?.fixedPrice != null) {
          subTotal += +service?.fixedPrice;
        }
      } else {
        subTotal += +(service?.laborQuantity * service?.laborRate);
        service?.parts?.map((part) => {
          subTotal += +part?.totalAmount;
        });
      }
    });
    if (subTotal == 0) {
      totals.discount = 0;
    }
    setTotals({
      subTotal: subTotal,
      taxPercentage: +totals?.taxPercentage ? +totals?.taxPercentage : 13,
      discount: +totals?.discount,
      tax:
        +totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0,
      estimatedTotal:
        subTotal -
        +totals?.discount +
        (+totals?.taxPercentage > 0
          ? ((+subTotal - +totals?.discount) * +totals?.taxPercentage) / 100
          : 0),
    });
    if (subTotal > 0 && totals?.discount <= subTotal) {
      setDiscountError(false);
    } else if (subTotal == 0) {
      setDiscountError(false);
    } else {
      setDiscountError(true);
    }
  }, [allService]);

  const termsAndWarranty = async () => {
    const data = await dispatch(termsAndWarrantyData());
    const filteredData = data?.map(({ _id, ...rest }) => rest);
    setGetTermsData(filteredData !== undefined ? filteredData : "");
  };
  const removeServicesClicked = (idx) => {
    const newdata = [...allService];
    let newArray = [...newdata.slice(0, idx), ...newdata.slice(idx + 1)];
    setAllServices(newArray);
  };

  const EditServiceHandler = (idx) => {
    handleOpenServiceModal();
    setEditServiceData({ edit: true, index: idx });
  };

  const getDescriptionForTitle = (titleToFind, data) => {
    for (const item of data) {
      if (item?.title === titleToFind) {
        return item?.description;
      }
    }
    return "";
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmitEstimate)();
    }
  };

  const customerList = async (page, limits) => {
    if (customerId) {
      setShowLoader(true);
    }
    // setCustomerDetails([]);
    try {
      setCustomerListLoader(true);
      let data;
      data = await dispatch(
        CustomersListBasedOnType(customerSearch, customerTypeData, page, limits)
      );

      if (data.data) {
        // setCustomerDetails(data);
        setCustomerDetails([...customerDetails, ...data.data]);
        setServicePageData({
          totalpages: data?.totalPages,
          currentPage: data?.currentPage,
        });
        if (id) {
          setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (estimateRequestId) {
          setCustomerData(data.data.find((d) => d._id == customerData?._id));
        } else if (customerId) {
          setCustomerData(data.data.find((d) => d._id == customerId));
        } else {
          setCustomerData("");
        }
        setShowLoader(false);
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
    setCheckChangeLoader(false);
  };

  const fetchVehicles = async () => {
    // setShowLoader(true);
    setVehicleDropdownLoading(true);
    let data;
    if (id || estimateRequestId) {
      data = await dispatch(vehicleDetails(10, 1, customerData?._id));
    } else {
      data = await dispatch(vehicleDetails(10, 1, customerData?.refIdUsers));
    }

    if (data) {
      setData(data);
      // setVehicleDropdownLoading(false);
      if (data.length == 0) {
        handleVehicleInfoOpen();
      } else {
        handleCloseCustomerInfo();
      }
      // setShowLoader(false);
    }
    setVehicleDropdownLoading(false);
  };
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && newVehicleCreatedId) {
      setVehicleData(newVehicleCreatedId);
      setVehicleCreatedId("");
    }
  }, [data]);
  const customerDataDetail = async () => {
    setShowLoader(true);
    let resp;
    if (type != SHOP_IMPORTED) {
      resp = await dispatch(customerDetail(customerId));
    }
    if (type == SHOP_IMPORTED) {
      resp = await dispatch(importedCustomerDetail(customerId));
    }
    resp.fullName = resp?.firstName + " " + resp?.lastName;
    resp.refIdUsers = customerId;
    resp.refIdImport = customerId;

    setCustomerData(resp);
    setCustomerDetails([resp]);
    setShowLoader(false);
  };
  useEffect(() => {
    if (!id && !customerId && !estimateRequestId) {
      setCustomerTypeData(["shop_created", "shop_imported", "apex_auto_app"]);
    }
    if (id) {
      fetchEstimateDetails(id);
    }
    if (estimateRequestId) {
      fetchAcceptRequestEstimateDetails(estimateRequestId);
    }
    if (customerId) {
      setCustomerTypeData(["shop_created", "apex_auto_app", "shop_imported"]);
      setState({
        shop_created: true,
        apex_auto_app: true,
        shop_imported: true,
      });
      customerDataDetail();
    }
    termsAndWarranty();
  }, []);
  useEffect(() => {
    if (customerData && customerData?.createdFrom != "shop_imported") {
      fetchVehicles();
    }
    if (customerData?.createdFrom == "shop_imported") {
      setCustomerCretedFrom("shop_imported");
      setAddCustomerInfoButton(false);
      handleOpenCustomerInfo();
    }
  }, [customerData]);

  useEffect(() => {
    if (customerTypeData.length != 0) {
      if (!id && !customerId && !estimateRequestId && !type)
        customerList(page, limits);
    } else {
      setCheckChangeLoader(false);
    }
  }, [customerTypeData]);

  const fetchEstimateDetails = async (id) => {
    setShowLoader(true);
    if (id) {
      let resp = await dispatch(estimateData(id));

      if (Object.keys(resp).length > 0) {
        setValue("estimateCreatedBy", resp?.estimateCreatedBy);
        setValue("odo", FormatNumberForKm(resp?.odo));
        setCustomerTypeData(["shop_created", "apex_auto_app"]);

        if (
          resp?.customer?.createdFrom == "shop_created" ||
          resp?.customer?.createdFrom == "apex_auto_app"
        ) {
          setState({ shop_created: true, apex_auto_app: true });
        } else if (resp?.customer?.createdFrom == "shop_imported") {
          setState({ shop_imported: true });
        }

        setTermsData(resp?.termsAndWarranty);
        if (
          resp &&
          resp.termsAndWarranty &&
          resp.termsAndWarranty.description !== undefined
        ) {
          setDemoDescription(resp.termsAndWarranty.description);
        } else {
          console.log("description is not found");
        }

        const estimateDate = dayjs(resp?.estimateDate);
        setEstimateDate(dayjs(dayjs(estimateDate).format("YYYY-MM-DD")));

        if (resp?.completionDate) {
          const completionDate = dayjs(resp?.completionDate);
          setEstimateCompletionDate(
            dayjs(dayjs(completionDate).format("YYYY-MM-DD"))
          );
        }
        if (resp?.estimationExpiryDate) {
          const expiryDate = dayjs(resp?.estimationExpiryDate);
          setEstimateExpirationDate(
            dayjs(dayjs(expiryDate).format("YYYY-MM-DD"))
          );
        }

        setTotals({
          discount: +resp?.discount,
          tax: +resp?.tax ? +resp?.tax : 0,
          estimatedTotal: +resp?.Total,
          taxPercentage: +(
            +(+(resp?.tax == null ? 0 : resp?.tax) * 100) /
            +(
              +(resp?.subTotal == null ? 0 : resp?.subTotal) -
              +(resp?.discount == null ? 0 : resp?.discount)
            )
          ),
          subTotal: +resp?.subTotal,
        });
        let services = [];

        resp?.services?.map((data) => {
          let service = {
            ...data,
            laborQuantity: data?.labor?.qty,
            laborRate: data?.labor?.ratePerHour,
            service: { _id: data.serviceId?._id, name: data?.serviceId?.name },
            serviceCategory: {
              _id: data?.serviceId?.serviceCategory?._id,
              name: data?.serviceId?.serviceCategory?.name,
            },
          };

          data?.parts?.map((row, idx) => {
            // row["quantity"] = row?.qty;
            if (row?.partsProvider == "OEM") {
              row["partsProvider"] = "oem";
            }
            if (row?.partsProvider == "After Market") {
              row["partsProvider"] = "after_market";
            }
            if (row?.partsProvider == "Used") {
              row["partsProvider"] = "used";
            }
            row["cost"] = row?.cost?.toString()?.replace(/,/g, "");
            row["totalAmount"] = row?.totalAmount
              ?.toString()
              ?.replace(/,/g, "");

            // delete row?.qty;
          });
          delete data?.labor?.qty;
          delete data?.labor?.ratePerHour;

          delete service["serviceId"];
          delete service["serviceName"];
          services.push(service);
        });
        if (resp && resp?.customer && resp?.customer?._id) {
          resp.customer = {
            ...resp?.customer,
            refIdUsers: resp?.customer?._id,
          };
          setCustomerData(resp?.customer);
        }
        if (resp && resp?.vehicle && resp?.vehicle?._id) {
          setVehicleData(resp?.vehicle?._id);
        }
        console.log("services", services, resp?.services);
        setAllServices(services);
      }
    }
    setShowLoader(false);
  };

  const fetchAcceptRequestEstimateDetails = async (estimateRequestId) => {
    setShowLoader(true);
    if (estimateRequestId) {
      const resp = await dispatch(estimateRequestData(estimateRequestId));
      setEstimateStatus(resp?.status);
      if (Object.keys(resp).length > 0) {
        setValue("estimateCreatedBy", resp?.estimateCreatedBy);
        setValue("odo", FormatNumberForKm(resp?.odo));

        setCustomerTypeData(["shop_created", "apex_auto_app"]);

        if (
          resp?.customer?.createdFrom == "shop_created" ||
          resp?.customer?.createdFrom == "apex_auto_app"
        ) {
          setState({ shop_created: true, apex_auto_app: true });
        } else if (resp?.customer?.createdFrom == "shop_imported") {
          setState({ shop_imported: true });
        }
        setTermsData(resp?.termsAndWarranty);
        setDemoDescription(resp?.termsAndWarranty?.description);

        const estimateDate = dayjs(resp?.estimateDate);
        setEstimateDate(dayjs(dayjs(estimateDate).format("YYYY-MM-DD")));

        if (resp?.completionDate) {
          const completionDate = dayjs(resp?.completionDate);
          setEstimateCompletionDate(
            dayjs(dayjs(completionDate).format("YYYY-MM-DD"))
          );
        }
        if (resp?.estimationExpiryDate) {
          const expiryDate = dayjs(resp?.estimationExpiryDate);
          setEstimateExpirationDate(
            dayjs(dayjs(expiryDate).format("YYYY-MM-DD"))
          );
        }

        setTotals({
          discount: +resp?.discount ? +resp?.discount : 0,
          tax: resp?.tax ? +resp?.tax : 0,
          estimatedTotal: resp?.Total ? +resp?.Total : 0,
          taxPercentage: +(
            +(+(resp?.tax == null ? 0 : resp?.tax) * 100) /
            +(
              +(resp?.subTotal == null ? 0 : resp?.subTotal) -
              +(resp?.discount == null ? 0 : resp?.discount)
            )
          ),
          subTotal: resp?.subTotal ? +resp?.subTotal : 0,
        });
        let services = [];

        resp?.services?.map((data) => {
          let service = {
            pricingType:
              data?.pricingType == "range" ? "fixed" : data?.pricingType,
            fixedPrice: data?.fixedPrice,
            service: { _id: data._id, name: data?.name },
            serviceCategory: data.serviceCategory ? data.serviceCategory : {},
          };

          services.push(service);
        });
        if (resp && resp?.customer && resp?.customer?._id) {
          resp.customer = {
            ...resp?.customer,
            refIdUsers: resp?.customer?._id,
          };
          setCustomerData(resp?.customer);
        }
        if (resp && resp?.vehicle && resp?.vehicle?._id) {
          setVehicleData(resp?.vehicle?._id);
        }
        setAllServices(services);
      }
    }
    setShowLoader(false);
  };
  const [open3, setOpen3] = React.useState(false);

  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => {
    setOpen3(false);
    setEditShopSupply({ edit: false, index: "" });
  };

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    };
  };

  const cancelEstimate = () => {
    navigate(-1);
    if (id) {
      customerList();
      termsAndWarranty();
      fetchEstimateDetails(id);
    } else {
      setValue("estimateCreatedBy", "");
      setValue("odo", "");
      setTotals({
        subTotal: 0,
        discount: 0,
        taxPercentage: 0,
        tax: 0,
        estimatedTotal: 0,
      });
      setCustomerData("");
      setVehicleData("");
      setEstimateDate(null);
      setEstimateCompletionDate(null);
      setEstimateExpirationDate(null);
      setTermsData("");
      setAllServices([]);
    }
  };
  async function handleInputChange(newInputValue) {
    setCustomerSearch(newInputValue);

    setCustomerDetails([]);
    try {
      setCustomerListLoader(true);
      // let data = await dispatch(AllCustomersList(page, limit, newInputValue));
      let data;

      data = await dispatch(
        CustomersListBasedOnType(newInputValue, customerTypeData, 1, limits)
      );

      if (data) {
        setCustomerDetails(data.data);
        setCustomerListLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const handleInputDebounced = (event, newInputValue) => {
    debouncedHandleInputChange(newInputValue);
  };

  const handleCheckChange = (event) => {
    setCheckChangeLoader(true);

    setCustomerDetails([]);
    setCustomerSearch("");
    setPage(1);
    if (event.target.name && event.target.checked == true) {
      setCustomerTypeData((prevArray) => [...prevArray, event.target.name]);
    } else {
      const filterData = customerTypeData.filter(
        (value) => value !== event.target.name
      );
      setCustomerTypeData(filterData);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleBlur = () => {
    if (customerSearch != "") {
      setCustomerSearch("");
      handleInputChange(""); // Clear the search text when the Autocomplete loses focus
    }
  };

  const handleScroll = (e) => {
    const listboxNode = e.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
        listboxNode.scrollHeight &&
      servicePageData.totalpages > page
    ) {
      setPage(page + 1);
      customerList(page + 1, limits);
    }
  };
  const onInvalid = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          const offset = 140; // Adjust the offset value as needed
          const elementPosition = errorElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition - offset;
          window.scrollBy({ top: offsetPosition, behavior: "smooth" });
          setFocus(firstErrorField);
        }, 0);
      }
    }
  };

  return (
    <>
      <Box
        padding={"32px 24px"}
        sx={{
          // bgcolor: "background.main",
          width: "fit-content",
          minWidth: "100%",
        }}
      >
        {" "}
        <form onSubmit={handleSubmit(onSubmitEstimate, onInvalid)}>
          <Grid container sx={{ padding: "16px 0px" }} gap={"8px"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="/estimates/all"
                    style={{
                      color: "black",
                      opacity: "0.6",
                      textDecoration: "none",
                    }}
                  >
                    Estimates
                  </Link>
                  <Link
                    to="/estimates/new"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {id
                      ? "Drafts "
                      : estimateRequestId
                      ? "Request"
                      : "New Estimate "}
                  </Link>
                </Breadcrumbs>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" sx={{ fontWeight: "500" }}>
                {id || estimateRequestId ? "Edit " : "New "}
                Estimate
              </Typography>
            </Grid>
          </Grid>
          <Grid container padding={"24px"} margin={"32px 0"} boxShadow={2}>
            <Grid container item justifyItems={"space-between"}>
              <Grid
                container
                item
                justifyItems={"space-between"}
                rowGap={3}
                padding={"10px 0px"}
              >
                <Grid item container columns={16}>
                  <Grid item md={5.2} sm={8} xs={16}>
                    <Stack spacing={2}>
                      <Typography variant="body1" className="label-500">
                        Estimate Prepared By{" "}
                        {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                      </Typography>
                      <Controller
                        name="estimateCreatedBy"
                        control={control}
                        rules={{
                          required: "Estimate Prepared By is required",
                        }}
                        render={({ field }) => (
                          <>
                            <TextField
                              fullWidth
                              variant="outlined"
                              error={!!errors.estimateCreatedBy}
                              helperText={
                                errors.estimateCreatedBy
                                  ? errors.estimateCreatedBy.message
                                  : ""
                              }
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={8} xs={12}>
                    <Stack spacing={0}>
                      <Typography variant="body1" className="label-500">
                        Customer <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerDetails ? customerDetails : []}
                        getOptionLabel={(option) =>
                          // option.firstName + " " + option.lastName
                          option.fullName
                        }
                        value={customerData ? customerData : null}
                        onChange={(event, newValue) => {
                          // Handle the change here if needed
                          setVehicleData("");
                          setCustomerData(newValue);
                          setCustomerCretedFrom(newValue?.createdFrom);
                          if (customerData != null) {
                            setData("");
                          }
                        }}
                        loading={customerListLoader}
                        disabled={
                          id ||
                          customerId ||
                          estimateRequestId ||
                          customerTypeData.length == 0
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            onChange={(e) => {
                              setVehicleData("");
                              handleInputDebounced(e, e.target.value);
                            }}
                            onBlur={handleBlur}
                          />
                        )}
                        loadingText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress />
                          </div>
                        }
                        ListboxProps={{
                          style: { maxHeight: "300px" },
                          onScroll: (event) => {
                            handleScroll(event);
                          },
                        }}
                        renderOption={(props, option) => (
                          <>
                            <Box component="li" {...props}>
                              <li
                                {...props}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => {
                                  if (option.createdFrom == "shop_imported") {
                                    handleOpenCustomerInfo();
                                  }
                                }}
                              >
                                <div>
                                  {/* {option.firstName + " " + option.lastName} */}
                                  {option.fullName}
                                </div>
                                <div>
                                  <Chip
                                    style={{
                                      ...styleChip,
                                      display: "flex",
                                      background:
                                        option.createdFrom == "shop_created"
                                          ? "#75DBFF80"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#E1D0FD"
                                          : "#EFEFEF",
                                      color:
                                        option.createdFrom == "shop_created"
                                          ? "#000000"
                                          : option.createdFrom ==
                                            "shop_imported"
                                          ? "#3E1C76"
                                          : "#002394",
                                    }}
                                    className="chip-text-style"
                                    label={
                                      option.createdFrom == "shop_created"
                                        ? "Shop Created"
                                        : option.createdFrom == "shop_imported"
                                        ? "Shop Imported"
                                        : "Apex Auto"
                                    }
                                  />
                                </div>
                              </li>
                            </Box>
                            <Box component="li" {...props}>
                              {props["data-option-index"] + 1 ==
                                customerDetails?.length &&
                                customerListLoader && (
                                  <li
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",

                                      display: "flex",
                                    }}
                                  >
                                    <CircularProgress />
                                  </li>
                                )}
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  </Grid>
                  {!addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleOpenCustomerInfo();
                          }}
                        >
                          {" "}
                          + Add Customer Info
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Grid item container columnGap={2} rowGap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_created}
                        onChange={handleCheckChange}
                        name="shop_created"
                        disabled={id || customerId || estimateRequestId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Created"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.shop_imported}
                        onChange={handleCheckChange}
                        name="shop_imported"
                        disabled={id || customerId || estimateRequestId}
                      />
                    }
                    labelPlacement="end"
                    label="Shop Imported"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.apex_auto_app}
                        onChange={handleCheckChange}
                        name="apex_auto_app"
                        disabled={id || customerId || estimateRequestId}
                      />
                    }
                    labelPlacement="end"
                    label="Apex Auto"
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={8} xs={12}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "18px",
                        }}
                        className="edit-invoice-form-title"
                      >
                        Vehicle
                      </Typography>
                      <Select
                        className="customer-dropdown"
                        // value={Array.isArray(data) ? vehicleData : ""}
                        value={
                          Array.isArray(data) && customerData !== null
                            ? vehicleData
                            : ""
                        }
                        // required
                        displayEmpty
                        onChange={(e) => {
                          setVehicleData(e.target.value);
                        }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                      >
                        {(data ? data : [])?.map((data, idx) => {
                          if (data)
                            return (
                              <MenuItem
                                value={data._id}
                                onClick={() => {
                                  if (
                                    data.source &&
                                    data.source == "shop_imported"
                                  ) {
                                    setVehicleSourceData(data);
                                    handleVehicleInfoOpen();
                                  }
                                }}
                              >
                                {data?.vehicleYear ? data?.vehicleYear : " "}
                                {data?.year && data?.year}{" "}
                                {data?.vehicleMake?.name &&
                                  data?.vehicleMake?.name}{" "}
                                {data?.vehicleModel?.name &&
                                  data?.vehicleModel?.name}{" "}
                                {data?.vehicleModelTrim?.name &&
                                  data?.vehicleModelTrim?.name}
                              </MenuItem>
                            );
                        })}
                        {vehicleDropdownLoading && <DropdownLoader />}
                      </Select>
                    </Stack>
                  </Grid>
                  {/* {addCustomerInfoButton &&
                    customerCretedFrom == "shop_imported" && ( */}
                  {customerData &&
                    customerData?.createdFrom != "shop_imported" && (
                      <Grid
                        item
                        xl={2.7}
                        lg={2.5}
                        md={2.7}
                        sm={2.7}
                        xs={2.7}
                        paddingTop={"0px"}
                        marginTop={"0px"}
                        className="width-100-per-drop-off"
                      >
                        <Button
                          style={{ marginTop: "35px" }}
                          onClick={() => {
                            handleVehicleInfoOpen();
                            setVehicleSourceData("");
                          }}
                        >
                          {" "}
                          + Add New Vehicle
                        </Button>
                      </Grid>
                    )}
                  {/* // )} */}
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                >
                  <Grid item xl={5.9} lg={5.9} md={5.9} sm={8} xs={12}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "22px",
                        }}
                      >
                        Odometer
                      </Typography>

                      <Controller
                        name="odo"
                        control={control}
                        rules={{
                          required: "Please enter ODO.",
                        }}
                        render={({ field }) => (
                          <>
                            <TextField
                              {...field}
                              fullWidth
                              // required
                              // type="number"
                              id="outlined-end-adornment"
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    km
                                  </InputAdornment>
                                ),
                              }}
                              error={!!errors.odo}
                              helperText={errors.odo ? errors.odo.message : ""}
                              // value={odoFormate}
                              onChange={(event) => {
                                setValue(
                                  "odo",
                                  FormatNumberForKm(event.target.value)
                                );
                              }}
                              onKeyDown={(e) => {
                                preventPhoneInputKeys(e);
                              }}
                            />
                          </>
                        )}
                      />
                      {/* <p>{odoValidation}</p> */}
                      <span style={{ color: REQUIRED_RED }}>
                        {odoValidation}
                      </span>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent={"start"}
                  columnGap={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                  rowGap={3}
                >
                  <Grid item xl={3.9} lg={3.9} md={3.9} sm={8} xs={12}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Estimate Date{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="DatePiFull"
                        value={estimateDate || null}
                        onChange={(date) => {
                          setCustomValidationErrForCompletion(null);
                          setCustomValidationErrForExpiry(null);
                          setEstimateDate(
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          );
                        }}
                        style={{ width: "100%" }}
                        components={{
                          OpenPickerIcon: KeyboardArrowDown,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.estimateDate}
                            helperText={
                              errors.estimateDate
                                ? errors.estimateDate.message
                                : ""
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xl={3.9} lg={3.9} md={3.9} sm={8} xs={12}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Estimate Completion Date
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="DatePiFull"
                        value={estimateCompletionDate || null}
                        onChange={(date) => {
                          setCustomValidationErrForCompletion(null);
                          setEstimateCompletionDate(
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          );
                        }}
                        style={{ width: "100%" }}
                        components={{
                          OpenPickerIcon: KeyboardArrowDown,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.completionDate}
                            helperText={
                              errors.completionDate
                                ? errors.completionDate.message
                                : ""
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xl={3.9} lg={3.7} md={3.8} sm={8} xs={12}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Estimate Expiration Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="DatePiFull"
                        value={estimateExpirationDate || null}
                        onChange={(date) => {
                          setCustomValidationErrForExpiry(null);
                          setEstimateExpirationDate(
                            dayjs(dayjs(date).format("YYYY-MM-DD"))
                          );
                        }}
                        style={{ width: "100%" }}
                        components={{
                          OpenPickerIcon: KeyboardArrowDown,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.estimationExpiryDate}
                            helperText={
                              errors.estimationExpiryDate
                                ? errors.estimationExpiryDate.message
                                : ""
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography className="err-validation">
                  {customValidationErrForCompletion}
                </Typography>
                <Typography className="err-validation">
                  {customValidationErrForExpiry}
                </Typography>
                <Divider
                  style={{
                    width: "100%",
                    borderBottomStyle: "dotted",
                    borderBottomWidth: "4px",
                  }}
                />
              </Grid>
              <Grid item container justifyContent={"end"} padding={"12px 0px"}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpenServiceModal}
                    className="btn-text-size-manage"
                  >
                    ADD SERVICE
                  </Button>
                </Grid>
              </Grid>

              {allService?.map((data, index) => {
                return (
                  <>
                    {data?.pricingType == "fixed" ? (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Service Name </TableCell>
                                  <TableCell width={"9.6%"}>Quantity</TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell component="th" scope="row">
                                    1
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.service?.name
                                      ? data?.service?.name
                                      : ""}
                                  </TableCell>

                                  <TableCell align="left">
                                    {data?.qty ? (
                                      <FormatNumber inputValue={data?.qty} />
                                    ) : (
                                      1
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>

                                  <TableCell align="left">
                                    {data?.fixedPrice ? (
                                      <FormatNumber
                                        inputValue={data?.fixedPrice}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          container
                          item
                          style={{
                            backgroundColor: "#002394",
                            color: "white",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              fontWeight={"500"}
                              padding={"16px"}
                            >
                              {data?.service?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid
                            item
                            xl={12}
                            md={12}
                            sm={12}
                            xs={12}
                            boxShadow={0}
                          >
                            <Table
                              aria-label="caption table"
                              padding="16px"
                              style={{ boxShadow: 0 }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#f4f6f8",
                                  fontWeight: 500,
                                }}
                              >
                                <TableRow>
                                  <TableCell width={"9.6%"}>
                                    <Typography
                                      variant="table/header"
                                      fontWeight={500}
                                    >
                                      {" "}
                                      No.
                                    </Typography>
                                  </TableCell>
                                  <TableCell> Description</TableCell>
                                  <TableCell width={"9.6%"}>
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell width={"9.6%"}> Cost</TableCell>
                                  <TableCell width={"9.6%"}> Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={0}>
                                  <TableCell component="th" scope="row">
                                    {1}
                                  </TableCell>
                                  <TableCell align="left">Labor</TableCell>

                                  <TableCell align="left">
                                    {data?.laborQuantity
                                      ? data?.laborQuantity
                                      : "1"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={data?.laborRate}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data?.laborQuantity * data?.laborRate ? (
                                      <FormatNumber
                                        inputValue={
                                          data?.laborQuantity * data?.laborRate
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                </TableRow>
                                {data?.parts?.map((row, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                      {idx + 2}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography
                                        variant="body2"
                                        fontWeight={400}
                                      >
                                        {row?.partName}
                                      </Typography>

                                      <Typography
                                        fontWeight={400}
                                        fontSize={12}
                                      >
                                        Part Provider:{" "}
                                        {console.log(
                                          "row.partsProvider",
                                          row.partsProvider
                                        )}
                                        {row?.partsProvider == "oem" && "OEM"}{" "}
                                        {row?.partsProvider == "after_market" &&
                                          "After Market"}{" "}
                                        {row?.partsProvider == "used" && "Used"}
                                      </Typography>
                                      {row?.partNumber && (
                                        <Typography
                                          variant="body2"
                                          fontWeight={400}
                                          fontSize={12}
                                        >
                                          Part Number: {row?.partNumber}
                                        </Typography>
                                      )}
                                      {row?.description && (
                                        <Typography
                                          variant="body2"
                                          fontSize={12}
                                          // color={"#848484"}
                                        >
                                          Description: {row?.description}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row?.qty}
                                    </TableCell>
                                    <TableCell align="left">
                                      {" "}
                                      <FormatNumber inputValue={row?.cost} />
                                    </TableCell>
                                    <TableCell align="left">
                                      <FormatNumber
                                        inputValue={row?.totalAmount}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      container
                      item
                      justifyContent={"end"}
                      padding={"24px"}
                    >
                      <Grid item>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => {
                              // console.log("index", index);
                              EditServiceHandler(index);
                            }}
                            className="btn-text-size-manage"
                          >
                            EDIT SERVICE
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<Delete />}
                            className="btn-text-size-manage"
                            onClick={() => {
                              removeServicesClicked(index);
                            }}
                          >
                            REMOVE SERVICE
                          </Button>
                        </Stack>
                      </Grid>{" "}
                    </Grid>
                  </>
                );
              })}
              <Divider
                style={{
                  width: "100%",
                  borderBottomStyle: "dotted",
                  borderBottomWidth: "4px",
                }}
              />
              <Grid container item justifyContent={"end"} padding={"24px"}>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Stack direction="column">
                      <TextField
                        variant="outlined"
                        size="medium"
                        label="Discount($)"
                        value={totals?.discount ? totals?.discount : 0}
                        color={discountError ? "error" : "primary"}
                        disabled={totals?.subTotal == 0 ? true : false}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setTotals({
                              discount: +e.target.value,
                              tax:
                                totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - +e.target.value) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0,

                              estimatedTotal:
                                totals?.subTotal -
                                e.target.value +
                                (totals?.taxPercentage > 0
                                  ? ((totals?.subTotal - +e.target.value) *
                                      totals?.taxPercentage) /
                                    100
                                  : 0),
                              taxPercentage:
                                totals?.taxPercentage > 0
                                  ? totals?.taxPercentage
                                  : 0,
                              subTotal: totals?.subTotal,
                            });
                            if (e.target.value > totals?.subTotal) {
                              setDiscountError(true);
                            } else {
                              setDiscountError(false);
                            }
                          } else {
                            toast.error("Please Enter Number value");
                          }
                        }}
                      />
                      {discountError && (
                        <p
                          style={{
                            color: "#d32f2f",
                            fontSize: "0.75rem",
                            letterSpacing: "0.03333em",
                            lineHeight: "1.66",
                            marginTop: "3px",
                          }}
                        >
                          Discount can not be more than subtotal amount
                        </p>
                      )}
                    </Stack>
                    <TextField
                      variant="outlined"
                      size="medium"
                      label="Taxes (%)"
                      value={
                        +totals.taxPercentage.toFixed(2)
                          ? +totals.taxPercentage.toFixed(2)
                          : +13
                      }
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setTotals({
                            taxPercentage: +e.target.value
                              ? +e.target.value
                              : 13,
                            tax:
                              ((totals?.subTotal - totals?.discount) *
                                e.target.value) /
                              100,
                            estimatedTotal:
                              totals?.subTotal -
                              totals?.discount +
                              ((totals?.subTotal - totals?.discount) *
                                +e.target.value) /
                                100,
                            discount: totals?.discount,
                            subTotal: totals?.subTotal ? totals?.subTotal : "0",
                          });
                        } else {
                          toast.error("Please Enter Number value");
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                container
                item
                justifyContent={"end"}
                padding={"0px 23px 24px 24px"}
              >
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>Sub Total</Typography>

                      <Typography mb={1}>Discount</Typography>

                      <Typography mb={1}>Tax</Typography>
                      <Typography> Estimate Total</Typography>
                    </Box>
                    <Box textAlign={"end"}>
                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.subTotal} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.discount} />
                      </Typography>

                      <Typography mb={1}>
                        <FormatTotalNumbers inputValue={totals?.tax} />
                      </Typography>
                      <Typography>
                        <FormatTotalNumbers
                          inputValue={totals?.estimatedTotal}
                        />
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent={"start"}
                marginTop={"32px"}
                columnGap={4}
                rowGap={2}
              >
                <Grid item lg={2.7} xl={2.7} md={2.7} sm={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={getTermsData ? getTermsData : []}
                    onChange={(e, value) => {
                      setTermsData(value || null); // Set null if value is undefined
                      setDemoDescription(
                        getDescriptionForTitle(value?.title, getTermsData)
                      );
                    }}
                    value={termsData === "" ? null : termsData}
                    getOptionLabel={(option) => option?.title}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.title ? option?.title : ""}
                      </Box>
                    )}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Terms and Warranty" />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  lg={8.9}
                  xl={8.9}
                  md={8.7}
                  sm={8.8}
                  xs={12}
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    width: "72%",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      maxWidth={"100%"}
                      style={{ color: "#848484" }}
                    >
                      {demoDescription}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"end"}>
            <Grid item>
              <Stack spacing={2} direction={"row"}>
                <Button
                  variant="contained"
                  className="btn-text-size-manage"
                  color="error"
                  onClick={() => {
                    // navigate("/estimates/drafts");
                    cancelEstimate();
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="btn-text-size-manage"
                  onClick={() => setIsSaveDraft(true)}
                >
                  SAVE AS DRAFT
                </Button>
                <Button
                  variant="contained"
                  className="btn-text-size-manage"
                  type="submit"
                  onClick={() => setIsSaveDraft(false)}
                >
                  SAVE AND SEND
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <ConfirmCustomersInfoModel
        open={openCustomerInfo}
        handleClose={handleCloseCustomerInfo}
        customerData={customerData}
        id={customerData?.refIdImport}
        vehicalDetailFunction={fetchVehicles}
        setAddCustomerInfoButton={setAddCustomerInfoButton}
        setCustomerData={setCustomerData}
        setCustomerTypeData={setCustomerTypeData}
        state={state}
        setState={setState}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        madeFromCustomerProfile={false}
      />
      <MissingVehicleInfoModal
        open={vehicleInfoOpen}
        handleClose={handleVehicleInfoClose}
        id={customerData?.refIdUsers}
        vehicalDetailFunction={fetchVehicles}
        setAddVehicleInfoButton={setAddVehicleInfoButton}
        setVehicleCreatedId={setVehicleCreatedId}
        vehicleSource={vehicleSourceData}
      />
      <ServiceModal
        open={openServiceModal}
        setOpenServiceModal={setOpenServiceModal}
        setAllServices={setAllServices}
        allServices={allService}
        editServiceData={editServiceData}
        setEditServiceData={setEditServiceData}
      />
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        alertType={messageType}
      />
      <Dialog fullScreen={fullScreen} open={checkChangeLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewEstimates;
