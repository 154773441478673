import { toast } from "sonner";

export const displayError = (message) => {
  let errMessage = message || "An error occurred. Please try again later.";

  return toast.error(errMessage);
};
const vinRegex = new RegExp("^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$");

export const checkValidVIN = (data) => {
  return vinRegex.test(data);
};
