import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
// import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { REQUIRED_RED } from "../../App";
import {
  decodeVIN,
  vehicleMakeMaster,
  vehicleModelMaster,
  vehicleTrimMaster,
  vehicleYearMaster,
} from "../../redux/Customer/action";
import { checkValidVIN } from "../../utility/utilityFunctions";

const select_category = [
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 3,
};

const VehicleModel = ({
  open,
  handleClose,
  vehicles,
  setVehicles,
  editVehicle,
  setEditVehicle,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
    clearErrors,
    setError,
    setFocus,
  } = useForm();
  const dispatch = useDispatch();
  const [vinError, setVINError] = useState("");

  const [vinLoading, setVINLoading] = useState(false);
  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");
  const [trimValue, setTrimValue] = useState("");
  const [yearPage, setYearPage] = useState(1);

  const [makeValuePage, setMakeValuePage] = useState(1);
  const [modelValuePage, setModelValuePage] = useState(1);
  const [trimValuePage, setTrimValuePage] = useState(1);
  const [makeLoader, setMakeLoader] = useState(false);
  const [modelLoader, setModelLoader] = useState(false);
  const [trimLoader, setTrimLoader] = useState(false);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [trim, setTrim] = useState([]);

  let vehicleMakeParams = {
    page: makeValuePage,
    limit: 500,
    year: yearValue,
  };
  // console.log("editVehicle", editVehicle);
  useEffect(() => {
    if (editVehicle?.edit) {
      // console.log("inside", vehicles);
      const data = vehicles[editVehicle?.editIndex];
      fetchVehicleMake({
        page: makeValuePage,
        limit: 500,
        year: data?.year,
      });
      fetchVehicleModel({
        page: modelValuePage,
        limit: 1000,
        vehicleMakeId: data?.vehicleMakeId.id,
        year: data?.year,
      });
      fetchVehicleTrim({
        page: trimValuePage,
        limit: 1000,
        vehicleMakeId: data?.vehicleMakeId.id,
        vehicleModelId: data?.vehicleModelId.id,
        year: data?.year,
      });
      setValue("vin", data?.vin);
      setValue("year", data?.year);
      setValue("vehicleMakeId", JSON.stringify(data?.vehicleMakeId));
      setValue("vehicleModelId", JSON.stringify(data?.vehicleModelId));
      setValue("vehicleModelTrimId", JSON.stringify(data?.vehicleModelTrimId));
      setValue("licenseNumber", data?.licenseNumber);
      setYearValue(data?.year);
      setMakeValue(JSON.stringify(data?.vehicleMakeId));
      setModelValue(JSON.stringify(data?.vehicleModelId));
      setTrimValue(JSON.stringify(data?.vehicleModelTrimId));
    }
  }, [editVehicle]);

  useEffect(() => {
    fetchVehicleYear();
    // fetchVehicleMake(vehicleMakeParams);
  }, []);
  const fetchVehicleYear = async () => {
    await dispatch(vehicleYearMaster({ page: yearPage, limit: 500 }));
  };
  const year = useSelector((state) => state.customer?.vehicleYear);

  const fetchVehicleMake = async (vehicleMakeParams) => {
    setMakeLoader(true);
    await dispatch(vehicleMakeMaster(vehicleMakeParams));
    setMakeLoader(false);
  };
  const makes = useSelector((state) => state.customer?.vehicleMake);

  useEffect(() => {
    setMake(makes);
  }, [makes]);
  const fetchVehicleModel = async (vehicleModelParams) => {
    setModelLoader(true);
    await dispatch(vehicleModelMaster(vehicleModelParams));
    setModelLoader(false);
  };

  const models = useSelector((state) => state.customer?.vehicleModel);
  useEffect(() => {
    setModel(models);
  }, [models]);
  const fetchVehicleTrim = async (vehicleTrimParams) => {
    setTrimLoader(true);

    await dispatch(vehicleTrimMaster(vehicleTrimParams));
    setTrimLoader(false);
  };
  const trims = useSelector((state) => state.customer?.vehicleTrim);
  useEffect(() => {
    setTrim(trims);
  }, [trims]);
  const handleDecodeVIN = async () => {
    if (getValues()?.vin && getValues()?.vin.length == 17) {
      // setVINError("");
      clearErrors("vin");

      setVINLoading(true);
      const resp = await dispatch(decodeVIN(getValues()?.vin));
      // setModelSelectLoader(true);
      setMakeValue(resp?.data?.make?._id);
      setModelValue("");
      setTrimValue("");
      await dispatch(
        vehicleMakeMaster({
          page: 1,
          limit: 1000,
          year: resp?.data?.year,
        })
      );
      let para = {
        page: 1,
        limit: 1000,
        vehicleMakeId: resp?.data?.make?._id,
        year: resp?.data?.year,
      };
      let maekResult = await dispatch(vehicleModelMaster(para));
      if (maekResult) {
        let para = {
          page: 1,
          limit: 1000,
          vehicleMakeId: resp?.data?.make?._id,
          vehicleModelId: resp?.data?.model?._id,
          year: resp?.data?.year,
        };
        await dispatch(vehicleTrimMaster(para));
      }

      if (resp) {
        setValue("year", resp?.data?.year);
        setValue(
          "vehicleMakeId",
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setValue(
          "vehicleModelId",
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );

        setYearValue(resp?.data?.year);
        setMakeValue(
          JSON.stringify({
            id: resp?.data?.make?._id,
            name: resp?.data?.make?.name,
          })
        );
        setModelValue(
          JSON.stringify({
            id: resp?.data?.model?._id,
            name: resp?.data?.model?.name,
          })
        );
        setValue(
          "vehicleModelTrimId",
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );
        setTrimValue(
          JSON.stringify({
            id: resp?.data?.trim?._id,
            name: resp?.data?.trim?.name,
            description: resp?.data?.trim?.description,
          })
        );

        setVINLoading(false);
      }
    } else if (getValues()?.vin && getValues()?.vin.length != 17) {
      setVINLoading(false);
      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");
    } else {
      setVINLoading(false);
      setError("vin", {
        message: "Please enter valid VIN",
      });
      // setVINError("Please Enter Valid VIN");
    }
  };

  const onSubmitVehicle = async (values) => {
    if (values?.vin && values?.vin !== "") {
      if (checkValidVIN(values?.vin)) {
        clearErrors("vin");
      } else {
        return setError("vin", { message: "Please enter valid VIN" });
      }
    }
    console.log("values", values);
    if (values?.licenseNumber.trim() == "") {
      setError("licenseNumber", {
        message: "Licence Plate Number is required",
      });
      setValue("licenseNumber", "");
      return;
    }
    values.vehicleMakeId = JSON.parse(values.vehicleMakeId);
    values.vehicleModelId = JSON.parse(values.vehicleModelId);
    if (values.vehicleModelTrimId)
      values.vehicleModelTrimId = JSON.parse(values.vehicleModelTrimId);

    if (!values.vehicleModelTrimId) {
      delete values["vehicleModelTrimId"];
    }

    if (editVehicle?.edit) {
      let allVehicles = [...vehicles];
      if (allVehicles[editVehicle?.editIndex]?.vehcileId) {
        values.vehcileId = allVehicles[editVehicle?.editIndex]?.vehcileId;
      }
      allVehicles[editVehicle?.editIndex] = values;
      setVehicles(allVehicles);
      setEditVehicle({ edit: false, editIndex: "" });
    } else {
      setVehicles([...vehicles, values]);
    }
    handleClose();
    reset();
    setMakeValue("");
    setYearValue("");
    setModelValue("");
    setTrimValue("");
  };
  const resetValues = () => {
    reset();
    setMakeValue("");
    setYearValue("");
    setModelValue("");
    setTrimValue("");
  };
  const handleDeleteVehicle = () => {
    const data = vehicles[editVehicle?.editIndex];
    const filteredData = vehicles?.filter((d) => d != data);
    // console.log("filteredData", filteredData);
    setVehicles(filteredData);
    handleClose();
  };
  const onInvalid = (errors) => {
    console.log("🚀 ~ onInvalid ~ errors:", errors);
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = document.querySelector(`[name=${firstErrorField}]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
        setFocus(firstErrorField);
      }
    }
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          resetValues();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, border: "none", borderRadius: "8px 8px 0px 0px" }}
          className="modalBodyBoxContainer"
        >
          <AppBar
            color="secondary"
            variant="elevation"
            sx={{
              color: "white",
              height: "64px",
              borderRadius: "8px 8px 0px 0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                {editVehicle?.edit ? "Edit" : "Add"} Vehicle
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleClose();
                    resetValues();
                  }}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon style={{ color: "#0000008F", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <Container
            disableGutters
            style={{
              scrollbarColor: "blue",
              scrollbarWidth: "thin",
              scrollbarGutter: "10px",
              // overflowY: "auto",
              marginTop: "64px",
              // maxHeight: "80vh",
            }}
          >
            <form onSubmit={handleSubmit(onSubmitVehicle, onInvalid)}>
              <Grid
                container
                spacing={3}
                style={{
                  maxHeight: "82vh",
                  overflowY: "auto",
                  marginBottom: "24px",
                  scrollbarColor: "blue",
                  scrollbarWidth: "thin",
                }}
                p={3}
                pb={0}
              >
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      VIN
                      {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                    </Typography>
                    <Controller
                      name="vin"
                      control={control}
                      // rules={{
                      //   required: "VIN is required",
                      // }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          // required
                          id="outlined-basic"
                          variant="outlined"
                          error={!!errors.vin}
                          helperText={errors.vin ? errors.vin.message : ""}
                          {...field}
                          onChange={(e) => {
                            setValue("vin", e.target.value);
                            if (e.target.value !== "") {
                              const regex = checkValidVIN(e.target.value);
                              if (regex) {
                                clearErrors("vin");
                              } else {
                                setError("vin", {
                                  message: "Please enter valid VIN",
                                });
                              }
                            } else {
                              clearErrors("vin");
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                {vinLoading ? (
                                  <CircularProgress />
                                ) : (
                                  <Button onClick={handleDecodeVIN}>
                                    Decode
                                  </Button>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            style: { textTransform: "uppercase" },
                            maxLength: 17,
                          }}
                        />
                      )}
                    />
                    {/* <Typography style={{ color: "red" }}>{vinError}</Typography> */}
                    {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                  </Stack>
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    padding: "0 10px",
                    marginTop: "24px",
                  }}
                >
                  Or
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth error={!!errors.year}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Year <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      <Controller
                        defaultValue=""
                        name="year"
                        control={control}
                        rules={{
                          required: "Year is required",
                        }}
                        render={({ field }) => (
                          <Select
                            value={yearValue}
                            onChange={(event) => {
                              field.onChange(event);
                              setYearValue(event.target.value);
                              setMakeValue("");
                              setModelValue("");
                              setTrimValue("");
                              setMake([]);
                              setModel([]);
                              setTrim([]);
                              fetchVehicleMake({
                                page: makeValuePage,
                                limit: 500,
                                year: event.target.value,
                              });
                            }}
                            error={!!errors.year}
                            helperText={errors.year ? errors.year.message : ""}
                            // {...field}
                            MenuProps={{
                              style: { maxHeight: "400px" },
                            }}
                          >
                            {Array.isArray(year) &&
                              year?.map((y) => {
                                return (
                                  <MenuItem value={y} key={y}>
                                    {y}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                      {errors.year && (
                        <FormHelperText>{errors.year.message}</FormHelperText>
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth error={!!errors.vehicleMakeId}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Make <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      {editVehicle?.edit && makeLoader && makeValue != null ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          className="skelton"
                          sx={{ fontSize: "3.4rem" }}
                        ></Skeleton>
                      ) : (
                        <Controller
                          name="vehicleMakeId"
                          defaultValue={""}
                          control={control}
                          rules={{
                            required: "Make is required",
                          }}
                          render={({ field }) => (
                            <Select
                              fullWidth
                              disabled={yearValue !== "" ? false : true}
                              value={makeValue ? makeValue : ""}
                              onChange={(event) => {
                                field.onChange(event);

                                setMakeValue(event.target.value);
                                setModelValue(null);
                                setTrimValue(null);
                                setModel([]);
                                setTrim([]);
                                fetchVehicleModel({
                                  page: modelValuePage,
                                  limit: 1000,
                                  vehicleMakeId: JSON.parse(event.target.value)
                                    .id,
                                  year: yearValue,
                                });
                              }}
                              error={!!errors.vehicleMakeId}
                              helperText={
                                errors.vehicleMakeId
                                  ? errors.vehicleMakeId.message
                                  : ""
                              }
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                            >
                              {!makeLoader &&
                                Array.isArray(make) &&
                                make?.map((m) => {
                                  return (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: m?._id,
                                        name: m?.name,
                                      })}
                                      key={m?._id}
                                    >
                                      {m?.name}
                                    </MenuItem>
                                  );
                                })}
                              {makeLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </Select>
                          )}
                        />
                      )}
                      {errors.vehicleMakeId && (
                        <FormHelperText>
                          {errors.vehicleMakeId.message}
                        </FormHelperText>
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth error={!!errors.vehicleModelId}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Model <span style={{ color: REQUIRED_RED }}>*</span>
                      </Typography>
                      {editVehicle?.edit &&
                      modelLoader &&
                      modelValue != null ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          className="skelton"
                          sx={{ fontSize: "3.4rem", m: 0 }}
                        ></Skeleton>
                      ) : (
                        <Controller
                          name="vehicleModelId"
                          control={control}
                          rules={{
                            required: "Model is required",
                          }}
                          render={({ field, fieldState }) => (
                            <Select
                              fullWidth
                              value={modelValue}
                              disabled={
                                yearValue !== "" && makeValue !== ""
                                  ? false
                                  : true
                              }
                              onChange={(event) => {
                                field.onChange(event);

                                setModelValue(event.target.value);

                                setTrimValue(null);
                                setValue("vehicleModelTrimId", null);

                                setTrim([]);
                                fetchVehicleTrim({
                                  page: trimValuePage,
                                  limit: 1000,
                                  vehicleMakeId: JSON.parse(makeValue)?.id,
                                  vehicleModelId: JSON.parse(event.target.value)
                                    .id,
                                  year: yearValue,
                                });
                              }}
                              error={!!errors.vehicleModelId}
                              helperText={
                                errors.vehicleModelId
                                  ? errors.vehicleModelId.message
                                  : ""
                              }
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                              // {...field}
                            >
                              {Array.isArray(model) &&
                                model?.map((m) => {
                                  return (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: m?._id,
                                        name: m?.name,
                                      })}
                                      key={m?._id}
                                    >
                                      {m?.name}
                                    </MenuItem>
                                  );
                                })}
                              {modelLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </Select>
                          )}
                        />
                      )}
                      {errors.vehicleModelId && (
                        <FormHelperText>
                          {errors.vehicleModelId.message}
                        </FormHelperText>
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <FormControl fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                        }}
                      >
                        Trim
                        {/* <span style={{ color: REQUIRED_RED }}>*</span> */}
                      </Typography>
                      {editVehicle?.edit && trimLoader && trimValue != null ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          className="skelton"
                          sx={{ fontSize: "3.4rem", m: 0 }}
                        ></Skeleton>
                      ) : (
                        <Controller
                          name="vehicleModelTrimId"
                          control={control}
                          // rules={{
                          //   required: "Make is required",
                          // }}
                          render={({ field, fieldState }) => (
                            <Select
                              fullWidth
                              value={trimValue}
                              disabled={
                                yearValue && makeValue && modelValue
                                  ? false
                                  : true
                              }
                              onChange={(event) => {
                                field.onChange(event);

                                setTrimValue(event.target.value);
                              }}
                              error={!!errors.vehicleModelTrimId}
                              helperText={
                                errors.vehicleModelTrimId
                                  ? errors.vehicleModelTrimId.message
                                  : ""
                              }
                              MenuProps={{
                                style: { maxHeight: "400px" },
                              }}
                              // {...field}
                            >
                              {Array.isArray(trim) &&
                                trim?.map((m) => {
                                  return (
                                    <MenuItem
                                      value={JSON.stringify({
                                        id: m?._id,
                                        name: m?.name,
                                        description: m?.description,
                                      })}
                                      key={m?._id}
                                    >
                                      <div>
                                        <div style={{ fontWeight: 500 }}>
                                          {m?.name}
                                        </div>
                                        <div>{m?.description}</div>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              {trimLoader && (
                                <MenuItem disabled>
                                  <CircularProgress />
                                </MenuItem>
                              )}
                            </Select>
                          )}
                        />
                      )}
                    </Stack>
                  </FormControl>
                </Grid>
                <Divider
                  textAlign="center"
                  style={{
                    width: "100%",
                    padding: "0 10px",
                    marginTop: "24px",
                  }}
                >
                  And
                </Divider>
                <Grid item xs={12} md={12} xl={12}>
                  <Stack spacing={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      Licence Plate Number{" "}
                      <span style={{ color: REQUIRED_RED }}>*</span>
                    </Typography>

                    <Controller
                      name="licenseNumber"
                      control={control}
                      rules={{
                        required: "Licence Plate Number is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          error={!!errors.licenseNumber}
                          helperText={
                            errors.licenseNumber
                              ? errors.licenseNumber.message
                              : ""
                          }
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                          {...field}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              clearErrors("licenseNumber");
                            } else {
                              setError("licenseNumber", {
                                message: "Licence Plate Number is required",
                              });
                            }
                            setValue(
                              "licenseNumber",
                              e.target.value.trim().length == 0
                                ? e.target.value.trim().toUpperCase()
                                : e.target.value.toUpperCase()
                            );
                          }}
                        />
                      )}
                    />
                    {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                          /> */}
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent={"space-between"}
                p={3}
                pt={0}
              >
                <Grid item>
                  <Stack spacing={2} direction="row">
                    {editVehicle?.edit && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteVehicle}
                        size="large"
                      >
                        DELETE
                      </Button>
                    )}
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClose();
                        resetValues();
                      }}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Modal>
    </Box>
  );
};

export default VehicleModel;
