import { Box, styled } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MyCalendar from "../container/Calendar/Calendar";
import Customers from "../container/Customers/Customers";
import NewCustomers from "../container/Customers/NewCustomers/NewCustomers";
import Profile from "../container/Customers/Profile/Profile";
import Dashboard from "../container/Dashboard/Dashboard";
import EstimatesDrafts from "../container/Estimates/Drafts/EstimatesDrafts";
import Estimates from "../container/Estimates/Estimates";
import EstimatesSend from "../container/Estimates/EstimatesSent/EstimatesSend";
import NewEstimates from "../container/Estimates/NewEstimates/NewEstimates";
import Drafts from "../container/Invoice/Drafts/Drafts";
import Invoice from "../container/Invoice/Invoice";
import NewInvoice from "../container/Invoice/NewInvoice/NewInvoice";
import Send from "../container/Invoice/Send/Send";
import Shop from "../container/Shop/Shop";
import InviteCustomer from "../container/Sidebar/InviteCustomer";
import Notifications from "../container/Sidebar/Notifications";
import CommonSidebar from "../container/Sidebar/Sidebar";

import Appointments from "../container/Appointment/Appointment";
import NewAppointment from "../container/Appointment/NewAppointment/NewAppointment";
import PastAppointmentView from "../container/Appointment/PastAppointmentView/PastAppointmentView";
import CalendarSetting from "../container/Calendar/CalendarSetting";
import ContactSupport from "../container/ContactSupport/ContactSupport";
import MergeCustomers from "../container/Customers/Merge";
import NewImportedCustomers from "../container/Customers/NewImportedCustomers/NewImportedCustomers";
import NewMergeCustomers from "../container/Customers/NewMerge";
import EstimatesRequest from "../container/Estimates/EstimatesRequest/EstimatesRequest";
import CustomerChat from "../container/Messenger/CustomerChat";
import Messenger from "../container/Messenger/Messenger";
import NotFound from "../container/NotFound/NotFound";
import Requests from "../container/Requests/Requests";
import SubscriptionTiers from "../container/Shop/Billing/SubscriptionTiers";
import Approvals from "../container/approval/Approval";
import CurrentApproval from "../container/approval/CurrentApproval/CurrentApproval";
import NewApprovals from "../container/approval/NewApprovals/NewApprovals";
import WorkOrderAuthorization from "../container/WorkOrderAuthorization/WorkOrderAuthorization";
import SiteMaintennance from "../container/SiteMaintenance/SiteMaintennance";

const EmptyPage = () => {
  return <div></div>;
};
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const PrivateRoutes = () => {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();

  const authData = JSON.parse(localStorage.getItem("apex-saas_details"));

  //Clear Search value from Customer list
  useEffect(() => {
    if (location.pathname.split("/").includes("customers")) {
      if (location.pathname.split("/").includes("merge")) {
        localStorage.removeItem("searchValue");
      }
    } else {
      localStorage.removeItem("searchValue");
    }
  }, [location]);
  useEffect(() => {
    if (window.innerWidth < 900) {
      setOpen(false);
    }
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Box sx={{ display: "flex" }} className="mainBox_container">
        <CommonSidebar open={open} setOpen={setOpen} />
        <Box
          component="main"
          sx={{
            width: open ? "calc(100% - 288px)" : "-webkit-fill-available",
          }}
        >
          <DrawerHeader />
          <Routes>
            <Route
              exact
              path={"/"}
              element={
                authData?.allowedPlatforms.find((d) => d == "web") ? (
                  <Dashboard />
                ) : (
                  <EmptyPage />
                )
              }
            />

            {/* <Route exact path={"/dragNdrop"} element={<DragNDrop />} /> */}
            {authData?.subscriptionStatus &&
              authData?.subscriptionStatus == "active" && (
                <>
                  <Route
                    exact
                    path={"/account-settings/:name"}
                    element={<Shop />}
                  />
                  <Route
                    exact
                    path={"/account-settings/billing-plans"}
                    element={<SubscriptionTiers />}
                  />

                  <Route
                    exact
                    path={"/notifications"}
                    element={<Notifications />}
                  />
                  <Route
                    exact
                    path={"/referrals"}
                    element={<InviteCustomer />}
                  />
                  <Route
                    exact
                    path="contact-support"
                    element={<ContactSupport />}
                  />
                </>
              )}
            {authData?.allowedPlatforms &&
              authData?.allowedPlatforms.find((d) => d == "web") && (
                <>
                  <Route exact path={"/dashboard"} element={<Dashboard />} />

                  <Route exact path={"/customers"} element={<Customers />} />
                  <Route
                    exact
                    path={"/customers/new-customer"}
                    element={<NewCustomers />}
                  />
                  <Route
                    exact
                    path={"/customers/:id/:type?"}
                    element={<Profile />}
                  />
                  <Route
                    exact
                    path={"/customers/merge"}
                    element={<MergeCustomers />}
                  />
                  <Route
                    exact
                    path={"/customers/merge/new/"}
                    element={<NewMergeCustomers />}
                  />

                  {/* <Route exact path={"/invoice"} element={<Invoice />} /> */}
                  {/* <Route exact path={"/invoice"} element={<Invoice />} /> */}
                  <Route exact path={"/invoice/all"} element={<Invoice />} />
                  <Route
                    exact
                    path={"/invoice/draft/:id"}
                    element={<Drafts />}
                  />
                  <Route exact path={"/invoice/drafts"} element={<Invoice />} />
                  <Route exact path={"/invoice/sent/:id"} element={<Send />} />
                  <Route exact path={"/invoice/sents"} element={<Invoice />} />

                  <Route
                    exact
                    path={"/invoice/edit/:id"}
                    element={<NewInvoice />}
                  />
                  <Route exact path={"/invoice/new"} element={<NewInvoice />} />
                  <Route
                    exact
                    path={"/invoice/new/:appoinmentId"}
                    element={<NewInvoice />}
                  />

                  <Route
                    exact
                    path={"/estimates/requests"}
                    element={<Estimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/all"}
                    element={<Estimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/drafts"}
                    element={<Estimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/draft/:id"}
                    element={<EstimatesDrafts />}
                  />
                  <Route
                    exact
                    path={"/estimates/sent/:id"}
                    element={<EstimatesSend />}
                  />
                  {/* <Route exact path={"/estimates/request/:id"} element={<EstimatesRequest />} /> */}
                  <Route
                    exact
                    path={"/estimates/decline/:id"}
                    element={<EstimatesRequest />}
                  />
                  <Route
                    exact
                    path={"/estimates/accept/:id"}
                    element={<EstimatesRequest />}
                  />
                  <Route
                    exact
                    path={"/estimates/sents"}
                    element={<Estimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/sent"}
                    element={<EstimatesSend />}
                  />
                  <Route
                    exact
                    path={"/estimates/edit/:id"}
                    element={<NewEstimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/estimate-request-edit/:estimateRequestId"}
                    element={<NewEstimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/new"}
                    element={<NewEstimates />}
                  />
                  <Route
                    exact
                    path={"/estimates/new/:customerId/:type?"}
                    element={<NewEstimates />}
                  />

                  <Route
                    exact
                    path={"/approvals/waiting"}
                    element={<Approvals />}
                  />
                  <Route
                    exact
                    path={"/approvals/responded"}
                    element={<Approvals />}
                  />
                  <Route
                    exact
                    path={"/approvals/deferred"}
                    element={<Approvals />}
                  />
                  <Route
                    exact
                    path={"/appointment/newApproval/:id"}
                    element={<NewApprovals />}
                  />
                  <Route
                    exact
                    path={"/appointment/workOrderAuthorization/:id"}
                    element={<WorkOrderAuthorization openSidebar={open} />}
                  />
                  <Route
                    exact
                    path={"/workOrderAuthorization/:id"}
                    element={<WorkOrderAuthorization openSidebar={open} />}
                  />
                  <Route
                    exact
                    path={"/approval/:id"}
                    element={<CurrentApproval />}
                  />

                  <Route
                    exact
                    path={"/calendar/:calendarView?"}
                    element={<MyCalendar />}
                  />

                  {/* <Route exact path={"/appointments"} element={<Appointments />} /> */}
                  <Route
                    exact
                    path={"/appointment/today"}
                    element={<Appointments />}
                  />
                  <Route
                    exact
                    path={"/appointment/upcoming-appointments"}
                    element={<Appointments />}
                  />
                  <Route
                    exactApprovals
                    path={"/appointment/past-appointments"}
                    element={<Appointments />}
                  />
                  <Route
                    exact
                    path={"/appointment/past-appointment/:id"}
                    element={<PastAppointmentView />}
                  />
                  <Route
                    exact
                    path={"/appointment/new-appointment"}
                    element={<NewAppointment />}
                  />
                  <Route
                    exact
                    path={"/appointment/new-appointment/:id/:type?"}
                    element={<NewAppointment />}
                  />

                  <Route
                    exact
                    path="/request/appointments"
                    element={<Requests />}
                  />
                  <Route
                    exact
                    path="/request/estimates"
                    element={<Requests />}
                  />
                  <Route
                    exact
                    path="/calendar/settings"
                    element={<CalendarSetting />}
                  />
                  <Route exact path="/messenger/:id?" element={<Messenger />} />
                  <Route
                    exact
                    path="/customerChat/:id"
                    element={<CustomerChat />}
                  />

                  <Route
                    exact
                    path="/customers/imported-customer/:id"
                    element={<NewImportedCustomers />}
                  />

                  <Route
                    exact
                    path={"/invoice/new/:customerId/:type?"}
                    element={<NewInvoice />}
                  />
                  <Route
                    exact
                    path="/site-maintenance"
                    element={<SiteMaintennance />}
                  />
                </>
              )}

            <Route exact path={"/*"} element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </>
  );
};

export default PrivateRoutes;
