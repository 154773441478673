import React, { useEffect, useState } from "react";
import "./ShopHours.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import {
  AppBar,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  addCustomHolidays,
  deleteCustomHolidays,
  getCustomHolidays,
  getTimeZones,
  updateCustomHolidays,
  updateOntarioHolidays,
} from "../../../redux/Account/shopHours/action";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import InfiniteScroll from "react-infinite-scroll-component";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { Controller, useForm } from "react-hook-form";
import Loader from "../../Common/Loader";

const label = { inputProps: { "aria-label": "Switch demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: "32px 0px 10px 0px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
}));

const CustomHolidays = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const getTimeZoneRedux = useSelector((state) => state?.account?.getTimeZone);

  const [open, setOpen] = React.useState(false);
  const [nextOpen, setNexOpen] = React.useState(false);
  const [showTimeInMOdal, setShowTimeInModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState("Africa/Abidjan");
  const [validationDateError, setValidationDateError] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNextOpen = () => {
    setNexOpen(true);
    setOpen(false);
  };


  const [Id, setId] = useState();
  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState();
  const [customHolidayModalData, setCustomHolidayModalData] = useState({
    starting: null,
    closing: dayjs().hour(23).minute(59), // Set default closing time to 11:59 PM
    date: null,
    startingDate: null,
    closingDate: null,
    status: null,
  });


  const handleClose = () => {
    setOpen(false);
    setNexOpen(false);
    setEditModalOpen(false);
    resetallvalueofModal();
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openDeleteConformModal, setOpenDeleteConformModal] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    getCustomHoliday(null, false);
  }, []);

  const getCustomHoliday = async (currentPage, InfiniteScrollCall) => {
    if (InfiniteScrollCall == false) setLoading(true);
    let res = await dispatch(
      getCustomHolidays(limit, currentPage ? currentPage : page)
    );

    if (res) {
      // setShowLoader(false);
      setCurrentPages(res.currentPage);
      setTotalPages(res.totalPages);
      res = res.data;

      if (currentPage) {
        if (currentPage == 1) {
          setCardData(res);
        } else {
          setCardData([...cardData, ...res]);
        }
      } else {
        if (page == 1) {
          setCardData(res);
        } else {
          setCardData([...cardData, ...res]);
        }
      }

      if (InfiniteScrollCall == false) setLoading(false);
    }
  };
  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const convertTimestampToFormattedDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${month} ${ordinalSuffix} , ${year}`;
  };

  const saveModalDataHandler = async (data) => {
    // handleNextOpen();
    setButtonDisabled(true);

    // Add validation to check if closing time is greater than opening time
    // const openingTime = dayjs(customHolidayModalData?.starting);

    // const closingTime = dayjs(customHolidayModalData?.closing);

    // if (
    //   closingTime?.isBefore(openingTime) ||
    //   closingTime?.isSame(openingTime)
    // ) {
    //   setValidationDateError("Closing time must be greater than opening time");
    //   // setShowLoader(false);
    //   return;
    // }

    const formattedData = {
      title: data.title,
      startTime: customHolidayModalData?.starting?.$d
        ? dayjs(customHolidayModalData?.starting?.$d).format("HH:mm")
        : dayjs(customHolidayModalData.starting).format("HH:mm"),
      endTime: customHolidayModalData?.closing?.$d
        ? dayjs(customHolidayModalData?.closing?.$d).format("HH:mm")
        : dayjs(customHolidayModalData.closing).format("HH:mm"),
      type: customHolidayModalData.status,
      startDate:
        customHolidayModalData.status == "single_full_day" ||
          customHolidayModalData.status == "single_day_with_some_hours"
          ? dayjs(customHolidayModalData.date, {
            format: "MM/DD/YYYY",
          }).format("YYYY-MM-DD")
          : dayjs(customHolidayModalData.startingDate, {
            format: "MM/DD/YYYY",
          }).format("YYYY-MM-DD"),
      endDate: dayjs(customHolidayModalData.closingDate, {
        format: "MM/DD/YYYY",
      }).format("YYYY-MM-DD"),
    };
    console.log("formattedData", formattedData)

    if (customHolidayModalData.status == "single_full_day") {
      delete formattedData.startTime;
      delete formattedData.endTime;
      delete formattedData.endDate;
    }
    if (customHolidayModalData.status == "multiple_full_days") {
      delete formattedData.startTime;
      delete formattedData.endTime;
    }
    if (customHolidayModalData.status == "single_day_with_some_hours") {
      delete formattedData.endDate;
    }

    let res;
    if (editModalOpen) {
      res = await dispatch(updateCustomHolidays(formattedData, Id));
    } else {
      res = await dispatch(addCustomHolidays(formattedData));
    }

    if (res) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      setPage(1);
      handleClose();

      getCustomHoliday(1, false);
      setEditModalOpen(false);
      resetallvalueofModal();
    }
    setButtonDisabled(false);
    setValidationDateError("");
  };

  const EditTimeSetData = async (data) => {
    setEditModalOpen(true);
    setId(data._id);
    handleNextOpen();

    const selectedDate = dayjs(data?.startDate).format("MM/DD/YYYY");
    const selectedEndDate = dayjs(data?.endDate).format("MM/DD/YYYY");
    const startingTime = data?.startTime
    ? dayjs(`2022-04-17T${data?.startTime}`)
    : dayjs().hour(0).minute(0);


  const closingTime = data?.endTime
    ? dayjs(`2022-04-17T${data?.endTime}`)
    : startingTime.hour(23).minute(55);

  setCustomHolidayModalData({
    starting: startingTime,
    closing: closingTime,
    date: dayjs(selectedDate),
    startingDate: dayjs(selectedDate),
    closingDate: dayjs(selectedEndDate),
    status: data?.type,
  });

    setValue("title", data?.title == "undefined" ? "" : data?.title);
  };


  const DeleteClicked = async (data) => {
    // setId(data._id);
    setLoading(true);
    const res = await dispatch(deleteCustomHolidays(Id));
    if (res) {
      setPage(1);
      getCustomHoliday(1, false);
    }
  };

  const disbaledTime = (value, view, time) =>
    (view === "hours" && value.hour() < time?.split("T")[1]?.split(":")[0]) ||
    (view === "minutes" &&
      value.hour() == time?.split("T")[1]?.split(":")[0] &&
      value.minute() < time?.split("T")[1]?.split(":")[1]);

  const disablePrevDates = (startDate) => {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) <= startSeconds;
    };
  };

  const resetallvalueofModal = () => {
    setValue("title", "");
    setCustomHolidayModalData({
      starting: null,
      closing: null,
      date: null,
      startingDate: null,
      closingDate: null,
      status: null,
    });
  };

  return (
    <>
      <Loader showLoader={loading} />
      <Box
        className="box3"
        sx={{ width: "100%", height: "100%", bgcolor: "white" }}
        boxShadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}
        padding={"10px"}
        borderRadius={"10px"}
      >
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          padding={"20px"}
          borderRadius={"10px"}
          paddingRight={"20px"}
          boxShadow={"none"}
        >
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
            paddingRight={"10px"}
          >
            <Typography variant="h5" style={{ fontWeight: 400 }}>
              Custom Holidays{" "}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8.5}
            lg={8.5}
            xl={8.5}
            direction={"row"}
            // spacing={4}
            rowSpacing={4}
            // columnGap={2}
            justifyContent={"end"}
            padding={"10px"}
          >
            <Grid item>
              <Stack justifyContent={"end"} spacing={2}>
                <Button
                  className="btn-text-size-manage"
                  variant="contained"
                  color="secondary"
                  onClick={handleNextOpen}
                >
                  Add Custom Holiday
                </Button>
              </Stack>
            </Grid>
            <div style={{ width: "100%" }} id="scrollableDiv">
              {!loading ? (
                cardData && cardData?.length > 0 ? (
                  <InfiniteScroll
                    // data={cardData}
                    dataLength={cardData.length}
                    next={() => {
                      setPage(page + 1);
                      getCustomHoliday(page + 1, true);
                    }}
                    hasMore={currentPages <= totalPages}
                    loader={
                      currentPages < totalPages && (
                        <div style={{ height: "50px" }}>
                          <CircularProgress
                            style={{ display: "block", margin: "auto" }}
                          />
                        </div>
                      )
                    }
                    // scrollableTarget="scrollableDiv"
                  >
                    {cardData?.map((data) => {
                      return (
                        <div>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            xl={12}
                            direction={"row"}
                            spacing={2}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            style={{ marginTop: "35px" }}
                          >
                            <Grid item xs={6} sm={6} md={6} xl={6}>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "500" }}
                              >
                                {data?.title == "undefined" ? "" : data?.title}
                              </Typography>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "500" }}
                              >
                                {convertTimestampToFormattedDate(
                                  data.startDate
                                )}
                              </Typography>
                              <Typography
                                variant="body"
                                style={{ lineHeight: "35px" }}
                              >
                                {data.type == "single_full_day" ||
                                data.type == "multiple_full_days"
                                  ? "Closed"
                                  : `${data.startTimeIn12Hr} to ${data.endTimeIn12Hr}`}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              xl={3}
                              spacing={2}
                              direction={"row"}
                              alignItems={"center"}
                              style={{ display: "contents" }}
                            >
                              <Stack spacing={1} direction="row">
                                <Button
                                  className="btn-text-size-manage"
                                  variant="contained"
                                  size="medium"
                                  onClick={() => {
                                    EditTimeSetData(data);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  className="btn-text-size-manage"
                                  color="error"
                                  variant="contained"
                                  size="medium"
                                  onClick={() => {
                                    setId(data._id);
                                    setOpenDeleteConformModal(true);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Divider style={{ width: "100%", height: "1px" }} />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  !loading && (
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 400, textAlign: "center" }}
                    >
                      Data is not available
                    </Typography>
                  )
                )
              ) : (
                <CircularProgress
                  style={{ display: "block", margin: "auto" }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={nextOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "720px",
            // height: showTimeInMOdal ? "440px" : "340px",
            border: "none",
          }}
          className="Modal-z-index"
        >
          <AppBar variant="elevation"
          sx={{
            height: "64px",
            paddingRight: "0px !important",
          }}>
            <Toolbar variant="regular" sx={{ bgcolor: "secondary.main" }}>
              <Typography
                fontSize={"20px"}
                variant="body1"
                gutterBottom={false}
                width={"98%"}
                color={"white"}
                fontWeight={500}
              >
                {editModalOpen ? "Edit Custom Holiday" : "Add Custom Holiday"}
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={() => {
                    handleClose();
                    setValidationDateError("");
                    resetallvalueofModal();
                  }}
                  style={{ cursor: "pointer",height:'32px' }}
                  >
                  <CloseIcon style={{ color: "#0000008F" ,fontSize: 32}} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
          <form onSubmit={handleSubmit(saveModalDataHandler)}>
            <Container
              gap={"24px"}
              disableGutters
              style={{
                // height: "302px"
                marginTop: "66px",
                zIndex: "1000",
              }}
            >
              <Grid
                container
                justifyContent={"start"}
                spacing={4}
                zIndex={2900}
              >
                <Grid item md={12} xl={12} spacing={2}>
                  <Grid md={12} xl={12} spacing={2}>
                    <Typography variant="body1" className="label-500">
                      Holiday Title{" "}
                    </Typography>
                    <Controller
                      name="title"
                      control={control}
                      rules={{ required: "Title is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          error={!!errors.title}
                          helperText={errors.title ? errors.title.message : ""}
                          value={field.value == undefined ? "" : field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          InputLabelProps={{
                            shrink:
                              (field.value !== "undefined" &&
                                field?.value?.length > 0) ||
                              false,
                          }}
                        />
                      )}
                    />
                    {/* )} /> */}
                  </Grid>
                  <Grid
                    md={12}
                    xl={12}
                    spacing={2}
                    className="margin-top-fixed"
                  >
                    <Typography variant="body1" className="label-500">
                      Shop Status
                    </Typography>
                    <Select
                      value={customHolidayModalData.status}
                      // label="customer"
                      onChange={(e) => {
                        let newData = { ...customHolidayModalData };
                        newData.status = e.target.value;
                        setCustomHolidayModalData(newData);
                      }}
                      className="customer-dropdown"
                    >
                      <MenuItem value="single_full_day">
                        Closed Single Full Day
                      </MenuItem>
                      <MenuItem value="multiple_full_days">
                        Closed Multiple Full Days
                      </MenuItem>
                      <MenuItem value="single_day_with_some_hours">
                        Single Day With Some Hours
                      </MenuItem>
                      <MenuItem value="multiple_days_with_some_hours">
                        Multiple Days With Some Hours
                      </MenuItem>
                    </Select>
                  </Grid>

                  {/* <div style={{ marginTop: "20px" }}>
                  <Switch
                    // {...label}
                    onChange={(e) => {
                      setShowTimeInModal(e.target.checked);
                    }}
                    checked={showTimeInMOdal}
                  />{" "}
                  {showTimeInMOdal ? "Open" : "Closed"}
                </div> */}
                  {/* {showTimeInMOdal && ( */}
                  {(customHolidayModalData.status == "single_full_day" ||
                    customHolidayModalData.status ==
                      "single_day_with_some_hours") && (
                    <div className="custom-holidays-date-picker  margin-top-fixed">
                      <Typography variant="body1" className="label-500">
                        Shop Closed Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            value={dayjs(customHolidayModalData.date)}
                            onChange={(data) => {
                              const formattedDate =
                                dayjs(data).format("MM/DD/YYYY");
                              let newData = { ...customHolidayModalData };
                              newData.date = formattedDate;
                              setCustomHolidayModalData(newData);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}

                  {(customHolidayModalData.status == "multiple_full_days" ||
                    customHolidayModalData.status ==
                      "multiple_days_with_some_hours") && (
                    <div className="space-between-style margin-top-fixed">
                      <div>
                        <Typography variant="body1" className="label-500">
                          Closed From Date
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              value={dayjs(
                                customHolidayModalData?.startingDate
                              )}
                              onChange={(data) => {
                                const formattedDate =
                                  dayjs(data).format("MM/DD/YYYY");
                                let newData = { ...customHolidayModalData };
                                newData.startingDate = formattedDate;
                                setCustomHolidayModalData(newData);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div
                        className="to_center_of_date"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "32px",
                        }}
                      >
                        to
                      </div>
                      <div>
                        <Typography variant="body1" className="label-500">
                          Closing To Date{" "}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              value={dayjs(customHolidayModalData?.closingDate)}
                              onChange={(data) => {
                                const formattedDate =
                                  dayjs(data).format("MM/DD/YYYY");
                                let newData = { ...customHolidayModalData };
                                newData.closingDate = formattedDate;
                                setCustomHolidayModalData(newData);
                              }}
                              shouldDisableDate={disablePrevDates(
                                customHolidayModalData.startingDate
                              )}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  )}
                  {(customHolidayModalData.status ==
                    "single_day_with_some_hours" ||
                    customHolidayModalData.status ==
                      "multiple_days_with_some_hours") && (
                    <div className="space-between-style margin-top-fixed">
                      <div>
                        <Typography variant="body1" className="label-500">
                          Closed From Time
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["TimePicker", "TimePicker"]}
                          >
                            <TimePicker
                              value={dayjs(customHolidayModalData?.starting)}
                              onChange={(newValue) => {
                                const inputDate = dayjs(newValue?.$d);

                                const formattedDate = dayjs()
                                  .hour(inputDate.hour())
                                  .minute(inputDate.minute())
                                  .format("YYYY-MM-DDTHH:mm");

                                setCustomHolidayModalData({
                                  ...customHolidayModalData,
                                  starting: formattedDate,
                                });
                                setButtonDisabled(false);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "32px",
                        }}
                      >
                        to
                      </div>
                      <div>
                        <Typography variant="body1" className="label-500">
                          Closing To Time
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["TimePicker", "TimePicker"]}
                          >
                            <TimePicker
                              value={dayjs(customHolidayModalData?.closing)}
                              defaultValue={dayjs().hour(23).minute(55)}
                              onChange={(newValue) => {
                                const inputDate = dayjs(newValue?.$d);

                                const formattedDate = dayjs()
                                  .hour(inputDate.hour())
                                  .minute(inputDate.minute())
                                  .format("YYYY-MM-DDTHH:mm");

                                setCustomHolidayModalData({
                                  ...customHolidayModalData,
                                  closing: formattedDate,
                                });
                                setButtonDisabled(false);
                              }}
                              shouldDisableTime={(v, e) =>
                                dayjs(customHolidayModalData?.starting, {
                                  strict: true,
                                }).format("YYYY-MM-DDTHH:mm") ===
                                  customHolidayModalData?.starting &&
                                disbaledTime(
                                  v,
                                  e,
                                  customHolidayModalData?.starting
                                )
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  )}
                  {/* )} */}
                  {validationDateError != "" && (
                    <Typography variant="body2" color="error">
                      {validationDateError}
                    </Typography>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  justifyContent={editModalOpen ? "space-between" : "end"}
                  spacing={2}
                >
                  {editModalOpen && (
                    <Grid item justifyContent={"start"}>
                      <Button
                        className="btn-text-size-manage"
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setOpenDeleteConformModal(true);
                          handleClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  )}
                  <Grid item spacing={2}>
                    <Stack spacing={2} direction="row">
                      <Button
                        className="btn-text-size-manage"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleClose();
                          setValidationDateError("");
                          resetallvalueofModal();
                        }}
                      >
                        Cancel{" "}
                      </Button>
                      <Button
                        className="btn-text-size-manage"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isButtonDisabled}
                        // onClick={saveModalDataHandler}
                      >
                        SAVE
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </form>
        </Box>
      </Modal>
      <Dialog
        open={openDeleteConformModal}
        onClose={() => setOpenDeleteConformModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this custom holiday?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              setOpenDeleteConformModal(false);
            }}
          >
            No
          </Button>
          <Button
            className="btn-text-size-manage"
            onClick={() => {
              DeleteClicked();
              setOpenDeleteConformModal(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </>
  );
};

export default CustomHolidays;
