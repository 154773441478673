import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ImagesUpload.css";

import { DeleteOutlined } from "@mui/icons-material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getItem, removeItem, setItem } from "../../../utility/localStorage";
import ShopImageShowModal from "../../modal/ShopImagePreviewModal";
import CustomImageUploadButton from "./CustomImageUpload";
import { useDispatch } from "react-redux";
import {
  getShopImagesList,
  updateShopImages,
} from "../../../redux/Account/ShopDetails/action";
import { imageUrl } from "../../../config/dataService/dataService";
import { toast } from "sonner";
import CloseIcon from "@mui/icons-material/Close";

// import galleryList from "./data.js";
const deleteStyle = {
  position: "absolute",
  top: "0%",
  left: "0%",
  width: 400,
  transform: "translate(-0%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const Card = ({
  src,
  title,
  id,
  index,
  moveImage,
  setImageOpenModalShows,
  setImageView,
  handleDelete,
  handleEdit,

  setCurrentIndex,
  canDrag,
}) => {
  const ref = React.useRef(null);
  const [uploadedImage, setUploadedImage] = useState();
  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    canDrag: canDrag,
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const currentIndex = getItem("index");
  useEffect(() => {
    if (uploadedImage) {
      if (currentIndex) {
        handleEdit(currentIndex - 1, uploadedImage);
        removeItem("index");
      }
      // else {
      //   handleAdd(index, uploadedImage);
      // }
      setUploadedImage(null);
    }
  }, [uploadedImage]);

  return (
    // <Grid item xs={12} sm={5} md={5.5} lg={3.3} xl={2}>
    <div ref={ref} className="card-container" style={{ opacity }}>
      {index == 0 && (
        <div className="card-overlay">
          <div className="primary">Primary </div>
          <CustomImageUploadButton
            setUploadedImage={setUploadedImage}
            edit={true}
            index={index}
          />
          {/* <EditOutlined
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => handleEdit(index)}
            /> */}
        </div>
      )}
      {index != 0 && (
        <div
          className="card-overlay-icons"
          onClick={() => {
            setCurrentIndex(index);
          }}
        >
          {/* <EditOutlined
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => handleEdit(index)}
            /> */}
          {/* <CustomImageUploadButton
              setUploadedImage={setUploadedImage}
              edit={true}
            /> */}
          {index > 3 && (
            <DeleteOutlined
              onClick={() => handleDelete(index)}
              className="icon delete"
            />
          )}
          <CustomImageUploadButton
            setUploadedImage={setUploadedImage}
            edit={true}
            index={index}
          />
        </div>
      )}
      {/* {src ? ( */}
      <img
        src={src}
        alt={title}
        className="card-image"
        onClick={() => {
          setImageOpenModalShows(true);
          setImageView(src);
        }}
      />
      {/* // ) : ( //{" "}
        <CustomImageUploadButton setUploadedImage={setUploadedImage} />
        // )} */}
    </div>
    // </Grid>
  );
};
const ImageUploader = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [images, setImages] = React.useState([]);
  const [tempImages, setTempImages] = React.useState([]);

  const [imageOpenModalShows, setImageOpenModalShows] = useState(false);
  const [imageView, setImageView] = useState("");
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const localStorageImages = getItem("apex-saas_details");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [curentIndex, setCurrentIndex] = useState("");
  useEffect(() => {
    getImages();
  }, []);
  const getImages = async () => {
    setSkeletonLoader(true);
    const resp = await dispatch(getShopImagesList());
    if (resp) {
      let temp = [];
      resp?.images?.map((img, index) => {
        temp.push({
          id: index + 1,
          title: img.type,
          img: imageUrl + img.url,
          serverImg: img.url,
        });
      });
      setImages(temp);
      setTempImages(temp);
      let allLocalData = localStorageImages;
      allLocalData.images = resp?.images;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChange"));
    }
    setSkeletonLoader(false);
  };

  const moveImage = React.useCallback((dragIndex, hoverIndex) => {
    setImages((prevCards) => {
      const clonedCards = [...prevCards];
      const [removedItem] = clonedCards.splice(dragIndex, 1);
      clonedCards.splice(hoverIndex, 0, removedItem);
      return clonedCards;
    });
  }, []);
  const handleDelete = (idx) => {
    setOpenDelete(true);
    setDeleteId(idx);
    // console.log("delete idex", idx);
    // const allImages = [...images];
    // allImages.splice(idx, 1);
    // const lastIndex = allImages[allImages.length - 1];
    // console.log("lastIndex", lastIndex);
    // // if (Object.keys(lastIndex).length != 0) {
    // //   allImages.push({});
    // // }
    // setImages(allImages);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteId(null);
  };
  const handleDeleteCofirm = () => {
    const allImages = [...images];
    allImages.splice(deleteId, 1);

    setImages(allImages);
    setOpenDelete(false);
    setDeleteId(null);
  };

  const handleEdit = (idx, file) => {
    const allImages = [...images];
    allImages[idx] = {
      id: idx + 1,
      title: "",
      img: URL.createObjectURL(file),
      file: file,
    };

    setImages(allImages);
  };
  const handleAdd = (idx, file) => {
    const allImages = [...images];
    // allImages[allImages.length - 1] = {
    //   id: idx + 1,
    //   title: "",
    //   img: URL.createObjectURL(file),
    //   file: file,
    // };

    // if (allImages.length < 10) {
    if (file) {
      allImages.push({
        id: idx + 1,
        title: "",
        img: URL.createObjectURL(file),
        file: file,
      });
      // }
      setImages(allImages);
    }
  };
  const handleSaveImages = async () => {
    let payload = {};

    // images.map((data, index) => {
    //   formData.append(`images[${index}]`, data.file);
    // });
    images.forEach((data, index) => {
      if (data.file) {
        payload[`images[${index}]`] = data.file;
      } else {
        payload[`images[${index}]`] = data.serverImg;
      }
      // paylaod(`images[${index}]`, data.file);
    });
    setShowLoader(true);
    const resp = await dispatch(updateShopImages(payload));
    if (resp) {
      toast.success("Changes saved");
      let temp = [];
      resp?.images?.map((img, index) => {
        temp.push({
          id: index + 1,
          title: img.type,
          img: imageUrl + img.url,
          serverImg: img.url,
        });
      });
      setImages(temp);
      setTempImages(temp);
      let allLocalData = localStorageImages;
      allLocalData.images = resp?.images;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChange"));
    }
    setShowLoader(false);
  };

  return (
    <>
      <Grid
        container
        sx={{ boxShadow: 3, bgcolor: "white", flexDirection: "column" }}
        // justifyContent={"space-between"}
        padding={"24px"}
        borderRadius={"10px"}
        marginTop={"32px"}
      >
        <Grid
          container
          justifyContent={"space-between"}
          borderRadius={"10px"}
          mb={3}
        >
          <Grid item xs={12} sm={8} md={8} xl={10}>
            <Typography variant="h5">Shop Images</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            xl={2}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="add-image-upload-button"
                onChange={(event) => {
                  if (event.target.files[0].type.startsWith("image/")) {
                    handleAdd(images.length - 1, event.target.files[0]);
                    event.target.value = null;
                  } else {
                    toast.info("Please select only images");
                  }
                }}
                disabled={images.length == 10}
              />

              <label htmlFor="add-image-upload-button">
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    document.getElementById("add-image-upload-button").click();
                    removeItem("index");
                  }}
                  disabled={images.length == 10}
                >
                  ADD NEW IMAGE
                </Button>
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "end",
            paddingRight: "10px",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <div
              style={{
                display: "flex",
                // width: "fit-content",
                flexWrap: "wrap",
                // justifyContent: "space-around",
                gap: "30px",
                justifyContent: "end",
              }}
            >
              {/* <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                // style={{
                //   display: "flex",
                //   justifyContent: "end",
                //   paddingRight: "10px",
                // }}
                // spacing={6}
                columnSpacing={2}
                columnGap={6.8}
                rowGap={4}
                // style={{ background: "black" }}
              > */}
              {images.map((image, index) => (
                // <div
                //   onClick={() => {
                //     setImageOpenModalShows(true);
                //     setImageView(image);
                //   }}
                // >
                <Card
                  src={image.img}
                  title={image.title}
                  id={image.id}
                  index={index}
                  moveImage={moveImage}
                  setImageOpenModalShows={setImageOpenModalShows}
                  setImageView={setImageView}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleAdd={handleAdd}
                  setCurrentIndex={setCurrentIndex}
                  canDrag={Object.keys(image).length != 0 ? true : false}
                />
                // </div>
              ))}
              {/* </Grid> */}
            </div>
          </DndProvider>
          {skeletonLoader && (
            <div className="skeleton-design">
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
              <Skeleton variant="rectangular" width={260} height={260} />
            </div>
          )}
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          spacing={2}
          style={{ marginTop: "20px" }}
        >
          <Grid item spacing={2}>
            <Stack spacing={2} direction="row">
              <Button
                className="btn-text-size-manage"
                variant="outlined"
                color="primary"
                onClick={() => setImages([...tempImages])}
                // onClick={() => {
                //   setShopPriceCancel(true);
                //   setValue("defaultHourlyRate", defaultPriceDetails);
                // }}
              >
                Cancel Changes
              </Button>
              <Button
                className="btn-text-size-manage"
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSaveImages}
              >
                Save Changes
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <ShopImageShowModal
        imageView={imageView}
        setImageView={setImageView}
        imageOpenModalShows={imageOpenModalShows}
        setImageOpenModalShows={setImageOpenModalShows}
      />
      <Dialog open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="deleteCustomer"
      >
        <Box
          sx={{
            ...deleteStyle,
            width: "100%",
            border: "none",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <AppBar
            color="secondary"
            variant="elevation"
            style={{
              width: "inherit",
              borderRadius: "8px 8px 0px 0px !important",
              padding: "0px",
            }}
          >
            <Toolbar variant="regular">
              <Typography variant="h6" width={"98%"} color={"white"}>
                Delete Image
              </Typography>
              <Stack direction={"row"} spacing={0}>
                <div
                  onClick={handleCloseDelete}
                  style={{ cursor: "pointer", height: "32px" }}
                >
                  <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                </div>
              </Stack>
            </Toolbar>
          </AppBar>
        </Box>
        <Container
          style={{
            marginTop: "64px",
            padding: "0",
          }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this image?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: "8px" }}>
            <Button onClick={handleCloseDelete} variant="outlined">
              CANCEL
            </Button>
            <Button
              onClick={handleDeleteCofirm}
              autoFocus
              color="error"
              variant="contained"
            >
              DELETE IMAGE
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
};

export default ImageUploader;
