import React, { useEffect, useState } from "react";
import "./ShopHours.css";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import {
  AppBar,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  TextField,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  getShopHours,
  getTimeZones,
  updateBusinessHours,
} from "../../../redux/Account/shopHours/action";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessageModal from "../../Common/SuccessMessage";
import Loader from "../../Common/Loader";

const label = { inputProps: { "aria-label": "Switch demo" } };

const BusinessHours = ({
  BusinessHoursData,
  getShopHourData,
  setSubmitDisabled,
  submitDisabled,
  manageCustomData,
  setManageCustomData,
}) => {
  const dispatch = useDispatch();
  const [manageBusinessHoursData, setManageBusinessHoursData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tooltipMsg, setTooltipMsg] = useState();

  function createData(date, disc, invTotal, patmentMethod, Action) {
    return { date, disc, invTotal, patmentMethod, Action };
  }

  useEffect(() => {
    getShopHour();
  }, []);

  const getShopHour = async () => {
    setShowLoader(true);
    let result = await dispatch(getShopHours());
    if (result) {
      result?.businessHours?.map((data, index) => {
        data.facingIssuesInStartTime = false;
        data.facingIssuesInEndTime = false;
      });
      BusinessHoursData(result.businessHours);
      setManageBusinessHoursData(result.businessHours);
    }
    setShowLoader(false);
  };

  const submitBusinessHours = async () => {
    let payload = {};
    setShowLoader(true);
    for (let i = 0; i < manageBusinessHoursData.length; i++) {
      payload[`businessHours[${i}][day]`] = manageBusinessHoursData[i].day;
      payload[`businessHours[${i}][startTime]`] =
        manageBusinessHoursData[i].startTime;
      payload[`businessHours[${i}][endTime]`] =
        manageBusinessHoursData[i].endTime;
      payload[`businessHours[${i}][isEnabled]`] =
        manageBusinessHoursData[i].isEnabled;
    }
    let result = await dispatch(updateBusinessHours(payload));
    if (result) {
      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
      getShopHourData();
      setSubmitDisabled(false);
    }
    setShowLoader(false);
  };
  // const disbaledTime = (value, view, index) =>
  //   (view === "hours" &&
  //     value.hour() < manageBusinessHoursData[index]?.startTime.split(":")[0]) ||
  //   (view === "minutes" &&
  //     value.hour() == manageBusinessHoursData[index]?.startTime.split(":")[0] &&
  //     value.minute() <
  //       manageBusinessHoursData[index]?.startTime.split(":")[1]);

  const disbaledTime = (value, view, index) => {
    const startHour = parseInt(
      manageBusinessHoursData[index]?.startTime.split(":")[0]
    );
    const startMinute = parseInt(
      manageBusinessHoursData[index]?.startTime.split(":")[1]
    );

    if (view === "hours") {
      if (startMinute === 30) {
        // If start minute is 30, disable the selection of the start hour
        return value.hour() <= startHour;
      }
      return value.hour() < startHour;
    } else if (view === "minutes") {
      return value.hour() === startHour && value.minute() <= startMinute;
    }

    return false;
  };

  return (
    <>
      <div className="business_hour_Container">
        <div>
          <Typography
            variant="body1"
            style={{ fontSize: "24px", fontWeight: "400", lineHeight: "32px" }}
          >
            Business Hours
          </Typography>
        </div>
          <div>
          <TableContainer component={Paper} className="table_bussines">
            <Table sx={{ minWidth: "400px" }} aria-label="simple table">
              <TableBody className="table_timing">
                {manageBusinessHoursData?.map((row, index) => (
                  <>
                  <TableRow
                    key={row.day}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    className="business-hour-height"
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="body">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            <Checkbox
                              {...label}
                              checked={row.isEnabled}
                              color="primary"
                              value={row.isEnabled}
                              onChange={() => {
                                let oldData = [...manageBusinessHoursData];
                                oldData[index].isEnabled =
                                  !oldData[index].isEnabled;
                                setManageBusinessHoursData(oldData);
                                BusinessHoursData(oldData);
                                setSubmitDisabled(true);

                                let manageOldData = [...manageCustomData];

                                manageOldData[index].isEnabled =
                                  oldData[index].isEnabled;
                                setManageCustomData(manageOldData);
                              }}
                            />
                            {row.day}
                          </div>
                        </div>
                      </Typography>
                    </TableCell>
                    {row?.isEnabled ? (
                      <div
                        style={{
                          marginLeft: "10%",
                          height: "inherit",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TableCell align="center" style={{ fontSize: "16px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {row?.facingIssuesInStartTime ? (
                              <Tooltip
                                title={
                                  <span className="time-tooltip-text">
                                    Warning:
                                    <br />
                                    {tooltipMsg == "shouldDisableTime-hours"
                                      ? "Selected time should be in range."
                                      : tooltipMsg}
                                  </span>
                                }
                                className="time-tooltip"
                                arrow
                                placement="top"
                              >
                                <Stack spacing={2}>
                                  <TimePicker
                                    /*label="Opening"*/
                                    value={dayjs(`2022-04-17T${row.startTime}`)}
                                    // value={"09:00 AM"}
                                    onChange={(newValue) => {
                                      const updatedString =
                                        newValue.$H + ":" + newValue.$m;

                                      let newData = [
                                        ...manageBusinessHoursData,
                                      ];
                                      newData[index].startTime = updatedString;
                                      newData[
                                        index
                                      ].facingIssuesInStartTime = false;
                                      setManageBusinessHoursData(newData);
                                      BusinessHoursData(newData);

                                      setSubmitDisabled(true);
                                    }}
                                    onError={(newError) => {
                                      let newData = [
                                        ...manageBusinessHoursData,
                                      ];
                                      newData[
                                        index
                                      ].facingIssuesInStartTime = true;
                                      setManageBusinessHoursData(newData);
                                      BusinessHoursData(newData);

                                      setTooltipMsg(newError);
                                    }}
                                    timeSteps={{ minutes: 30 }}
                                  />
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Stack spacing={2}>
                                <TimePicker
                                  /*label="Opening"*/
                                  value={dayjs(`2022-04-17T${row.startTime}`)}
                                  // value={"09:00 AM"}
                                  onChange={(newValue) => {
                                    const updatedString =
                                      newValue.$H + ":" + newValue.$m;

                                    let newData = [...manageBusinessHoursData];
                                    newData[index].startTime = updatedString;
                                    newData[
                                      index
                                    ].facingIssuesInStartTime = false;
                                    setManageBusinessHoursData(newData);
                                    BusinessHoursData(newData);

                                    setSubmitDisabled(true);
                                  }}
                                  onError={(newError) => {
                                    let newData = [...manageBusinessHoursData];
                                    newData[
                                      index
                                    ].facingIssuesInStartTime = true;
                                    setManageBusinessHoursData(newData);
                                    BusinessHoursData(newData);

                                    setTooltipMsg(newError);
                                  }}
                                  timeSteps={{ minutes: 30 }}
                                />
                              </Stack>
                            )}
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>to</Typography>
                        </TableCell>

                        <TableCell align="center">
                          {" "}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {row?.facingIssuesInEndTime ? (
                              <Tooltip
                                title={
                                  <span className="time-tooltip-text">
                                    Warning:
                                    <br />
                                    {tooltipMsg == "shouldDisableTime-hours"
                                      ? "Closing hours should be later than opening hours."
                                      : tooltipMsg}
                                  </span>
                                }
                                className="time-tooltip"
                                arrow
                                placement="top"
                              >
                                <Stack spacing={2}>
                                  <TimePicker
                                    /*label="Closing"*/
                                    timeSteps={{ minutes: 30 }}
                                    value={dayjs(`2022-04-17T${row.endTime}`)}
                                    onChange={(newValue) => {
                                      const inputDate = dayjs(newValue.$d);

                                      const formattedDate = dayjs(
                                        "2022-04-17T23:59:00"
                                      )
                                        .hour(inputDate.hour())
                                        .minute(inputDate.minute())
                                        .format("YYYY-MM-DDTHH:mm");

                                      const updatedString =
                                        newValue.$H + ":" + newValue.$m;

                                      let newData = [
                                        ...manageBusinessHoursData,
                                      ];
                                      newData[index].endTime = updatedString;
                                      newData[
                                        index
                                      ].facingIssuesInEndTime = false;
                                      setManageBusinessHoursData(newData);
                                      BusinessHoursData(newData);

                                      setSubmitDisabled(true);
                                    }}
                                    onError={(newError) => {
                                      let newData = [
                                        ...manageBusinessHoursData,
                                      ];
                                      newData[
                                        index
                                      ].facingIssuesInEndTime = true;
                                      setManageBusinessHoursData(newData);
                                      BusinessHoursData(newData);

                                      setTooltipMsg(newError);
                                    }}
                                    shouldDisableTime={(v, e) =>
                                      disbaledTime(v, e, index)
                                    }
                                  />
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Stack spacing={2}>
                                <TimePicker
                                  /*label="Closing"*/
                                  timeSteps={{ minutes: 30 }}
                                  value={dayjs(`2022-04-17T${row.endTime}`)}
                                  onChange={(newValue) => {
                                    const inputDate = dayjs(newValue.$d);

                                    const formattedDate = dayjs(
                                      "2022-04-17T23:59:00"
                                    )
                                      .hour(inputDate.hour())
                                      .minute(inputDate.minute())
                                      .format("YYYY-MM-DDTHH:mm");

                                    const updatedString =
                                      newValue.$H + ":" + newValue.$m;

                                    let newData = [...manageBusinessHoursData];
                                    newData[index].endTime = updatedString;
                                    newData[
                                      index
                                    ].facingIssuesInEndTime = false;
                                    setManageBusinessHoursData(newData);
                                    BusinessHoursData(newData);

                                    setSubmitDisabled(true);
                                  }}
                                  onError={(newError) => {
                                    let newData = [...manageBusinessHoursData];
                                    newData[index].facingIssuesInEndTime = true;
                                    setManageBusinessHoursData(newData);
                                    BusinessHoursData(newData);

                                    setTooltipMsg(newError);
                                  }}
                                  shouldDisableTime={(v, e) =>
                                    disbaledTime(v, e, index)
                                  }
                                />
                              </Stack>
                            )}
                          </LocalizationProvider>{" "}
                        </TableCell>
                      </div>
                    ) : (
                      <div className="closed-business-hours">Closed</div>
                    )}
                  </TableRow>
                  <Divider
                   style={{
                     margin: "12px 5px",
                     borderColor:'white'
                   }}
                 />
                </>))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          <Stack spacing={2} direction="row" justifyContent={'flex-end'}>
            <Button
                className="btn-text-size-manage"
                variant="outlined"
                color="primary"
                onClick={() => {
                  getShopHour();
                }}
              >
                CANCEL CHANGES
            </Button>
            <Button
                className="btn-text-size-manage"
                variant="contained"
                color="primary"
                onClick={submitBusinessHours}
              >
                SAVE CHANGES
            </Button>
          </Stack>
      </div>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
      <Loader showLoader={showLoader} />
    </>
  );
};

export default BusinessHours;
