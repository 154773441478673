import { API } from "../../config/api/index";

//ALL TYPE WISE DATA RETURN
const initState = {
  loading: false,
  personalDetailsData: {},
  shopDetailsData: {},
  getTermsAndWarrenty: [],
  getCancellationPolicy: [],
  getAmenities: [],
  getTimeZone: [],
  getOntarioHolidays: [],
  getShopHours: [],
  getServiceCategory: [],
  shopServicesListing: [],
  getNotificationStatus: {},
  billingHistory: [],
  currentPlan: {},
  plansList: [],
  passwordUpdate: [],
  getReschedulingData: [],
  getReschedulingUpdateData: [],
  getReschedulingList: [],
  rescheduleData: [],
  deleteRescheduleData: [],
  shopPrice: "",
  shopImages: {},
  thankyouDetailData: {},
};

//DEFINE ACCOUNT REDUCER FUNCTION
const AccountReducer = (state = initState, action) => {
  switch (action.type) {
    case API.account.shopServices.shopServicesListing:
      return {
        ...state,
        shopServicesListing: action.shopServicesListing,
      };
    case API.account.personal.getPersonalDetail:
      return {
        ...state,
        personalDetailsData: action.personalDetailsData,
        loading: action.loading,
      };
    case API.account.shopDetails.getshopDetails:
      return {
        ...state,
        shopDetailsData: action.shopDetailsData,
        loading: action.loading,
      };
    case API.account.termsAndWarrenty.getTermsAndWarrenty:
      return {
        ...state,
        getTermsAndWarrenty: action.getTermsAndWarrenty,
      };
    case API.account.cancellationPolicy.getpolicy:
      return {
        ...state,
        getCancellationPolicy: action.getCancellationPolicy,
      };

    case API.account.Amenities.getAmenities:
      return {
        ...state,
        getAmenities: action.getAmenities,
      };
    case API.account.shopHours.getTimeZone:
      return {
        ...state,
        getTimeZone: action.getTimeZone,
      };
    case API.account.shopHours.ontarioHolidays.getOntarioHolidays:
      return {
        ...state,
        getOntarioHolidays: action.getOntarioHolidays,
      };

    case API.account.shopHours.getShopHours:
      return {
        ...state,
        getShopHours: action.getShopHours,
      };
    case API.account.shopServices.getServiceCategory:
      return {
        ...state,
        getServiceCategory: action.getServiceCategory,
      };

    case API.account.notificationManage.getNotificationStatus:
      return {
        ...state,
        getNotificationStatus: action.getNotificationStatus,
      };
    case API.account.billing.getBillingHistory:
      return {
        ...state,
        billingHistory: action.billingData,
      };
    case API.account.billing.getCurrentPlan:
      return {
        ...state,
        currentPlan: action.currentPlan,
      };
    case API.account.billing.getPlans:
      return {
        ...state,
        plansList: action.plansList,
      };
    case API.auth.passwordUpdate:
      return {
        ...state,
        passwordUpdate: action.passwordUpdate,
      };
    case API.account.reschedulingPolicy.add:
      return {
        ...state,
        rescheduleData: action.rescheduleData,
      };
    case API.account.reschedulingPolicy.update:
      return {
        ...state,
        getReschedulingUpdateData: action.getReschedulingUpdateData,
      };
    case API.account.reschedulingPolicy.getById:
      return {
        ...state,
        getReschedulingData: action.getReschedulingData,
      };
    case API.account.reschedulingPolicy.getList:
      return {
        ...state,
        getReschedulingList: action.getReschedulingList,
      };
    case API.account.reschedulingPolicy.delete:
      return {
        ...state,
        deleteRescheduleData: action.deleteRescheduleData,
      };
    case API.account.shopDetails.getUpdateShopPrice:
      return {
        ...state,
        shopPrice: action.shopPrice,
      };
    case API.account.shopImages.get:
      return {
        ...state,
        shopImages: action.getShopImagesList,
      };
    case API.account.personal.getThankyouDetail:
      return {
        ...state,
        thankyouDetailData: action.thankyouDetailData,
      };
    default:
      return state;
  }
};

//EXPORT ALL FUNCTION
export default AccountReducer;
