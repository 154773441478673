import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Modal,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePlan } from "../../../redux/Account/Billing/action";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { getItem, setItem } from "../../../utility/localStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pl: 3,
  pr: 3,
  pb: 3,
};

const ChangeSubscriptionModal = ({
  openSubscriptionModal,
  setOpenSubscriptionModal,
  currentPlan,
  newPlan,
  fetchCurrentPlan = () => {},
}) => {
  // console.log(
  //   "🚀 ~ file: ServiceModal.jsx:30 ~ ServiceModal ~ getData:",
  //   getData
  // );
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showLoader, setShowLoader] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const handleCloseSubscriptionModal = () => {
    setOpenSubscriptionModal(false);
  };

  const onSubmitPlan = async () => {
    const localStorageData = getItem("apex-saas_details");

    setShowLoader(true);
    const resp = await dispatch(
      updatePlan({ oldId: currentPlan?._id, newId: newPlan?._id })
    );
    if (resp) {
      let allLocalData = localStorageData;
      let data = [];
      if (newPlan?.nameOfProduct == "Mobile") data.push("mobile");
      if (newPlan?.nameOfProduct == "Web") data.push("web");
      if (newPlan?.nameOfProduct == "Combined") data = ["web", "mobile"];
      allLocalData.allowedPlatforms = data;

      setItem("apex-saas_details", allLocalData);
      window.dispatchEvent(new Event("localStorageChangePlan"));
    }
    setShowLoader(false);
    handleCloseSubscriptionModal();
    fetchCurrentPlan();
    setOpenSuccessModal(true);
    setSuccessMessage("Subscription updated");
    navigate("/account-settings/billing");
  };

  return (
    <Box>
      <Modal
        open={openSubscriptionModal}
        onClose={handleCloseSubscriptionModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmitPlan)}>
          <Box
            sx={{
              ...style,
              width: "600px",
              border: "none",
              borderRadius: "8px 8px 0px 0px",
              outline: "none",
            }}
          >
            <AppBar
              color="secondary"
              variant="elevation"
              sx={{
                height: "64px",
                paddingRight: "0px !important",
                borderRadius: "8px 8px 0px 0px",
                padding: 0,
                margin: 0,
                paddingLeft: "-16px !important",
              }}
            >
              <Toolbar variant="regular">
                <Typography variant="h6" width={"98%"} color={"white"}>
                  Change Subscription Plan
                </Typography>
                <Stack direction={"row"} spacing={0}>
                  <div
                    onClick={() => {
                      handleCloseSubscriptionModal();
                    }}
                    style={{ cursor: "pointer", height: "32px" }}
                  >
                    <CloseIcon sx={{ fill: "white", fontSize: 32 }} />
                  </div>
                </Stack>
              </Toolbar>
            </AppBar>
            <Container
              gap={2}
              disableGutters
              style={{
                scrollbarColor: "blue",
                scrollbarWidth: "2px",

                marginTop: "72px",
              }}
            >
              <Grid
                container
                spacing={2}
                maxHeight={"85vh"}
                style={{ scrollbarColor: "blue", scrollbarWidth: "thin" }}
              >
                <Grid item lg={12}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    Current Plan
                  </Typography>
                  <Typography>
                    {currentPlan?.subscriptionPrice?.nameOfProduct == "Web" &&
                      "Web"}{" "}
                    {currentPlan?.subscriptionPrice?.nameOfProduct ==
                      "Mobile" && "Mobile"}{" "}
                    {currentPlan?.subscriptionPrice?.nameOfProduct ==
                      "Combined" && "Premium"}
                  </Typography>
                  <Typography>
                    ${currentPlan?.subscriptionPrice?.unitAmountInDollars}
                    {currentPlan?.subscriptionPrice?.interval
                      ? "/" + currentPlan?.subscriptionPrice?.interval
                      : ""}
                  </Typography>
                </Grid>

                <Grid item lg={12}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    New Plan
                  </Typography>
                  <Typography>
                    {newPlan?.nameOfProduct == "Combined"
                      ? "Premium"
                      : newPlan?.nameOfProduct + " Only"}
                    {/* {newPlan?.type} */}
                  </Typography>
                  <Typography>
                    ${newPlan?.unitAmountInDollars?.toLocaleString()}
                    {"/"}
                    {newPlan?.interval}
                  </Typography>
                </Grid>
                <Grid item lg={12}>
                  <Typography
                    bgcolor={"rgba(241, 241, 241, 1)"}
                    paddingInline={1.6}
                    pt={1}
                    pb={1}
                    borderRadius={2}
                  >
                    New plan starts on{" "}
                    <span style={{ fontWeight: 500 }}>
                      {dayjs().format("MMMM DD, YYYY")}
                    </span>
                    .
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  display={"flex"}
                  justifyContent={"end"}
                  mt={"8px"}

                  // style={{ marginTop: "20px" }}
                >
                  {/* <Grid item></Grid> */}

                  <Grid item>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleCloseSubscriptionModal();
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        CONFIRM CHANGES
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </form>
      </Modal>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
      />
    </Box>
  );
};

export default ChangeSubscriptionModal;
