import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SendFeedbackModal from "./SendFeedbackModal";
import ServiceModal from "./SendFeedbackModal";
import { useDispatch } from "react-redux";
import { handlelogout } from "../../redux/Auth/action";
import { getItem } from "../../utility/localStorage";
import { imageUrl } from "../../config/dataService/dataService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function ShopPopup(props) {
  const navigate = useNavigate();
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [image, setImage] = useState(
    JSON.parse(localStorage.getItem("apex-saas_details"))?.images?.find(
      (d) => d.type == "primary"
    )
  );
  const logout = async () => {
    setShowLoader(true);
    const resp = await dispatch(handlelogout());

    if (resp) {
      const location = window.location.pathname
        .split("/")
        .includes("apex-saas");
      window.location.replace(location ? "/apex-saas/" : "/");
    }
    setShowLoader(false);
  };

  const [name, setName] = useState(
    JSON.parse(localStorage.getItem("apex-saas_details"))?.shopName?.split(" ")
  );

  const [shopDetails, setShopDetails] = useState(
    JSON.parse(localStorage.getItem("apex-saas_details"))
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      setImage(
        JSON.parse(localStorage.getItem("apex-saas_details"))?.images?.find(
          (d) => d.type == "primary"
        )
      );
    };

    window.addEventListener("localStorageChange", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageChange", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      setShopDetails(JSON.parse(localStorage.getItem("apex-saas_details")));
    };

    window.addEventListener("localStorageChangeShopName", handleStorageChange);

    return () => {
      window.removeEventListener(
        "localStorageChangeShopName",
        handleStorageChange
      );
    };
  }, []);

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            //   {...bindTrigger(popupState)}
          >
            <div {...bindTrigger(popupState)}>
              <Avatar
                alt="Avatar"
                variant="circular"
                sx={{
                  marginRight: "15px",
                  height: "48px",
                  width: "48px",
                  borderRadius: "4px",
                  background: "#002394",
                }}
                src={imageUrl + image?.url}
              >
                {name?.[0]?.[0] + name?.[1]?.[0]}
              </Avatar>
            </div>
            <div {...bindTrigger(popupState)}>
              <Typography
                variant="contained"
                // {...bindTrigger(popupState)}
              >
                {/* {getItem("apex-saas_details")?.shopName
                  ? getItem("apex-saas_details")?.shopName
                  : "Auto Repair Shop Name"} */}
                <ExpandMoreIcon style={{ color: "rgba(142, 142, 142, 1)" }} />
              </Typography>
            </div>
            <Menu
              {...bindMenu(popupState)}
              sx={{ mt: "20px", minWidth: "256px" }}
            >
              <MenuItem style={{ background: "white" }}>
                <Avatar
                  alt="Avatar"
                  variant="circular"
                  sx={{
                    marginRight: "15px",
                    height: "48px",
                    width: "48px",
                    borderRadius: "4px",
                  }}
                  src={imageUrl + image?.url}
                >
                  {name?.[0]?.[0] + name?.[1]?.[0]}
                </Avatar>

                {shopDetails?.shopName ?? "Auto Repair Shop Name"}
                {/* {   getItem("apex-saas_details")?.shopName
                  ? getItem("apex-saas_details")?.shopName
                  : "Auto Repair Shop Name"} */}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  popupState.close();
                  navigate("/account-settings/personal-information");
                }}
              >
                Account Settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  setOpenFeedbackModal(true);
                }}
              >
                Send Feedback
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  navigate("referrals");
                }}
              >
                Invite Customers
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  popupState.close();
                }}
              >
                <Link
                  to={"https://apexmechanic.com/privacy-policy/"}
                  target="_blank"
                  style={{ textDecoration: "none", color: "rgba(0,0,0,0.87)" }}
                >
                  Privacy Policy and Terms
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  logout();
                }}
              >
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        )}
      </PopupState>
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <ServiceModal
        open={openFeedbackModal}
        handleClose={() => setOpenFeedbackModal(false)}
      />

      {/* <SendFeedbackModal
        open={openFeedbackModal}
        setOpen={setOpenFeedbackModal}
        /> */}
    </>
  );
}

export default ShopPopup;
