const API = {
  auth: {
    login: "mechanic/loginSaas",
    register: "mechanic/register",
    logout: "user/logout",
    forgetpass: "user/forgotPassword",
    verifyotp: "user/emailCodeVerification",
    resetpassword: "user/resetPasswordForSaas",
    shopRequest: "mechanic/register",
    changePassword: "user/changePasswordForSaas",
    passwordUpdate: "user/passwordUpdatedAt",
    setFirstPassword: "user/setPasswordSaas/",
  },
  account: {
    personal: {
      getPersonalDetail: "user/shopOwnerDetail",
      updatePersonalDetail: "user/updatePersonalInfo",
      getThankyouDetail: "shop/thankyou-email-settings",
      updateThankyouDetail: "shop/update-thankyou-email-settings",
      sendThankyouDetail: "shop/test-thankyou-email-settings",
    },
    shopDetails: {
      getshopDetails: "shop/shopDetails",
      updateShopDetails: "shop/shopImages",
      updateShopCertificates: "shop/shopCertifications",
      shopBasicsUpdate: "shop/shopBasicsUpdate",
      updateShopPrice: "shop/default-hourly-rate",
      getUpdateShopPrice: "shop/default-hourly-rate",
    },
    shopImages: {
      get: "shop/get-images",
      update: "shop/add-images",
    },
    cancellationPolicy: {
      getpolicy: "cancellationPolicy/list",
      addPolicy: "cancellationPolicy/add",
      updatePolicy: "cancellationPolicy/update",
      deletePolicy: "cancellationPolicy/delete",
    },
    Estimates: {
      estimateFee: "shop/updateEstimateFees",
    },
    termsAndWarrenty: {
      getTermsAndWarrenty: "shop/termsAndWarranty",
      addTermsAndWarrenty: "shop/add-term-and-warranty",
      updateTermsAndWarrenty: "shop/edit-term-and-warranty",
      deleteTermsAndWarrenty: "shop/delete-term-and-warranty",
    },
    reschedulingPolicy: {
      getList: "reschedulingPolicy/list",
      getById: "reschedulingPolicy/get",
      update: "reschedulingPolicy/update",
      add: "reschedulingPolicy/add",
      delete: "reschedulingPolicy/delete",
    },
    Amenities: {
      putAmenities: "shop/updateShopAmenities",
      getAmenities: "amenities/list",
    },

    shopHours: {
      getTimeZone: "shop/get-timezone",
      ontarioHolidays: {
        getOntarioHolidays: "shop/ontarioListing",
      },
    },
    shopHours: {
      getTimeZone: "shop/get-timezone",
      getShopHours: "/shop/shopHours",
      ontarioHolidays: {
        getOntarioHolidays: "/shopHolidays/ontarioListing",
        updateOntarioHolidays: "/shopholidays/updateOntarioHolidays",
      },
      customHolidays: {
        getCustomHolidays: "/shopHolidays/customHolidayListing",
        updateCustomHolidays: "/shopHolidays/update",
        addCustomHolidays: "/shopHolidays/addCustomHoliday",
        deleteCustomHolidays: "/shopHolidays/delete",
      },
      businessHours: {
        updateBusinessHours: "/shop/updateBusinessHours",
      },
      operationalHours: {
        updateOperationHours: "shop/shopOperationalHours",
      },
    },
    shopServices: {
      getServiceCategory: "serviceCategory/list",
      shopServicesListing: "shopServices/shopServices",
      getServiceSubCategory: "serviceSubCategory/list",
      addShopService: "shopServices/addShopServices",
      updateShopService: "shopServices/updateShopServices",
      updateShopServiceStatus: "shopServices/updateShowShopService",
      deleteService: "shopServices/deleteShopServices",
      getShopBasedCategory: "shopServices/ShopServiceCategory",
    },
    notificationManage: {
      getNotificationStatus: "notification/getNotificationStatus",
      updateNotificationStatus: "user/update-preference",
    },
    billing: {
      getBillingHistory: "subscriptionInvoice/list",
      getPlans: "subscriptionPriceMaster/list",
      getCurrentPlan: "subscriptionUserMaster/getByShop/",
      upgradePlan: "subscriptionUserMaster/update/",
      addCard: "paymentMethod/add",
      updatecard: "subscriptionUserMaster/changePaymentMethod",
    },
  },

  notification: {
    notificationList: "notification/list",
    markAsRead: "notification/markAsRead",
    markAsReadById: "notification/markRead/",
  },
  dashboard: {
    dashboardData: "dashboard",
    requestEstimateListing: "estimateRequest/EstimateRequest",
    requestEstimateDetail: "estimateRequest/getEstimateRequest",
    requestApponitmentDetail: "appointment/appointmentRequestDetail",
    requestAppointmentListing: "appointment/appointment-requests",
    acceptDeclineRequest: "estimate/estimateStatusManage",
    acceptDeclineAppointmentRequest: "appointment/respondToRequestForSaas",
    updateEstimatePickUp: "appointment/updatePickupTime",
    changeAssignee: "appointment/assignee/",
  },

  vehicle: {
    getVehicleListByUserID: "vehicle/list",
    getVehicleYearList: "vehicle/vehicleYear",
    getVehicleMakeList: "vehicleMake/list",
    getVehicleModelList: "vehicleModel/list",
  },

  customer: {
    getCustomerList: "shopCustomer/list",
    list: "mechanic/customerList",
    detail: "user/customerDetail/",
    vehicles: "vehicle/list/",
    appointments: "appointment/appointmentList",
    vehicleYear: "vehicle/vehicleYear",
    vehicleMake: "vehicleMake/list",
    vehicleModel: "vehicleModel/list",
    vehicleTrim: "vehicleModelTrim/list",
    notesList: "notes/list",
    addNote: "notes/addNotesForCustomer",
    addVehicle: "vehicle/addVehicle",
    importedVehicleAdded: "vehicle/imported-vehicle-create",
    create: "mechanic/createCustomer",
    edit: "user/updateCustomer/",
    delete: "user/deleteUser/",
    editVehicle: "vehicle/updateVehicle/",
    deleteVehicle: "vehicle/deleteVehicle/",
    editNote: "notes/editNotes/",
    deleteNote: "notes/delete/",
    decodeVIN: "user/decode/",
    allCustomerList: "mechanic/dbCustomer",
    customerListWithType: "user/getCustomerList",
    mergeCustomerList: "user/listForMergeCustomer",
    shopImportedCreate: "user/create-customer-from-imported",
    mergeCustomerById: "user/getImportCustomer/",
    importCustomerList: "user/imported-customer-list",
    mergeCustomers: "user/mergeCustomer",
    removeMergeCustomer: "user/remove-from-import/",
    checkPhoneNumber: "user/checkPhoneNumber",
    updatePhoneCustomer: "user/updateCustomerDetails/",
    importCustomerDetail: "user/getImportCustomer/",
    dismissCustomer: "user/removeFromImport",
    customerDetilsWithType: "user/getImportCustomer",
    cityList: "cityMaster/listOfCity",
    provinceList: "provinceMaster/listOfProvince",
    countryList: "countryMaster/listOfCountry",
    editImportedVehicle: "vehicle/imported-vehicle-update/",
    deleteImportedVehicle: "vehicle/imported-vehicle-delete/",
  },

  estimate: {
    estimateList: "estimate/estimateListSaas",
    estimateDetail: "estimate/estimateDetail",
    servicesCategory: "shopServices/ShopServiceCategory",
    servicesSubCategory: "shopServices/ShopServiceSubCategory",
    termsAndWarranty: "shop/termsAndWarranty",
    createEstimate: "estimate/createEstimate",
    createDraftEstimate: "estimate/createEstimateAsDraft",
    deleteDraft: "estimate/deleteDraftEstimate",
    sentEstimateDetails: "estimate/sendEstimatePdf",
    getEstimateRequestDetails: "estimateRequest/getEstimateRequest",
  },

  invoice: {
    invoiceList: "invoice/listing",
    vehicleList: "vehicle/list",
    invoiceDetails: "invoice/invoiceDetail",
    create: "invoice/addInvoice",
    customerList: "shopCustomer/list",
    createDraft: "invoice/draftInvoice",
    deleteDraft: "invoice/deleteDraftInvoice",
    getCustomerList: "mechanic/shopCustomerWithAppointment",
    sentInvoiceDetails: "invoice/sendInvoicePdf",
    downloadReport: "invoice/export-invoice",
  },

  approvals: {
    getApprovalsListing: "appointment/approvalList",
    getApprovalDetails: "appointment/approvalDetail",
    createApproval: "appointment/createApprovalForSaas",
    currentApproval: "appointment/approvalDetail",
  },
  feedbackSubmit: {
    submitFeedback: "feedback/send",
  },

  appointment: {
    kanbanBoardList: "appointment/appointmentListKanbanBoard",
    updateAppointmentStatus: "appointment/updateAppointmentStatus",
    appointmentList: "appointment/listForAppointment",
    appointmentDetails: "appointment/detail",
    rescheduleAppointment: "appointment/rescheduleAppointment",
    cancleAppointment: "appointment/cancelAppointment",
    createAppointment: "appointment/createAppointmentFromSaas",
    mechanicDetails: "appointment/getAssignList",
    proposeDateAndTime: "appointment/propose-new-time-saas",
    rescheduleRequestAcceptDecline: "/appointment/reschedule-accept-decline",
    shopClosingTime: "shop/shopClosingTime",
  },
  calendar: {
    listing: "calendar/list-for-saas",
    updateStatus: "shopUnavailability/add-for-saas",
    availablityStatus: "shopUnavailability/list-for-saas",
    getSetting: "calendarSetting/list",
    addSetting: "calendarSetting/add",
    editSetting: "calendarSetting/update/",
    deleteSetting: "calendarSetting/delete/",
    getAppointmentsStatus: "calendar/view-preferance",
  },
  cometChat: {
    create: "user/createChatUser",
  },
  pauseAppointment: {
    get: "shop/getPauseAppointment",
    update: "shop/pauseAppointment",
  },
  workOrderAuthorization: {
    create: "work-order-authorization",
    detail: "work-order-authorization/?workorderauthorizationid=",
    updateStatus: "work-order-authorization/change-status/",
    history: "work-order-authorization/history/",
  },
};

export { API };
