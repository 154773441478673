import React, { useEffect, useState, useMemo } from "react";
import "./ShopHours.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Bolt, Girl } from "@mui/icons-material";
import { Modal, Tooltip } from "@mui/material";
import Switch from "@mui/material/Switch";
import dustbin from "../../../assets/DeleteFilled.png";
import {
  AppBar,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  getShopHours,
  getTimeZones,
  updateOperationalHours,
} from "../../../redux/Account/shopHours/action";
import { useDispatch, useSelector } from "react-redux";
import BusinessHours from "./BusinessHours";
import OntarioHoliday from "./OntarioHoliday";
import CustomHolidays from "./CustomHolidays";
import { useTheme } from "@emotion/react";
import SuccessMessageModal from "../../Common/SuccessMessage";
import { getItem } from "../../../utility/localStorage";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ShopHours = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getTimeZoneRedux = useSelector((state) => state?.account?.getTimeZone);

  const [showLoader, setShowLoader] = useState(false);
  const [tooltipMsg, setTooltipMsg] = useState();

  const [BusinessHourData, setBusinessHourData] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [typeMessage, setTypeMessage] = useState("");
  const [enable, setEnable] = useState(false);

  // const getTimeZone = async () => {
  //   setShowLoader(true);
  //   const data = await dispatch(getTimeZones());
  //   if (data) {
  //     setShowLoader(false);
  //   }
  // };

  const getAvailability = useSelector(
    (state) => state?.account?.getShopHours?.operationHours
  );
  const [manageCustomData, setManageCustomData] = useState([]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getBusinessHoursData = useSelector(
    (state) => state?.account?.getShopHours?.businessHours
  );

  useEffect(() => {
    getShopHourData();
  }, []);

  const getShopHourData = async () => {
    setShowLoader(true);
    let result = await dispatch(getShopHours());
    if (result) {
      result?.operationHours?.map((item, index) => {
        item?.openHours?.map((data, idx) => {
          data.facingIssuesInStartTime = false;
          data.facingIssuesInEndTime = false;
        });
      });
      setManageCustomData(result.operationHours);
    }

    setShowLoader(false);
  };

  const AddClicked = (item, index) => {
    let newData = [...manageCustomData];
    let openHoursArr = newData[index]?.openHours;
    let splittedArrForstartTime =
      openHoursArr[openHoursArr?.length - 1]?.endTime.split(":");
    let splittedArrForendTime = BusinessHourData[index]?.endTime.split(":");

    if (
      splittedArrForstartTime[0] == splittedArrForendTime[0] ||
      (splittedArrForendTime[1] == "30" &&
        +splittedArrForstartTime[0] + 1 == splittedArrForendTime[0] &&
        splittedArrForstartTime[1] == "30") ||
      (splittedArrForendTime[1] == "0" &&
        +splittedArrForstartTime[0] + 1 == splittedArrForendTime[0])
    ) {
      setTypeMessage("error");
      setOpenSuccessModal(true);
      setSuccessMessage(
        "You can't add because end time of operation hour is less than 1 hour of business hour."
      );
    } else {
      openHoursArr.push({
        startTime:
          splittedArrForstartTime[1] == "0"
            ? parseInt(splittedArrForstartTime[0]) +
              ":" +
              parseInt(parseInt(splittedArrForstartTime[1]) + 30)
            : parseInt(splittedArrForstartTime[0]) + 1 + ":" + parseInt("00"),
        // parseInt(splittedArrForstartTime[0]) +
        // 1 +
        // ":" +
        // splittedArrForstartTime[1],
        endTime:
          parseInt(splittedArrForendTime[0]) -
          1 +
          ":" +
          splittedArrForendTime[1],
        facingIssuesInStartTime: false,
        facingIssuesInEndTime: false,
      });

      setManageCustomData(newData);
    }
  };

  const deleteClicked = (index, hoursIndex) => {
    // let removedOne = delete manageCustomData[index].openHours[hoursIndex];
    const newData = [...manageCustomData];
    newData[index].openHours.splice(hoursIndex, 1);
    // manageCustomData[index].openHours = data;
    setManageCustomData(newData);
  };

  const submitOperationHours = async () => {
    const payload = {};
    setShowLoader(true);
    for (
      let outerIndex = 0;
      outerIndex < manageCustomData.length;
      outerIndex++
    ) {
      payload[`operationHours[${outerIndex}][day]`] =
        manageCustomData[outerIndex].day;
      payload[`operationHours[${outerIndex}][startTime]`] =
        manageCustomData[outerIndex].startTime;
      payload[`operationHours[${outerIndex}][endTime]`] =
        manageCustomData[outerIndex].endTime;
      payload[`operationHours[${outerIndex}][isEnabled]`] =
        manageCustomData[outerIndex].isEnabled;
      if (
        manageCustomData[outerIndex].openHours &&
        manageCustomData[outerIndex].openHours.length > 0
      ) {
        for (
          let innerIndex = 0;
          innerIndex < manageCustomData[outerIndex].openHours.length;
          innerIndex++
        ) {
          payload[
            `operationHours[${outerIndex}][openHours][${innerIndex}][startTime]`
          ] = manageCustomData[outerIndex].openHours[innerIndex].startTime;
          payload[
            `operationHours[${outerIndex}][openHours][${innerIndex}][endTime]`
          ] = manageCustomData[outerIndex].openHours[innerIndex].endTime;
        }
      }
    }
    let res = await dispatch(updateOperationalHours(payload));
    if (res) {
      setTypeMessage("success");

      setOpenSuccessModal(true);
      setSuccessMessage("Changes Saved");
    }
    setShowLoader(false);
  };

  const BusinessHoursData = (data) => {
    setBusinessHourData(data);
  };

  const disbaledTime = (value, view, index) =>
    (view === "hours" &&
      (value.hour() < BusinessHourData[index]?.startTime?.split(":")[0] ||
        value.hour() + 1 > BusinessHourData[index]?.endTime?.split(":")[0])) ||
    (view === "minutes" &&
      ((value.hour() == BusinessHourData[index]?.startTime?.split(":")[0] &&
        value.minute() < BusinessHourData[index]?.startTime?.split(":")[1]) ||
        (value.hour() + 1 == BusinessHourData[index]?.endTime?.split(":")[0] &&
          value.minute() > BusinessHourData[index]?.endTime?.split(":")[1])));

  const disbaledTimeotherIndex = (value, view, index, hrIndex) =>
    (view === "hours" &&
      ((value.minute() == 0 &&
        value.hour() ==
          manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
            ":"
          )[0]) ||
        value.hour() <
          manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
            ":"
          )[0] ||
        value.hour() > BusinessHourData[index]?.endTime?.split(":")[0])) ||
    (view === "minutes" &&
      value.hour() ==
        manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
          ":"
        )[0] &&
      value.minute() - 30 <
        manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
          ":"
        )[1]) ||
    //start hour comparison
    (value.hour() == BusinessHourData[index]?.endTime?.split(":")[0] &&
      value.minute() > BusinessHourData[index]?.endTime?.split(":")[1]);

  const disbaledTimeotherIndexForEndTime = (value, view, index, hrIndex) =>
    (view === "hours" &&
      value.hour() <
        manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
          ":"
        )[0]) ||
    value.hour() > BusinessHourData[index]?.endTime?.split(":")[0] ||
    (view === "minutes" &&
      value.hour() == BusinessHourData[index]?.endTime?.split(":")[0] &&
      BusinessHourData[index]?.endTime?.split(":")[1] == "30" &&
      value.minute() + 30 > BusinessHourData[index]?.endTime?.split(":")[1]) ||
    (value.hour() == BusinessHourData[index]?.endTime?.split(":")[0] &&
      BusinessHourData[index]?.endTime?.split(":")[1] == 0 &&
      value.hour > BusinessHourData[index]?.endTime?.split(":")[0]) ||
    //end hour comparison
    (value.hour() ==
      manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(":")[0] &&
      value.minute() <
        manageCustomData[index]?.openHours[hrIndex - 1]?.endTime?.split(
          ":"
        )[1]) ||
    //start hour comparison
    (value.hour() == BusinessHourData[index]?.endTime?.split(":")[0] &&
      value.minute() > BusinessHourData[index]?.endTime?.split(":")[1]) ||
    //same hour comparison
    (value.hour() ==
      manageCustomData[index]?.openHours[hrIndex]?.startTime?.split(":")[0] &&
      value.minute() <
        manageCustomData[index]?.openHours[hrIndex]?.startTime?.split(":")[1]);

  return (
    <div style={{ marginTop: "32px" }}>
      <div className="hours_top_section">
        <Grid xl={12} lg={12}>
          <Box
            sx={{ width: "100%", height: "100%", bgcolor: "background.main" }}
            boxShadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}
            borderRadius={"8px"}
            className="padding-box-customer-view"
          >
            <div className="customer_view">
              <div className="timezone">
                Timezone
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  required
                  fullWidth
                  disabled
                  value={getItem("apex-saas_details")?.timezone}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-disabled fieldset": {
                        backgroundColor: "background.disabled_fields",
                        border: 0,
                      },
                    },
                  }}
                />
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className="btn-text-size-manage"
                  color="secondary"
                  variant="contained"
                >
                  CUSTOMER'S VIEW
                </Button>
              </div> */}
            </div>
          </Box>
        </Grid>
      </div>

      <Grid container spacing={3}>
        <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        >
          <BusinessHours
            BusinessHoursData={BusinessHoursData}
            getShopHourData={getShopHourData}
            setSubmitDisabled={setSubmitDisabled}
            submitDisabled={submitDisabled}
            setManageCustomData={setManageCustomData}
            manageCustomData={manageCustomData}
          />
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <div className="availability_container">
              <Typography
                variant="body1"
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "32px",
                }}
              >
                Availability
              </Typography>
              <div className="availability-styling"> 
              <TableContainer component={Paper} className="table_bussines">
                <Table sx={{ minWidth: "400px" }} aria-label="simple table">
                  {Array.isArray(BusinessHourData) && (
                    <TableBody className="table_timing">
                      {manageCustomData?.map((row, index) => (
                        <>
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            className="available-hour-style align-item-center"
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="body">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "140px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <Checkbox
                                      {...label}
                                      disabled={
                                        Array.isArray(BusinessHourData) &&
                                        BusinessHourData.length > 0 &&
                                        !BusinessHourData[index]?.isEnabled
                                      }
                                      // checked={
                                      //   BusinessHourData[index]?.isEnabled ==
                                      //   false
                                      //     ? BusinessHourData[index]?.isEnabled
                                      //     : manageCustomData[index].isEnabled
                                      // }
                                      // value={
                                      //   BusinessHourData[index]?.isEnabled ==
                                      //   false
                                      //     ? BusinessHourData[index]?.isEnabled
                                      //     : manageCustomData[index].isEnabled
                                      // }
                                      checked={
                                        manageCustomData[index].isEnabled
                                      }
                                      value={manageCustomData[index].isEnabled}
                                      color="primary"
                                      onChange={() => {
                                        let oldData = [...manageCustomData];
                                        oldData[index].isEnabled =
                                          !oldData[index].isEnabled;
                                        setManageCustomData(oldData);
                                      }}
                                    />
                                    {row?.day}
                                  </div>
                                  {Array.isArray(manageCustomData) &&
                                    manageCustomData.length > 0 &&
                                    manageCustomData[index]?.isEnabled && (
                                      <div
                                        style={{
                                          color: "#006CDD",
                                          fontSize: "13px",
                                          fontWeight: "500",
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "20px",
                                          lineHeight:"22px",
                                          letterSpacing:"0.46px",
                                          padding:'4px 5px'
                                        }}
                                        onClick={() => {
                                          AddClicked(row, index);
                                        }}
                                      >
                                        <AddIcon
                                          style={{ width: "15px" }}
                                          color="primary"
                                        />
                                        Add
                                      </div>
                                    )}
                                </div>
                              </Typography>
                            </TableCell>
                            <div className="width-100-per">
                              {row.openHours.length > 0 &&
                              Array.isArray(manageCustomData) &&
                              manageCustomData.length > 0 &&
                              manageCustomData[index]?.isEnabled ? (
                                row.openHours?.map((hourData, hourIndex) => {
                                  return (
                                    // <TableRow>
                                    <div>
                                      <TableCell
                                        align="center"
                                        style={{ fontSize: "16px" }}
                                      >
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          {hourData?.facingIssuesInStartTime ? (
                                            <Tooltip
                                              title={
                                                <span className="time-tooltip-text">
                                                  Warning:
                                                  <br />
                                                  {tooltipMsg ==
                                                  "shouldDisableTime-hours"
                                                    ? "Selected time should be in range."
                                                    : tooltipMsg}
                                                </span>
                                              }
                                              className="time-tooltip"
                                              arrow
                                              placement="top"
                                            >
                                              <Stack spacing={2}>
                                                <TimePicker
                                                  /*label="Opening"*/

                                                  value={
                                                    dayjs(
                                                      `2022-04-17T${hourData.startTime}`
                                                    ).isValid()
                                                      ? dayjs(
                                                          `2022-04-17T${hourData.startTime}`
                                                        )
                                                      : null
                                                  }
                                                  onChange={(newValue) => {
                                                    const updatedString =
                                                      newValue.$H +
                                                      ":" +
                                                      newValue.$m;

                                                    let newData = [
                                                      ...manageCustomData,
                                                    ];
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].startTime = updatedString;
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].facingIssuesInStartTime = false;
                                                    setManageCustomData(
                                                      newData
                                                    );
                                                  }}
                                                  onError={(newError) => {
                                                    let newData = [
                                                      ...manageCustomData,
                                                    ];
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].facingIssuesInStartTime = true;
                                                    setManageCustomData(
                                                      newData
                                                    );
                                                    setTooltipMsg(newError);
                                                  }}
                                                  timeSteps={{ minutes: 30 }}
                                                  shouldDisableTime={(v, e) =>
                                                    hourIndex == 0
                                                      ? disbaledTime(
                                                          v,
                                                          e,
                                                          index
                                                        )
                                                      : disbaledTimeotherIndex(
                                                          v,
                                                          e,
                                                          index,
                                                          hourIndex
                                                        )
                                                  }
                                                />
                                              </Stack>
                                            </Tooltip>
                                          ) : (
                                            <Stack spacing={2}>
                                              <TimePicker
                                                /*label="Opening"*/

                                                value={
                                                  dayjs(
                                                    `2022-04-17T${hourData.startTime}`
                                                  ).isValid()
                                                    ? dayjs(
                                                        `2022-04-17T${hourData.startTime}`
                                                      )
                                                    : null
                                                }
                                                onChange={(newValue) => {
                                                  const updatedString =
                                                    newValue.$H +
                                                    ":" +
                                                    newValue.$m;

                                                  let newData = [
                                                    ...manageCustomData,
                                                  ];
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].startTime = updatedString;
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].facingIssuesInStartTime = false;
                                                  setManageCustomData(newData);
                                                }}
                                                onError={(newError) => {
                                                  let newData = [
                                                    ...manageCustomData,
                                                  ];
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].facingIssuesInStartTime = true;
                                                  setManageCustomData(newData);
                                                  setTooltipMsg(newError);
                                                }}
                                                timeSteps={{ minutes: 30 }}
                                                shouldDisableTime={(v, e) =>
                                                  hourIndex == 0
                                                    ? disbaledTime(v, e, index)
                                                    : disbaledTimeotherIndex(
                                                        v,
                                                        e,
                                                        index,
                                                        hourIndex
                                                      )
                                                }
                                              />
                                            </Stack>
                                          )}
                                        </LocalizationProvider>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography>to</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          {hourData?.facingIssuesInEndTime ? (
                                            <Tooltip
                                              title={
                                                <span className="time-tooltip-text">
                                                  Warning:
                                                  <br />
                                                  {tooltipMsg ==
                                                  "shouldDisableTime-hours"
                                                    ? "Selected time should be in range."
                                                    : tooltipMsg}
                                                </span>
                                              }
                                              className="time-tooltip"
                                              arrow
                                              placement="top"
                                            >
                                              <Stack spacing={2}>
                                                <TimePicker
                                                  /*label="Closing"*/
                                                  timeSteps={{ minutes: 30 }}
                                                  value={
                                                    dayjs(
                                                      `2022-04-17T${hourData.endTime}`
                                                    ).isValid()
                                                      ? dayjs(
                                                          `2022-04-17T${hourData.endTime}`
                                                        )
                                                      : null
                                                    // dayjs(`2022-04-17T22:22`)
                                                  }
                                                  onChange={(newValue) => {
                                                    const updatedString =
                                                      newValue.$H +
                                                      ":" +
                                                      newValue.$m;

                                                    let newData = [
                                                      ...manageCustomData,
                                                    ];
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].endTime = updatedString;
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].facingIssuesInEndTime = false;
                                                    setManageCustomData(
                                                      newData
                                                    );
                                                    // setCancelClicked(false);d
                                                  }}
                                                  onError={(newError) => {
                                                    let newData = [
                                                      ...manageCustomData,
                                                    ];
                                                    newData[index].openHours[
                                                      hourIndex
                                                    ].facingIssuesInEndTime = true;
                                                    setManageCustomData(
                                                      newData
                                                    );
                                                    setTooltipMsg(newError);
                                                  }}
                                                  shouldDisableTime={(v, e) =>
                                                    hourIndex == 0
                                                      ? disbaledTime(
                                                          v,
                                                          e,
                                                          index
                                                        )
                                                      : disbaledTimeotherIndexForEndTime(
                                                          v,
                                                          e,
                                                          index,
                                                          hourIndex
                                                        )
                                                  }
                                                />
                                              </Stack>
                                            </Tooltip>
                                          ) : (
                                            <Stack spacing={2}>
                                              <TimePicker
                                                /*label="Closing"*/
                                                timeSteps={{ minutes: 30 }}
                                                value={
                                                  dayjs(
                                                    `2022-04-17T${hourData.endTime}`
                                                  ).isValid()
                                                    ? dayjs(
                                                        `2022-04-17T${hourData.endTime}`
                                                      )
                                                    : null
                                                  // dayjs(`2022-04-17T22:22`)
                                                }
                                                onChange={(newValue) => {
                                                  const updatedString =
                                                    newValue.$H +
                                                    ":" +
                                                    newValue.$m;

                                                  let newData = [
                                                    ...manageCustomData,
                                                  ];
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].endTime = updatedString;
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].facingIssuesInEndTime = false;
                                                  setManageCustomData(newData);
                                                  // setCancelClicked(false);d
                                                }}
                                                onError={(newError) => {
                                                  let newData = [
                                                    ...manageCustomData,
                                                  ];
                                                  newData[index].openHours[
                                                    hourIndex
                                                  ].facingIssuesInEndTime = true;
                                                  setManageCustomData(newData);
                                                  setTooltipMsg(newError);
                                                }}
                                                shouldDisableTime={(v, e) =>
                                                  hourIndex == 0
                                                    ? disbaledTime(v, e, index)
                                                    : disbaledTimeotherIndexForEndTime(
                                                        v,
                                                        e,
                                                        index,
                                                        hourIndex
                                                      )
                                                }
                                              />
                                            </Stack>
                                          )}
                                        </LocalizationProvider>
                                      </TableCell>
                                      {hourIndex != 0 ? (
                                        <TableCell
                                          align="center"
                                          onClick={() => {
                                            deleteClicked(index, hourIndex);
                                          }}
                                          className="img-col"
                                        >
                                          {row.Action}
                                          <img
                                            src={dustbin}
                                            alt=".."
                                            className="dustbin-img"
                                          />
                                        </TableCell>
                                      ) : (
                                        <TableCell
                                          align="center"
                                          className="img-col"
                                        >
                                          <div className="empty-div-for-shop-hour"></div>
                                        </TableCell>
                                      )}
                                    </div>
                                    // </TableRow>
                                  );
                                })
                              ) : (
                                <div className="unavailable-Text">
                                  Unavailable for appointments
                                </div>
                              )}
                            </div>
                          </TableRow>
                          {index != manageCustomData.length - 1 && (
                            <Divider
                              style={{
                                margin: "12px 5px",
                              }}
                            />
                          )}{" "}
                        </>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              </div>
                  <Stack spacing={2} direction="row" justifyContent={'flex-end'}>
                  <Button
                    className="btn-text-size-manage"
                    variant="outlined"
                    color="primary"
                    onClick={getShopHourData}
                  >
                    CANCEL CHANGES
                  </Button>
                  <Button
                    className="btn-text-size-manage"
                    variant="contained"
                    color="primary"
                    disabled={submitDisabled}
                    onClick={submitOperationHours}
                  >
                    SAVE CHANGES
                  </Button>
                </Stack>
          </div>
        </Grid>
      </Grid>
      <OntarioHoliday />
      <CustomHolidays />
      <Dialog fullScreen={fullScreen} open={showLoader}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <SuccessMessageModal
        message={successMessage}
        open={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        alertType={typeMessage}
      />
    </div>
  );
};

export default ShopHours;
