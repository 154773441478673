import { Alert, Snackbar } from "@mui/material";
import React from "react";

function SuccessMessageModal(props) {
  // console.log("props", props);
  return (
    <Snackbar
      open={props?.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message="I love snacks"
      key={"top" + "center"}
      onClose={() => props.setOpenSuccessModal(false)}
      autoHideDuration={2000}
    >
      <Alert severity={props.alertType == "error" ? "error" : "success"}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

export default SuccessMessageModal;
