import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import NotificationsIcon from "@mui/icons-material/Notifications";
import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getNotification,
  markAsRead,
  markAsReadById,
} from "../../redux/Notification/action";
import { useDispatch } from "react-redux";
import CurrentDateToGivenTImeCalculation from "../../components/CurrentDateToGivenTImeCalculation/CurrentDateToGivenTImeCalculation";
import { requestAppointmentDetails } from "../../redux/Dashboard/action";
import AppointmentCancelModel from "../modal/AppointmentCancelModel";
import { appointmentDetails } from "../../redux/appointment/action";

function NotificationPopUp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);

  const [sucessMessage, setSucessMessage] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [appointmentid, setAppointmentid] = useState();
  let notificationPopData = useSelector(
    (state) => state.Notification.notificationPopupData
  );

  useEffect(() => {
    getNotificationPopup();
    const intervalId = setInterval(() => {
      getNotificationPopup();
    }, 60000);
    return () => {
      clearInterval(intervalId);
      setSucessMessage(false);
    };
  }, []);

  const getNotificationPopup = async () => {
    setNotificationLoading(true);
    let res = await dispatch(getNotification(3, 1, true));
    if (res) {
      setUnreadCount(res?.unreadCount);
      setNotificationLoading(false);
    }
  };

  const markAsAllRead = async () => {
    let res = await dispatch(markAsRead());
    if (res) {
      setSucessMessage(true);
      getNotificationPopup();
    }
  };
  const handleCardClick = (item) => {
    readNotification(item?._id);

    if (item?.type == "newEstimateRequest") {
      navigate("/request/estimates");
    }
    if (item?.type == "newAppointmentRequest") {
      navigate("/request/appointments");
    }

    if (item?.type == "approvalRequestResponded") {
      navigate(`/approval/${item?.referenceId}`);
    }
    if (item?.type == "appointmentRescheduleRequest") {
      setAppointmentid(item?.referenceId);
      AppointmentDetails(item?.referenceId, "appointmentRescheduleRequest");
      setOpenRescheduleModal(true);
    }
    if (
      item?.type == "acceptProposeNewTimeAppointment" ||
      item?.type == "acceptRescheduleAppointment" ||
      item?.type == "declineRescheduleAppointment"
    ) {
      navigate(`/appointment/upcoming-appointments`);
    }
    if (item?.type == "cancelAppointment") {
      setAppointmentid(item?.referenceId);
      AppointmentDetails(item?.referenceId, "cancelAppointment");
      setOpenCancelModal(true);
    }
    if (item?.type == "workOrderAuthorizationResponded") {
      navigate(`/appointment/workOrderAuthorization/${item?.appointment?._id}`);
    }
    if (item?.type == "subscriptionPaymentReceived") {
      navigate(`/account-settings/billing`);
    }
    getNotificationPopup();

    console.log("item", item);
  };

  const readNotification = async (id) => {
    await dispatch(markAsReadById(id));
  };

  const AppointmentDetails = async (id, selectedType) => {
    if (id) {
      setLoading(true);
      let data;
      if (selectedType == "cancelAppointment") {
        data = await dispatch(appointmentDetails(id));
      } else {
        data = await dispatch(requestAppointmentDetails(id));
      }
      if (data) {
        setAppointmentData(data);
      }
      setLoading(false);
    }
  };
  return (
    <div>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Typography
              variant="contained"
              {...bindTrigger(popupState)}
              style={{ cursor: "pointer" }}
            >
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon style={{ color: "rgba(0, 0, 0, 0.56)" }} />
              </Badge>
            </Typography>
            <Popover {...bindMenu(popupState)} sx={{ mt: "42px" }}>
              <Card style={{ width: "512px" }}>
                <Stack
                  direction="row"
                  // spacing={0}
                  // p={2}
                  justifyContent="space-between"
                  height="62px"
                  style={{
                    border: "0px, 0px, 1px, 0px",
                    alignItems: "center",
                    padding: "4px 10px 4px 10px",
                    borderBottom: "1px solid #D8D8D8",
                  }}
                >
                  <div>
                    Notifications&nbsp;{" "}
                    {/* {unreadCount != 0 ? `(${unreadCount})` : ""} */}
                  </div>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={markAsAllRead}
                  >
                    MARK AS ALL READ &nbsp;
                    {sucessMessage && (
                      <CheckIcon
                        fontSize="small"
                        style={{ color: "green", marginTop: "-3px" }}
                      />
                    )}
                  </Button>

                  {/* <div>MARK AS ALL READ</div> */}
                </Stack>
                {!notificationLoading ? (
                  notificationPopData && notificationPopData.length > 0 ? (
                    notificationPopData.map((item, index) => {
                      return (
                        <div key={index}>
                          <Card
                            style={{
                              border: "none",
                              height: "auto",
                              cursor: "pointer",
                            }}
                            sx={{ mb: 0 }}
                            key={index}
                            onClick={() => {
                              handleCardClick(item);
                              popupState.close();
                            }}
                          >
                            <CardHeader
                              title={
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <div
                                    style={{
                                      fontSize: "17px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {notificationPopData[index]?.title}
                                    <br />
                                    <Typography style={{ fontSize: "16px" }}>
                                      {notificationPopData[index]?.message}
                                    </Typography>
                                  </div>
                                  <div>
                                    <CircleIcon
                                      className={`${
                                        item?.isRead ? "gray-dot" : "green-dot"
                                      } common-dot`}
                                    />
                                  </div>
                                </Stack>
                              }
                            ></CardHeader>
                            <CardContent
                              style={{
                                marginBottom: "10px",
                                paddingTop: "inherit",
                                paddingBottom: "inherit",
                              }}
                            >
                              {CurrentDateToGivenTImeCalculation(
                                notificationPopData[index]?.createdAt
                              )}
                            </CardContent>
                          </Card>
                          <Divider />
                        </div>
                      );
                    })
                  ) : (
                    !notificationLoading && (
                      <Typography
                        variant="h5"
                        style={{ fontWeight: 400, textAlign: "center" }}
                      >
                        Data is not available
                      </Typography>
                    )
                  )
                ) : (
                  <CircularProgress
                    style={{ display: "block", margin: "auto" }}
                  />
                )}

                {/* <br/> */}
                {/* <Card
                  style={{ border: "0px, 0px, 1px, 0px", height: "112px" }}
                  sx={{ mb: 1 }}
                >
                  <CardHeader
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <div style={{ fontSize: "17px", fontWeight: "600" }}>
                          Estimate Request
                          <br />
                          <Typography style={{ fontSize: "16px" }}>
                            Michael Jordan, has sent you an estimate request.
                          </Typography>
                        </div>
                        <div>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#BDBDBD" }}
                          />
                        </div>
                      </Stack>
                    }
                  ></CardHeader>
                  <CardContent
                    style={{ marginBottom: "10px", paddingTop: "inherit" }}
                  >
                    1d
                  </CardContent>
                </Card>
                <Card
                  style={{ border: "0px, 0px, 1px, 0px", height: "112px" }}
                  sx={{ mb: 0 }}
                >
                  <CardHeader
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <div style={{ fontSize: "17px", fontWeight: "600" }}>
                          Estimate Request
                          <br />
                          <Typography style={{ fontSize: "16px" }}>
                            Michael Jordan, has sent you an estimate request.
                          </Typography>
                        </div>
                        <div>
                          <CircleIcon
                            style={{ fontSize: "small", color: "#BDBDBD" }}
                          />
                        </div>
                      </Stack>
                    }
                  ></CardHeader>
                  <CardContent
                    style={{ marginBottom: "10px", paddingTop: "inherit" }}
                  >
                    1d
                  </CardContent>
                </Card> */}
              </Card>
              {/* <Card>
                <CardContent> */}
              <Stack
                height="62px"
                style={{
                  justifyContent: "center",
                  textAlign: "end",
                  padding: "4px 10px 4px 10px",
                }}
              >
                <div>
                  <Typography>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => {
                        popupState.close();
                        navigate("/notifications");
                      }}
                    >
                      SEE ALL NOTIFICATIONS
                    </Button>
                  </Typography>
                </div>
              </Stack>

              {/* </CardContent>
              </Card> */}
            </Popover>
          </React.Fragment>
        )}
      </PopupState>
      <AppointmentCancelModel
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        appointmentData={appointmentData}
        showLoader={loading}
        setShowLoader={setLoading}
      />
    </div>
  );
}

export default NotificationPopUp;
